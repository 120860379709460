import * as yup from 'yup';
import { create, useModal } from '@ebay/nice-modal-react';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from 'formik-mui';
import { Modal, StatusEnum, StatusUI } from '@brandbank/portal-components';
import { ProductItem } from 'features/product-search';
import ProductResendAlert from './ProductResendAlert';
import useProductResendModal from '../hooks/useProductResendModal';

type ProductResendModalProps = {
  itemsToResend: ProductItem[];
  purposeId: string;
};

const ProductResendFormSchema = yup.object().shape({
  requestedBy: yup.string().required('Please select an option'),
  reason: yup.string().required('Please provide a reason'),
});

export default create<ProductResendModalProps>(
  ({ itemsToResend, purposeId }) => {
    const { hide, remove, resolve, visible } = useModal();

    const { errorMessage, errorType, formValues, showForm, status, submit } =
      useProductResendModal({
        itemsToResend,
        purposeId,
        onResolve: resolve,
      });

    const handleClose = () => {
      if (status !== 'loading') {
        resolve([]);
        hide();
      }
    };

    return (
      <Modal
        muiTransitionProps={{ onExited: remove }}
        onClose={handleClose}
        open={visible}
        title={status === 'idle' ? 'Resend products' : ''}
      >
        {status === 'idle' && (
          <Formik
            initialValues={formValues}
            onSubmit={submit}
            validationSchema={ProductResendFormSchema}
          >
            <Form id='resend-reason-form'>
              <Stack spacing={2}>
                <Field
                  component={Select}
                  label='Requested By*'
                  name='requestedBy'
                  required
                >
                  <MenuItem value='1'>Client Request</MenuItem>
                  <MenuItem value='2'>NielsenIQ Brandbank Request</MenuItem>
                </Field>
                <Field
                  component={TextField}
                  label='Reason for resend'
                  name='reason'
                  required
                  multiline
                  minRows={10}
                  maxRows={10}
                />
                <Button
                  form='resend-reason-form'
                  sx={{ alignSelf: 'flex-end' }}
                  type='submit'
                  variant='contained'
                >
                  Submit
                </Button>
              </Stack>
            </Form>
          </Formik>
        )}

        {status === 'loading' && (
          <StatusUI status={StatusEnum.LOADING} title='Resending products...' />
        )}

        {status === 'error' && (
          <ProductResendAlert status='error' title='Error'>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 400 }} variant='h6'>
                {errorMessage}
              </Typography>

              {errorType && errorType !== 'server-error' && (
                <Typography>
                  Resend-related errors can be seen in the{' '}
                  <strong>errors</strong> tab
                </Typography>
              )}
            </Box>

            <Stack direction='row' spacing={1}>
              <Button color='error' onClick={handleClose} variant='outlined'>
                Close
              </Button>
              <Button color='error' onClick={showForm} variant='contained'>
                Back to form
              </Button>
            </Stack>
          </ProductResendAlert>
        )}

        {status === 'warning' && (
          <ProductResendAlert
            status='warning'
            title='Some PVIDs failed to resend'
          >
            <Typography sx={{ mb: 2 }}>
              PVIDs that failed to resend can be viewed on the{' '}
              <strong>errors</strong> tab.
              <br />
              All other selected PVIDs have been resent successfully.
            </Typography>

            <Button color='warning' onClick={handleClose} variant='contained'>
              OK
            </Button>
          </ProductResendAlert>
        )}

        {status === 'success' && (
          <ProductResendAlert status='success' title='Resend was successful'>
            <Button onClick={handleClose} variant='contained'>
              OK
            </Button>
          </ProductResendAlert>
        )}
      </Modal>
    );
  }
);
