import { formatDate } from '@brandbank/portal-components';
import { ProductItem } from 'features/product-search/productSearch.types';
import { useState } from 'react';
import postProductsForResend from '../api/postProductsForResend';
import {
  ProductResendBatch,
  ProductResendError,
  ProductResendFormValues,
  ProductResendLimitError,
  ProductResendStatus,
} from '../productResend.types';

type UseProductResendModalProps = {
  itemsToResend: ProductItem[];
  onResolve: (args?: unknown) => void;
  purposeId: string;
};

const initialFormValues: ProductResendFormValues = {
  requestedBy: '',
  reason: '',
};

const useProductResendModal = ({
  itemsToResend,
  onResolve,
  purposeId,
}: UseProductResendModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorType, setErrorType] = useState('');

  const [formValues, setFormValues] =
    useState<ProductResendFormValues>(initialFormValues);

  const [status, setStatus] = useState<ProductResendStatus>('idle');

  const handlePostSuccess = (batch: ProductResendBatch) => {
    const { failedPVIDs, isRequestSubmitted, requestStatusMessage } = batch;

    if (failedPVIDs && failedPVIDs.length > 0) {
      onResolve(
        failedPVIDs.map(({ pvid }) => ({
          attemptedValue: pvid,
          errorMessage: `PVID ${pvid} failed to resend`,
          propertyName: 'PVID',
        }))
      );
    }

    if (!isRequestSubmitted) {
      setErrorMessage(requestStatusMessage);
      setStatus('error');
    } else {
      onResolve([]);
      setStatus('success');
    }
  };

  const handlePostLimitError = (
    limitError: ProductResendLimitError,
    retryAfter?: string
  ) => {
    const { data, message, reference } = limitError;

    const limitErrorMessage = `Limit Error - ${message} - Reference: ${reference}`;

    onResolve([
      {
        attemptedValue: '',
        errorMessage: message,
        propertyName: data.join(','),
      },
    ]);
    setErrorMessage(
      `${limitErrorMessage} ${
        retryAfter ? `retry after ${formatDate(retryAfter)}` : ''
      }`
    );
    setErrorType('limit-error');
    setStatus('error');
  };

  const handlePostResendError = (resendErrors: ProductResendError[]) => {
    const numOfErrors = resendErrors.length;

    onResolve(resendErrors);
    setErrorMessage(
      `Resend Error - ${numOfErrors} product${
        numOfErrors > 1 ? 's' : ''
      } could not be resent`
    );

    setErrorType('resend-error');
    setStatus('error');
  };

  const handleServerError = () => {
    setErrorMessage(
      'A server error has occurred, please try again or contact your administrator'
    );
    setErrorType('server-error');
    setStatus('error');
  };

  const showForm = () => {
    setStatus('idle');
  };

  const submit = (values: ProductResendFormValues) => {
    const { reason, requestedBy } = values;

    setFormValues(values);

    setStatus('loading');

    postProductsForResend({
      gtins: itemsToResend.map(({ gtin }) => gtin),
      requestSource: requestedBy,
      reason,
      purposeId,
      pvids: itemsToResend.map(({ productVersionId }) => productVersionId),
    })
      .then((res) => {
        if (res.status === 'success') {
          handlePostSuccess(res.data);
        } else if (res.status === 'limit-error') {
          handlePostLimitError(res.data, res.retryAfter);
        } else if (res.status === 'resend-error') {
          handlePostResendError(res.data);
        } else if (res.status === 'server-error') {
          handleServerError();
        }
      })
      .catch(() => {
        setErrorMessage('An unexpected error occurred');
        setStatus('error');
      });
  };

  return {
    errorMessage,
    errorType,
    formValues,
    showForm,
    status,
    submit,
  };
};

export default useProductResendModal;
