import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useSnackbar } from 'notistack';
import { CreateConnectMaxPayload } from '../connect-max.types';

type Response = {
  message?: string;
};

async function createConnectMaxConfig(connectMax: CreateConnectMaxPayload) {
  const response = await axios.post<Response>(
    '/AddRetailerConfiguration',
    connectMax
  );

  return response.data;
}

const useCreateConnectMaxConfig = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const mutation = useMutation<
    Response,
    AxiosError,
    CreateConnectMaxPayload,
    unknown
  >(createConnectMaxConfig, {
    onError: (err) => {
      console.error(err);
      enqueueSnackbar('Unable to save Connect Max layout', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully added Connect Max layout', {
        variant: 'success',
      });
      queryClient.invalidateQueries(['connectMaxConfig']);
    },
  });

  return mutation;
};

export default useCreateConnectMaxConfig;
