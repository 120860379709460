import React from 'react';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { AppSidebar } from '@brandbank/portal-components';
import SidebarNavigation from './SidebarNavigation';
import TopNavigation from './TopNavigation';
import { useCookieConsentContext } from '@use-cookie-consent/react';

const ProtectedLayout = (): JSX.Element => {
  const { consent, cookies } = useCookieConsentContext();

  // If user did not accept 'preferences (functional)' cookies, remove auth0 cookies
  React.useEffect(() => {
    Object.keys(cookies.getAll()).forEach((key) => {
      if (key.indexOf('auth0.') > -1 && !consent.preferences) {
        cookies.remove(key);
      }
    });
  }, [consent, cookies]);

  return (
    <>
      <Box
        data-testid='logged-in-view'
        sx={{
          display: 'flex',
        }}
      >
        <AppSidebar>
          <SidebarNavigation />
        </AppSidebar>
        <Box
          sx={{
            minHeight: 'calc(100vh - 260px)',
            position: 'relative',
            width: '100%',
          }}
        >
          <TopNavigation />
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export default ProtectedLayout;
