import { Link } from 'react-router-dom';
import {
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import GoToIcon from '@mui/icons-material/ExitToAppRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import { useDeleteFavouriteFeed, useFavouriteFeeds } from '../api';
import { QueryWidget } from 'features/widgets';

const FavouriteFeedsWidget = () => {
  const query = useFavouriteFeeds();
  const deleteFavourite = useDeleteFavouriteFeed();

  const handleDelete = (purposeId: number) => {
    deleteFavourite.mutate(String(purposeId));
  };

  const isDeleting = deleteFavourite.status === 'loading';

  return (
    <QueryWidget
      errorMessage='Unable to get favourites'
      query={query}
      searchKeys={['id', 'name']}
      title='My favourite feeds'
    >
      {(items, searchValue) => (
        <TableContainer sx={{ borderRadius: 1, maxHeight: [500, 200] }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Feed name</TableCell>
                <TableCell>Purpose ID</TableCell>
                <TableCell padding='checkbox' />
                <TableCell padding='checkbox' />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length === 0 && !!searchValue && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    No favourites found matching <b>{searchValue}</b>
                  </TableCell>
                </TableRow>
              )}
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.name}{' '}
                    {item.isDeleted ? (
                      <sup>
                        <Typography
                          fontWeight={600}
                          color={(theme) => theme.palette.error.main}
                          display={'inline'}
                          fontSize='11px'
                          paddingLeft='4px'
                        >
                          Deleted
                        </Typography>
                      </sup>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>{item.id}</TableCell>

                  <TableCell padding='checkbox'>
                    <Tooltip title='Go to feed'>
                      <span>
                        <IconButton
                          component={Link}
                          disabled={isDeleting}
                          to={`/feeds/${item.id}`}
                        >
                          <GoToIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell padding='checkbox'>
                    <Tooltip title='Remove from favourites'>
                      <span>
                        <IconButton
                          data-testid='delete-button'
                          disabled={isDeleting}
                          onClick={() => handleDelete(item.id)}
                        >
                          <ClearRoundedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </QueryWidget>
  );
};

export default FavouriteFeedsWidget;
