import Widget, { WidgetNoContent } from './Widget';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Icon,
  SearchField,
  searchItemsByKeys,
  StatusEnum,
  StatusUI,
  TableSkeleton,
} from '@brandbank/portal-components';
import { AxiosError } from 'axios';

type QueryWidgetProps<T> = {
  children: (items: T[], searchValue: string) => ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  mapQueryItems?: (item: T) => T;
  noItemsText?: string;
  query: UseQueryResult<T[], unknown>;
  searchKeys: (keyof T)[];
  title: string;
};

const QueryWidget = <T,>({
  children,
  disabled,
  errorMessage,
  mapQueryItems,
  noItemsText,
  query,
  searchKeys,
  title,
}: QueryWidgetProps<T>) => {
  const { data = [], error, refetch, fetchStatus, status } = query;

  const [filteredItems, setFilteredItems] = useState(data);
  const [searchValue, setSearchValue] = useState('');
  const [widgetStatus, setWidgetStatus] = useState(status);

  const getErrorMessage = () => {
    const axiosError = error as AxiosError;

    if (errorMessage) return errorMessage;
    if (axiosError && axiosError.message) return axiosError.message;
    return 'An unexpected error occurred';
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  const mappedItems = useMemo(
    () => (mapQueryItems ? data.map(mapQueryItems) : data),
    [data, mapQueryItems]
  );

  const searchElement = (
    <SearchField
      disabled={disabled || data.length === 0 || status !== 'success'}
      onChange={handleChangeSearch}
      placeholder='Search...'
      size='small'
      sx={{ maxWidth: 300 }}
    />
  );

  useEffect(() => {
    setWidgetStatus(fetchStatus === 'fetching' ? 'loading' : status);
  }, [fetchStatus, status]);

  useEffect(() => {
    if (widgetStatus === 'success') {
      let freshItems = [...mappedItems];

      if (searchValue) {
        freshItems = searchItemsByKeys({
          keys: searchKeys,
          items: freshItems,
          searchValue,
        });
      }

      setFilteredItems(freshItems);
    }
  }, [mappedItems, searchKeys, widgetStatus, searchValue]);

  return (
    <Widget
      action={
        <Stack alignItems='center' direction='row' spacing={1}>
          <Box sx={{ display: ['none', 'block'] }}>{searchElement}</Box>

          <IconButton
            disabled={widgetStatus === 'loading'}
            onClick={() => refetch()}
          >
            <Icon iconName='refresh' />
          </IconButton>
        </Stack>
      }
      title={title}
    >
      {widgetStatus === 'loading' && (
        <TableSkeleton numColumns={3} numRows={1} />
      )}

      {widgetStatus === 'error' && (
        <StatusUI
          action={
            <Button color='error' onClick={() => refetch()} variant='contained'>
              Try again
            </Button>
          }
          status={StatusEnum.ERROR}
          sx={{
            alignItems: 'center',
            '& h3': {
              fontSize: '1.5em',
            },
            '& h5': {
              display: 'none',
            },
            '& svg': {
              width: 60,
            },
          }}
          title={getErrorMessage()}
        />
      )}

      {widgetStatus === 'success' && (
        <>
          {data.length === 0 ? (
            <WidgetNoContent
              title={noItemsText || 'You currently have no items'}
            />
          ) : (
            <>
              <Box sx={{ display: ['block', 'none'], mb: 1 }}>
                {searchElement}
              </Box>

              {children(filteredItems, searchValue)}
            </>
          )}
        </>
      )}
    </Widget>
  );
};

export default QueryWidget;
