import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { SxProps, Typography } from '@mui/material';
import NoItems, { NoItemsProps } from '../ui/NoItems';
import { ReactComponent as EmptySvg } from 'assets/svg/empty.svg';
import { ReactComponent as ComingSoonSvg } from 'assets/svg/coming-soon.svg';

export type WidgetProps = {
  action?: React.ReactNode;
  children: React.ReactNode;
  contentSx?: SxProps;
  sx?: SxProps;
  title: string;
};

type WidgetComingSoonProps = {
  title: string;
  sx?: SxProps;
};

const Widget = (props: WidgetProps): JSX.Element => {
  const { action, children, contentSx, sx, title } = props;

  return (
    <Card
      sx={{
        height: '100%',
        ...sx,
      }}
    >
      <CardHeader
        action={action}
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent
        sx={{
          position: 'relative',
          ...contentSx,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export const WidgetNoContent = (props: NoItemsProps): JSX.Element => {
  const { sx, ...rest } = props;

  return (
    <NoItems
      sx={{ '& h5': { fontSize: '1em' }, ...sx }}
      svg={<EmptySvg />}
      {...rest}
    />
  );
};

export const WidgetComingSoon = (props: WidgetComingSoonProps): JSX.Element => {
  const { sx, title } = props;

  return (
    <Widget
      action={<Chip color='primary' label='Coming soon' sx={{ mt: 1 }} />}
      sx={sx}
      title={title}
    >
      <Stack alignItems='center' spacing={2} paddingBlock='3rem'>
        <ComingSoonSvg />
        <Typography variant='h5'>Coming Soon...</Typography>
      </Stack>
    </Widget>
  );
};

export default Widget;
