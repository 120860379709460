import { Logo, useModal } from '@brandbank/portal-components';
import MenuIcon from '@mui/icons-material/MenuRounded';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Outlet, Link as RouterLink } from 'react-router-dom';
import ExternalLink from './ExternalLink';

const LINKS = [
  { label: 'Nielsen IQ Brandbank', href: 'https://www.brandbank.com/' },
  { label: 'Product Library', href: 'https://productlibrary.brandbank.com/' },
  {
    label: 'JIRA Help desk',
    href: 'https://brandbank.atlassian.net/servicedesk/customer/portal/1',
  },
];

const PublicLayout = (): JSX.Element => {
  const menuRef = React.useRef(null);
  const { isOpen, open, close } = useModal();

  return (
    <>
      <AppBar color='transparent' position='static' sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <Box className='logo-wrapper' sx={{ width: '220px', mr: 'auto' }}>
            <Logo />
          </Box>

          <Stack
            direction='row'
            spacing={3}
            sx={{ alignItems: 'center', display: ['none', 'none', 'flex'] }}
          >
            {LINKS.map((link) => (
              <ExternalLink key={link.label} {...link} />
            ))}

            <Button
              component={RouterLink}
              to='/documentation'
              variant='contained'
            >
              Documentation
            </Button>
          </Stack>

          <IconButton
            color='primary'
            ref={menuRef}
            onClick={open}
            sx={{
              display: ['initial', 'initial', 'none'],
              height: 40,
              '&, &:hover, &.Mui-focusVisible': {
                bgcolor: 'primary.main',
              },
            }}
          >
            <MenuIcon sx={{ color: 'common.white' }} />
          </IconButton>

          <Menu
            id='basic-menu'
            anchorEl={menuRef.current}
            open={isOpen}
            onClose={close}
          >
            {LINKS.map(({ label, href }) => (
              <MenuItem
                component='a'
                key={label}
                href={href}
                target='_blank'
                rel='noreferrer'
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>

      <Outlet />
    </>
  );
};

export default PublicLayout;
