import { StatusEnum, StatusUI } from '@brandbank/portal-components';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CompanyDetailsCtx, useCompanyDetails } from 'features/companies';
import { paths } from 'features/routing';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import CompanyDetailsLayoutError from './CompanyDetailsLayoutError';
import CompanyDetailsNavbar from './CompanyDetailsNavbar';

const CompaniesLayout = () => {
  const { id = '' } = useParams();
  const { data: company, error, status, refetch } = useCompanyDetails(id);

  const companyName = company?.name || '';

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `/companies/${id}`) {
      navigate(paths.companyOverview(id), { replace: true });
    }
  }, [id, location.pathname, navigate]);

  return (
    <>
      {status === 'loading' && (
        <StatusUI splashPage status={StatusEnum.LOADING} />
      )}

      {status === 'error' && (
        <CompanyDetailsLayoutError error={error} refetch={refetch} />
      )}

      {status === 'success' && (
        <>
          {companyName.length > 0 && (
            <Typography
              variant='h6'
              sx={{ paddingLeft: '24px', fontStyle: 'italic' }}
            >
              {companyName}
            </Typography>
          )}

          <CompanyDetailsNavbar companyId={id} company={company} />

          <Box sx={{ p: 3 }}>
            <CompanyDetailsCtx.Provider value={company}>
              <Outlet />
            </CompanyDetailsCtx.Provider>
          </Box>
        </>
      )}
    </>
  );
};

export default CompaniesLayout;
