import { AppSidebar } from '@brandbank/portal-components';
import { Box, Stack } from '@mui/material';
import { BroadcastChannel } from 'broadcast-channel';
import { paths } from 'features/routing';
import { UserMenu } from 'features/ui';
import { usePromptForXMLayout } from 'hooks/usePromptForXMLayout';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarNavigation from '../ProtectedLayout/SidebarNavigation';

type Props = {
  element: ReactNode;
};

/*
    ExtractManagementUI can use this broadcast channel to
    post a message with a boolean on whether to block navigation away
    from Extract tabs
*/
const bc = new BroadcastChannel('Extract-Management');
const dashboardbc = new BroadcastChannel('Dashboard-Navigation');

const ExtractManagementLayout = ({ element }: Props) => {
  const [blockNavigation, setBlockNavigation] = useState(false);
  const navigate = useNavigate();

  dashboardbc.onmessage = (shouldNavigate: boolean) => {
    if (shouldNavigate) navigate(paths.dashboard);
  };

  bc.onmessage = (shouldBlockNavigation: boolean) => {
    setBlockNavigation(shouldBlockNavigation);
  };

  usePromptForXMLayout('Changes you made may not be saved.', blockNavigation);

  return (
    <Stack direction='row'>
      <AppSidebar>
        <SidebarNavigation />
      </AppSidebar>
      <Box
        height='64px'
        width='64px'
        position='fixed'
        margin='8px'
        right={0}
        zIndex={1300}
      >
        <UserMenu />
      </Box>
      <Stack flex='1'>{element}</Stack>
    </Stack>
  );
};

export default ExtractManagementLayout;
