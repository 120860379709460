import { createContext, useContext } from 'react';
import { Company } from './companies.types';

export const CompanyDetailsCtx = createContext<Company | null>(null);

export const useCompanyDetailsCtx = () => {
  const data = useContext(CompanyDetailsCtx);

  if (!data) {
    throw new Error(
      'useCompanyDetailsCtx is being used outside of CompanyDetailsCtx.Provider'
    );
  }

  return data;
};
