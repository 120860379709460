import { useProductSearchCtx } from '../ProductSearch.context';
import { Alert } from '@mui/material';
import { SimpleTable } from '@brandbank/portal-components';

const ProductErrorsView = () => {
  const { state } = useProductSearchCtx();

  return (
    <>
      <Alert severity='info' sx={{ m: 1 }}>
        Any products displayed here have experienced an error during a product
        resend request.
        <br />
        For help with these errors, please contact a member of the Integration
        team, your Account Manager or{' '}
        <a
          href='https://brandbank.atlassian.net/servicedesk/customer/portal/1'
          target='_blank'
          rel='noreferrer'
        >
          raise a JIRA ticket
        </a>
        .
      </Alert>

      <SimpleTable
        columns={[
          {
            id: 'propertyName',
            label: 'Failed properties',
            render: (item) => {
              if (item.propertyName.indexOf(',') > -1) {
                return item.propertyName.split(',').map((word) => (
                  <>
                    {word}
                    <br />
                  </>
                ));
              }
              return item.propertyName;
            },
          },
          { id: 'errorMessage', label: 'Error message' },
          {
            id: 'attemptedValue',
            label: 'Attempted value',
          },
        ]}
        items={state.errors}
        sx={{ bgcolor: 'background.paper', borderRadius: 1 }}
      />
    </>
  );
};

export default ProductErrorsView;
