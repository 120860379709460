import { useEffect } from 'react';
import axios from 'config/axios';
import { FavouriteFeed } from '../favouriteFeeds.type';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { SnackbarKey, useSnackbar } from 'notistack';

async function getFavouriteFeeds() {
  const response = await axios.get<FavouriteFeed[]>('GetFavouriteFeeds');
  return response.data || [];
}

const useFavouriteFeeds = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const query = useQuery<FavouriteFeed[], AxiosError>(
    ['favouriteFeeds'],
    getFavouriteFeeds
  );

  useEffect(() => {
    let snackbar: SnackbarKey | null = null;

    if (query.error) {
      snackbar = enqueueSnackbar(query.error.message, { variant: 'error' });
    }

    return () => {
      if (snackbar) {
        closeSnackbar(snackbar);
      }
    };
  }, [closeSnackbar, enqueueSnackbar, query.error]);

  return query;
};

export default useFavouriteFeeds;
