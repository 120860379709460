import { Icon } from '@brandbank/portal-components';
import {
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Checkbox, Select, TextField } from 'formik-mui';
import { Placeholder, PlaceholderCategory } from '../connectTemplates.types';

type PlaceholderFieldArrayItemProps = {
  index: number;
  onRemove: (index: number) => void;
  placeholder: Placeholder;
  styles: PlaceholderCategory[];
  types: PlaceholderCategory[];
};

const ImageProfileTooltip = styled(({ className, ...rest }: TooltipProps) => (
  <Tooltip {...rest} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: theme.shadows[2],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    maxWidth: 600,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    color: theme.palette.background.paper,
  },
}));

const PlaceholderFieldArrayItem = ({
  index,
  onRemove,
  placeholder,
  styles,
  types,
}: PlaceholderFieldArrayItemProps) => {
  const { isSubmitting } = useFormikContext();

  return (
    <TableRow key={placeholder.placeholderId}>
      <TableCell>{placeholder.placeholderId}</TableCell>
      <TableCell>
        <Field
          component={TextField}
          initialValue={placeholder.name}
          name={`placeholders.${index}.name`}
        />
      </TableCell>
      <TableCell align='center'>
        <Field
          component={Select}
          id={`placeholders.${index}.typeId`}
          label=''
          initialValue={placeholder.typeId}
          name={`placeholders.${index}.typeId`}
        >
          {types.map((type) => (
            <MenuItem key={type.categoryId} value={type.categoryId}>
              {type.name}
            </MenuItem>
          ))}
        </Field>
      </TableCell>
      <TableCell align='center'>
        <Field
          component={Select}
          id={`placeholders.${index}.styleId`}
          label=''
          initialValue={placeholder.styleId}
          name={`placeholders.${index}.styleId`}
        >
          {styles.map((style) => (
            <MenuItem key={style.categoryId} value={style.categoryId}>
              {style.name}
            </MenuItem>
          ))}
        </Field>
      </TableCell>
      <TableCell>
        <Field
          component={TextField}
          fullWidth
          InputProps={{
            endAdornment: (
              <ImageProfileTooltip
                arrow
                title={
                  <>
                    <Typography>Example format:</Typography>
                    <Typography variant='caption'>
                      &#123;0&#125;?Height=640&amp;Width=640&amp;OutputFormat=image/jpeg
                    </Typography>
                  </>
                }
              >
                <IconButton edge='end'>
                  <Icon iconName='info' />
                </IconButton>
              </ImageProfileTooltip>
            ),
          }}
          initialValue={placeholder.imageProfile}
          name={`placeholders.${index}.imageProfile`}
        />
      </TableCell>
      <TableCell align='center'>
        <Field
          checked={placeholder.optional}
          component={Checkbox}
          name={`placeholders.${index}.optional`}
          label=''
        />
      </TableCell>
      <TableCell align='center'>
        <Tooltip title='Remove placeholder'>
          <IconButton disabled={isSubmitting} onClick={() => onRemove(index)}>
            <Icon iconName='trash' />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default PlaceholderFieldArrayItem;
