import { dayjs } from '@brandbank/portal-components';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { useCompanyDetailsCtx } from 'features/companies';
import { FormActionButtons } from 'features/ui';
import { usePrompt } from 'hooks/useBlocker';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useReducer, useRef, useState } from 'react';
import { XBlock, XMasonry } from 'react-xmasonry';
import { RefetchQueryFn } from 'utils/types';
import {
  AddConnectMaxAssetModal,
  ConnectMaxActionsCard,
  ConnectMaxAssetList,
  ConnectMaxInfoModal,
  TargetMarketWidget,
} from '.';
import {
  useCreateConnectMaxConfig,
  useDeleteConnectMaxConfig,
  useUpdateConnectMaxConfig,
} from '../api';
import {
  arePositionsValid,
  createAssetsDeepCopy,
} from '../connect-max.helpers';
import connectMaxReducer from '../connect-max.reducer';
import {
  ConnectMaxAssetType,
  ConnectMaxConfiguration,
  ConnectMaxMappingItem,
  TargetMarket,
} from '../connect-max.types';

type ConnectMaxProps = {
  data: ConnectMaxConfiguration;
  refetch: RefetchQueryFn<ConnectMaxConfiguration>;
};

const ConnectMax = ({ data, refetch }: ConnectMaxProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const company = useCompanyDetailsCtx();

  const {
    id,
    retailerName,
    retailerId,
    targetMarkets: tms,
    assets: assetsFetched,
    schemaVersion,
    documentVersion,
    timestamp,
  } = data;

  const initialAssets = createAssetsDeepCopy(assetsFetched);

  const isModalOpen = useRef(false);

  const [assets, dispatch] = useReducer(
    connectMaxReducer,
    createAssetsDeepCopy(assetsFetched)
  );

  const [targetMarkets, setTargetMarkets] = useState<TargetMarket[]>([
    ...(tms || []),
  ]);

  const { mutateAsync: createConnectMaxConfig, isLoading: createLoading } =
    useCreateConnectMaxConfig();
  const { mutateAsync: updateConnectMaxConfig, isLoading: updateLoading } =
    useUpdateConnectMaxConfig();
  const { mutateAsync: deleteConnectMaxConfig, isLoading: deleteLoading } =
    useDeleteConnectMaxConfig();

  const handleSaveChanges = () => {
    const errors: string[] = [];

    if (!assets || !assets.length || assets.length < 1) {
      errors.push('At least one asset type and mapping must be added.');
    }

    if (!targetMarkets || !targetMarkets.length || targetMarkets.length < 1) {
      errors.push('At least one target market must be added.');
    }

    if (errors.length && errors.length > 0) {
      errors.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
          style: { marginBottom: '100px' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      });
      return;
    }

    if (!id) {
      createConnectMaxConfig({
        retailerId: retailerId || company.id,
        retailerName: retailerName || company.name,
        assets,
        targetMarkets,
      }).then(() => refetch());
      return;
    }

    updateConnectMaxConfig({
      id,
      documentVersion,
      schemaVersion: schemaVersion || '1.0.0',
      timestamp: timestamp || dayjs(new Date()).toISOString(),
      retailerId: retailerId || company.id,
      retailerName: retailerName || company.name,
      targetMarkets,
      assets,
    }).then(() => refetch());
  };

  const handleDelete = () => {
    deleteConnectMaxConfig({
      retailerId: id || '',
      retailerName: retailerName || '',
    }).then(() => window.location.reload());
  };

  const handleDiscardChanges = () => {
    setTargetMarkets(tms || []);
    dispatch({
      type: 'set_assets',
      newAssets: createAssetsDeepCopy(initialAssets),
    });
  };

  const handleDragEnd = (
    newPositions: ConnectMaxMappingItem[],
    index: number
  ) => {
    if (!arePositionsValid(newPositions)) {
      enqueueSnackbar('All shot types must precede additional assets', {
        variant: 'error',
      });
      return;
    }

    const copy = [...assets];
    copy[index].positions = newPositions;

    dispatch({ type: 'moved', newAssets: copy });
  };

  const showAddNewModal = async () => {
    const newValue:
      | (Omit<ConnectMaxMappingItem, 'position'> & {
          assetType: ConnectMaxAssetType;
        })
      | null = await NiceModal.show(AddConnectMaxAssetModal, {
      assets,
    });

    if (newValue) {
      const { assetType, mapping, mappingType } = newValue;
      dispatch({ type: 'added', assetType, item: { mapping, mappingType } });
    }
  };

  useEffect(() => {
    const shouldShow = localStorage.getItem('ShouldShowConnectMaxInfoModal');

    if (shouldShow !== 'false' && isModalOpen.current === false) {
      NiceModal.show(ConnectMaxInfoModal);
      isModalOpen.current = true;
    }
  }, []);

  const hasChanges =
    !_.isEqual(initialAssets, assets) || !_.isEqual(tms || [], targetMarkets);

  const isLoading = createLoading || updateLoading || deleteLoading;

  usePrompt('Any unsaved changes will be lost', hasChanges);

  return (
    <Box sx={{ '.MuiCard-root': { m: 1, position: 'relative' }, mb: '100px' }}>
      <XMasonry
        maxColumns={2}
        targetBlockWidth={950}
        center={false}
        responsive
        smartUpdate
        smartUpdateCeil={1000}
      >
        <XBlock>
          <TargetMarketWidget
            targetMarkets={targetMarkets}
            setTargetMarkets={setTargetMarkets}
          />
        </XBlock>

        {assets?.map((asset, index) => (
          <XBlock key={`${asset.assetType}-list-${index}`}>
            <ConnectMaxAssetList
              {...asset}
              assets={assets}
              index={index}
              dispatch={dispatch}
              handleDragEnd={handleDragEnd}
            />
          </XBlock>
        ))}

        <XBlock>
          <ConnectMaxActionsCard
            handleAdd={showAddNewModal}
            handleDelete={!id ? undefined : () => handleDelete()}
          />
        </XBlock>
      </XMasonry>
      <FormActionButtons
        disabled={!hasChanges || isLoading}
        loading={isLoading}
        primaryButtonAction={handleSaveChanges}
        primaryButtonText={!id ? 'Create' : 'Save Changes'}
        secondaryButtonAction={handleDiscardChanges}
      />
    </Box>
  );
};

export default ConnectMax;
