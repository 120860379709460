import {
  Auth0ContextInterface,
  LogoutOptions,
  useAuth0,
} from '@auth0/auth0-react';
import {
  AuthRoles,
  AuthUser,
  AuthUserService,
  AuthUserSubcode,
} from '@brandbank/portal-components';
import { useEffect, useMemo, useState } from 'react';
import {
  CONNECTION_COOKIE_NAME,
  TIMEOUT_COOKIE_NAME,
  getCookieByName,
  isSessionCookieExpired,
  setConnectionCookie,
  updateTimeoutCookie,
} from 'utils/cookies';

export type OmittedInterface = Omit<Auth0ContextInterface, 'user'>;

export interface UserContextInterface extends OmittedInterface {
  appLogin: (redirectUri: string) => void;
  hasRole(role: AuthRoles, subcode?: string): boolean;
  roleChecks: {
    canOnlyViewDocumentation: boolean;
  } | null;
  token: string;
  user: AuthUser | null;
}

export const HAS_USER_SESSION_EXPIRED = 'session-expired' as const;
export const REDIRECT_ON_LOGIN = 'redirect-pathname' as const;

const activityEventListeners = [
  'mousemove',
  'mousedown',
  'keypress',
  'scroll',
  'DOMMouseScroll',
  'mousewheel',
  'touchmove',
  'MSPointerMove',
] as const;

const removeEventListeners = () => {
  activityEventListeners.forEach((listener) =>
    document.removeEventListener(listener, () => updateTimeoutCookie())
  );
};

const addEventListeners = () => {
  activityEventListeners.forEach((listener) =>
    document.addEventListener(listener, () => updateTimeoutCookie())
  );
};

const useUser = (): UserContextInterface => {
  const [token, setToken] = useState('');
  const [user, setUser] = useState<AuthUser | null>(null);

  const hasRole = (role: AuthRoles, subcode?: string): boolean => {
    if (!user) return false;

    if (subcode) {
      // subcode given, find subcode and check for role
      const foundSubcode = user.roles.subcodes.find(
        (item) => item.subcode === subcode
      );

      if (foundSubcode && foundSubcode.roles.indexOf(role) > -1) {
        return true;
      }
    } else if (
      user.roles.subcodes.some((item) => item.roles.indexOf(role) > -1)
    ) {
      // No subcode given, check ALL user subcodes for role match
      return true;
    }

    // Fallback to check service roles if subcode and/or role was not found
    return user.roles.serviceRoles.indexOf(role) > -1;
  };

  const {
    user: auth0User,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout: auth0Logout,
    ...options
  } = useAuth0();

  const appLogin = (redirectUri: string): void => {
    const urlHost = window.location.host;
    const urlScheme = urlHost.indexOf('localhost') > -1 ? 'http' : 'https';

    const urlParams = new URLSearchParams(window.location.search);
    const urlConnection = urlParams.get('connection');
    const connectionCookie = getCookieByName(CONNECTION_COOKIE_NAME);

    const connection = urlConnection || connectionCookie || 'BrandbankSTS';

    setConnectionCookie(connection);

    options
      .loginWithRedirect({
        redirectUri: `${urlScheme}://${urlHost}${redirectUri}`,
        connection,
      })
      .then(() => {
        updateTimeoutCookie();
        addEventListeners();
      });
  };

  const logout = (options?: LogoutOptions | undefined) => {
    removeEventListeners();
    updateTimeoutCookie('remove');
    localStorage.removeItem('token');
    auth0Logout(options);
  };

  const sessionTimeout = () => {
    logout();
    localStorage.setItem(REDIRECT_ON_LOGIN, window.location.pathname);
    localStorage.setItem(HAS_USER_SESSION_EXPIRED, 'true');
  };

  // Check if session is inactive/expired every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const upToDateCookie = getCookieByName(TIMEOUT_COOKIE_NAME);

      if (isSessionCookieExpired(upToDateCookie || '') && !!auth0User) {
        sessionTimeout();
        clearInterval(interval);
      }
    }, 15 * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check session status on initial render
  useEffect(() => {
    const upToDateCookie = getCookieByName(TIMEOUT_COOKIE_NAME);

    if (!isLoading) {
      if (!!auth0User && isSessionCookieExpired(upToDateCookie))
        sessionTimeout();

      // Adds event listeners when session is valid and component mounts e.g. When opening a new tab
      if (!isSessionCookieExpired(upToDateCookie) && !!auth0User)
        addEventListeners();
    }

    return removeEventListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const roleChecks = useMemo(() => {
    if (isAuthenticated && user) {
      const onlyHasDocumentationRole = (roles: AuthRoles[]): boolean =>
        roles.length === 1 && roles.indexOf('Data Model Viewer') > -1;

      const { serviceRoles, subcodes } = user.roles;

      const canOnlyViewDocumentationServiceLevel =
        serviceRoles.length === 0 || onlyHasDocumentationRole(serviceRoles);

      const canOnlyViewDocumentationSubcodeLevel = subcodes.every((item) =>
        onlyHasDocumentationRole(item.roles)
      );

      return {
        canOnlyViewDocumentation:
          canOnlyViewDocumentationServiceLevel &&
          canOnlyViewDocumentationSubcodeLevel,
      };
    }

    return null;
  }, [isAuthenticated, user]);

  // Get token
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((tkn) => {
        localStorage.setItem('token', tkn);
        setToken(tkn);
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  // Create User
  useEffect(() => {
    if (!auth0User) {
      setUser(null);
    } else {
      const parsedServices: AuthUserService[] = JSON.parse(
        auth0User['https://auth.brandbank.com/services']
      );

      const { firstName, lastName } = JSON.parse(
        auth0User['https://auth.brandbank.com/user']
      );

      const serviceRoles = parsedServices.length
        ? parsedServices[0].serviceRoles || []
        : [];

      const subcodesToMap = parsedServices.length
        ? parsedServices[0].subcodes || []
        : [];

      const subcodes: AuthUserSubcode[] = subcodesToMap.map((item) => ({
        subcode: item.subcode,
        roles: item.roles || [],
      }));

      setUser({
        email: auth0User.email || '',
        name: `${firstName} ${lastName}`,
        roles: {
          serviceRoles,
          subcodes,
        },
        picture: auth0User.picture || '',
      });
    }
  }, [auth0User]);

  return {
    appLogin,
    hasRole,
    roleChecks,
    user,
    token,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    ...options,
  };
};

export default useUser;
