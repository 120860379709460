import { useQuery } from '@tanstack/react-query';
import axios from 'config/axios';
import { ConnectMaxConfiguration } from '../connect-max.types';

async function getConnectMaxConfig(retailerId: string) {
  const response = await axios.get<ConnectMaxConfiguration>(
    `GetRetailerConfiguration?retailerId=${retailerId}`
  );

  return response.data;
}

const useGetConnectMaxConfig = (retailerId: string) =>
  useQuery(
    ['connectMaxConfig', retailerId],
    () => getConnectMaxConfig(retailerId),
    {
      enabled: retailerId !== '0',
    }
  );

export default useGetConnectMaxConfig;
