import Link from '@mui/material/Link';

type ExternalLinkProps = {
  href: string;
  label: string;
};

const ExternalLink = (props: ExternalLinkProps): JSX.Element => {
  const { href, label } = props;

  return (
    <Link
      color='text.primary'
      key={label}
      href={href}
      rel='noreferrer'
      target='_blank'
      underline='hover'
    >
      {label}
    </Link>
  );
};

export default ExternalLink;
