type EnvironmentVariables = {
  auth: {
    domain: string | undefined;
    clientID: string | undefined;
  };
  microFrontends: {
    docs: string | undefined;
    purposeManagement: string | undefined;
    extractManagement: string | undefined;
  };
  subscriptionKey: string | undefined;
  apiUrl: string | undefined;
  productLibraryUrl: string | undefined;
  docsUrl: string | undefined;
};

const environmentVariables: EnvironmentVariables = {
  auth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN || '',
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  },
  microFrontends: {
    docs: process.env.REACT_APP_MICRO_FRONTEND_DOCUMENTATION_MANAGEMENT,
    purposeManagement: process.env.REACT_APP_MICRO_FRONTEND_PURPOSE_MANAGEMENT,
    extractManagement: process.env.REACT_APP_MICRO_FRONTEND_EXTRACT_MANAGEMENT,
  },
  subscriptionKey: process.env.REACT_APP_APIM_SUBSCRIPTION_KEY,
  apiUrl: process.env.REACT_APP_API_URL,
  productLibraryUrl: process.env.REACT_APP_PRODUCT_LIBRARY_URL,
  docsUrl: process.env.REACT_APP_DOCS_URL,
};

export default environmentVariables;
