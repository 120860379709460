import axios from 'config/axios';
import { FeedDetailItem } from '../feeds.types';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

async function getFeedDetails(purposeId: string) {
  try {
    const response = await axios.get<FeedDetailItem>('GetFeedDetails', {
      params: { purposeId },
    });

    if (response.status === 204) {
      return Promise.reject(
        new Error(`No details were found for purposeId: ${purposeId}`)
      );
    }

    return response.data;
  } catch (err) {
    const error = err as AxiosError;

    if (error.code === '401') {
      return Promise.reject(
        new Error('You do not have authorisation to view this feed')
      );
    }

    if (error.code === '422') {
      return Promise.reject(new Error('Please provide a valid purposeID'));
    }

    return Promise.reject(err);
  }
}

const useFeedDetails = (purposeId: string) =>
  useQuery<FeedDetailItem, AxiosError>(['feedDetails', purposeId], () =>
    getFeedDetails(purposeId)
  );

export default useFeedDetails;
