import { connectMaxMappingTypes } from './connect-max.constants';
import {
  ConnectMaxAssetItem,
  ConnectMaxAssetType,
  ConnectMaxMappingItem,
} from './connect-max.types';

export const arePositionsValid = (positions: ConnectMaxMappingItem[]) => {
  const firstAdditionalAssetIndex = positions.findIndex(
    (x) => x.mappingType === 'additionalAsset'
  );
  const lastShotTypeIndex = positions.findLastIndex(
    (x) => x.mappingType === 'shotType'
  );

  if (firstAdditionalAssetIndex !== -1 && lastShotTypeIndex !== -1) {
    if (firstAdditionalAssetIndex < lastShotTypeIndex) return false;
  }

  return true;
};

export const getMappingTypeOptions = (
  assetType: ConnectMaxAssetType | '' | undefined
) =>
  connectMaxMappingTypes.filter(
    (x) => x === 'additionalAsset' || assetType === 'imageFile' || !assetType
  );

export const createAssetsDeepCopy = (assets: ConnectMaxAssetItem[] | null) =>
  assets?.map((asset) => ({
    ...asset,
    positions: [...(asset.positions?.map((pos) => ({ ...pos })) || [])],
  })) || [];

export const getGridTemplateColumns = (dragDisabled: boolean) =>
  `0.75fr 2fr 2fr 0.75fr ${dragDisabled ? '0fr' : '0.2fr'}`;
