import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CookiePrivacyModal from './CookiePrivacyModal';
import { useCookieConsentContext } from '@use-cookie-consent/react';
import { useModal } from '@ebay/nice-modal-react';
import CookieBanner from './CookieBanner';

type ConsentArg = {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

const CookieBannerOverlay = (): JSX.Element => {
  const preferencesModal = useModal(CookiePrivacyModal);

  const { acceptAllCookies, acceptCookies, consent } =
    useCookieConsentContext();

  // True if ANY cookies accepted, not all
  const hasAcceptedCookies = consent && !!consent.necessary;

  const [showCookieManagement, setShowCookieManagement] = useState(
    !hasAcceptedCookies
  );

  const handleSaveAccepted = (cookies: ConsentArg): void => {
    acceptCookies({
      ...cookies,
      persistent: true,
      session: true,
      firstParty: true,
      thirdParty: true,
    });
    setShowCookieManagement(false);
  };

  const handleAcceptAllCookies = (): void => {
    acceptAllCookies();
    setShowCookieManagement(false);
  };

  const handleAcceptEssentialCookies = (): void => {
    acceptCookies({
      necessary: true,
      preferences: false,
      marketing: false,
      statistics: false,
      persistent: false,
      session: false,
      firstParty: false,
      thirdParty: false,
    });
    setShowCookieManagement(false);
  };

  return (
    <Backdrop
      invisible={preferencesModal.visible}
      open={showCookieManagement}
      sx={{ zIndex: 'modal' }}
    >
      <CookieBanner
        onAcceptAllClick={handleAcceptAllCookies}
        onAcceptEssentialClick={handleAcceptEssentialCookies}
        onSettingsClick={() =>
          preferencesModal.show({ onSaveAndAccept: handleSaveAccepted })
        }
      />
    </Backdrop>
  );
};

export default CookieBannerOverlay;
