import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Widget from './Widget';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { Icon, dayjs, sortDates } from '@brandbank/portal-components';
import 'pure-react-carousel/dist/react-carousel.es.css';

const StyledSlide = styled(Slide)({
  '& .carousel__slide-focus-ring': {
    display: 'none !important',
  },
  overflow: 'auto',
});

// published - MM/DD/YYYY;

const slides = [
  {
    published: '01/01/2022',
    content: (
      <>
        <Typography>
          The NielsenIQ Brandbank, Client Integration team are excited to
          welcome you to the new Client Integration Hub! This application
          replaces the Integration Management Portal.
        </Typography>
      </>
    ),
  },
  {
    published: '1/19/2022',
    content: (
      <>
        <Typography>
          The NielsenIQ Brandbank, Client Integration team are pleased to
          confirm the technical documentation previously accessible via the
          Integration Management Portal has been fully replicated to the new
          Client Integration Hub.
        </Typography>

        <Button
          component={Link}
          sx={{ alignSelf: 'start' }}
          to='/documentation'
          variant='outlined'
        >
          Go to documentation
        </Button>
      </>
    ),
  },
  {
    published: '3/2/2022',
    content: (
      <>
        <Typography>
          To help make navigating the Client Integration Hub easier, we have now
          released a new favorite feeds feature. Navigate to any feed on the
          feeds list page, press the heart icon and that feed will then show on
          your dashboard each time you log in.
        </Typography>
      </>
    ),
  },
  {
    published: '4/1/2022',
    content: (
      <Typography>
        For those customers using Connect 1.0 services, Connect template
        functionality has now been released to the hub.
      </Typography>
    ),
  },
  {
    published: '4/20/2022',
    content: (
      <Typography>
        Additional functionality has now been released to the products tab;
        direct links to view products in Product Library are available and
        de-listed products are indicated in the search results.
      </Typography>
    ),
  },
  {
    published: '5/11/2022',
    content: (
      <Typography>
        Updated terms and conditions and cookie policy links for the Client
        Integration Hub are now available via the username dropdown.
      </Typography>
    ),
  },
  {
    published: '7/6/2022',
    content: (
      <Typography>
        To help you keep up to date with any changes to our technical
        documentation pages, an additional widget has been added to the
        dashboard, showing all revised or newly published pages within the
        previous two weeks.
      </Typography>
    ),
  },
  {
    published: '7/13/2022',
    content: (
      <>
        <Typography>
          To ensure our data model is easy to use, filter functionality has been
          added allowing you to view only the attributes relevant to your Target
          Market.{' '}
        </Typography>
        <Button
          component={Link}
          sx={{ alignSelf: 'start' }}
          to='/documentation/data-model'
          variant='outlined'
        >
          Go to Data Model
        </Button>
      </>
    ),
  },
];

const HubUpdatesWidget = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handlePreviousSlide = (): void => {
    setCurrentSlide((prev) => prev - 1);
  };

  const handleNextSlide = (): void => {
    setCurrentSlide((prev) => prev + 1);
  };

  const sortedSlides = sortDates(slides, 'desc', 'published');

  return (
    <Widget
      action={
        <>
          <IconButton
            data-testid='prev-button'
            disabled={currentSlide === 0}
            onClick={handlePreviousSlide}
          >
            <Icon iconName='arrowChevronLeft' />
          </IconButton>{' '}
          <IconButton
            data-testid='next-button'
            disabled={currentSlide === sortedSlides.length - 1}
            onClick={handleNextSlide}
          >
            <Icon iconName='arrowChevronRight' />
          </IconButton>
        </>
      }
      title='Hub Updates'
    >
      <CarouselProvider
        currentSlide={currentSlide}
        dragEnabled={false}
        infinite
        naturalSlideHeight={50}
        naturalSlideWidth={100}
        totalSlides={slides.length}
        touchEnabled={false}
      >
        <Slider>
          {sortedSlides.map((slide, index) => (
            <StyledSlide key={slide.published} index={index}>
              <Box sx={{ pr: 2 }}>
                <Stack spacing={2}>
                  <Typography variant='caption'>
                    Published: {dayjs(slide.published).format('D MMM YYYY')}
                  </Typography>

                  <Stack spacing={2}>{slide.content}</Stack>
                </Stack>
              </Box>
            </StyledSlide>
          ))}
        </Slider>
      </CarouselProvider>
    </Widget>
  );
};

export default HubUpdatesWidget;
