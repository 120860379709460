import { ErrorScreen, ErrorScreenVariants } from '@brandbank/portal-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AxiosError } from 'axios';
import { Company } from 'features/companies';
import { paths } from 'features/routing';
import { Link } from 'react-router-dom';
import { RefetchQueryFn } from 'utils/types';

type CompanyDetailsLayoutErrorProps = {
  error: AxiosError | null;
  refetch: RefetchQueryFn<Company>;
};

const CompanyDetailsLayoutError = ({
  error,
  refetch,
}: CompanyDetailsLayoutErrorProps) => (
  <ErrorScreen
    variant={ErrorScreenVariants.ERROR}
    title='Something went wrong.'
    subtitle={
      error?.message || "We've encountered an error. Please try again later."
    }
    action={
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: ['center', 'center', 'left'],
        }}
      >
        <Button component={Link} to={paths.dashboard} variant='contained'>
          Back to home
        </Button>

        <Button variant='outlined' onClick={() => refetch()}>
          Try Again
        </Button>
      </Box>
    }
  />
);

export default CompanyDetailsLayoutError;
