import axios from 'axios';
import environmentVariables from './environmentVariables';

const { apiUrl, subscriptionKey } = environmentVariables;

const instance = axios.create({
  baseURL: `${apiUrl}/`,
  timeout: 60000, // timeout after 1 minute,
  timeoutErrorMessage:
    'An error occurred, please try again later or contact Integration Support',
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (!apiUrl) throw new Error('No apiUrl given');
    if (!subscriptionKey) throw new Error('No subscription key given');
    if (!token) throw new Error('No token found');

    // eslint-disable-next-line
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Ocp-Apim-Subscription-Key': subscriptionKey,
      'Content-Type': 'application/json',
    };

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
