import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Field, useField } from 'formik';
import { styled } from '@mui/material';
import { Switch } from 'formik-mui';
import { Expandable } from 'features/ui';

type CookieSwitchesProps = {
  children: React.ReactNode;
  disabled?: boolean;
  name: string;
  title: string;
};

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase:not(.Mui-checked)': {
    color: theme.palette.grey[300],
  },
}));

const CookieSwitches = (props: CookieSwitchesProps): JSX.Element => {
  const { children, disabled, name, title } = props;

  const [field] = useField(name);
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = (): void => {
    setExpanded((prev) => !prev);
  };

  return (
    <Expandable
      expanded={expanded}
      onClick={handleToggleExpand}
      summary={
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          sx={{ width: '100%' }}
        >
          <Typography>{title}</Typography>
          <Box onClick={(e) => e.stopPropagation()}>
            <Field
              component={StyledSwitch}
              checked={field.value}
              disabled={disabled}
              name={name}
            />
          </Box>
        </Stack>
      }
    >
      {children || <></>}
    </Expandable>
  );
};

export default CookieSwitches;
