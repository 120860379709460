import { Button, CircularProgress, styled } from '@mui/material';
import { pink } from '@mui/material/colors';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import useFavouriteFeedActions from '../hooks/useFavouriteFeedActions';

interface FavouriteFeedButtonProps {
  purposeId: number;
  purposeName: string;
}

const StyledButton = styled(Button)({
  minWidth: 151,

  '&.MuiButton-outlined': {
    borderColor: pink[300],
    color: pink[300],
    '&:hover': {
      backgroundColor: pink[50],
      borderColor: pink[400],
    },
  },
  '&.MuiButton-contained': {
    backgroundColor: pink[300],
    '&:hover': {
      backgroundColor: pink[400],
    },
  },
});

const FavouriteFeedButton = ({
  purposeId,
  purposeName,
}: FavouriteFeedButtonProps) => {
  const { isFavourited, isLoading, toggle } = useFavouriteFeedActions(
    purposeId,
    purposeName
  );

  const Icon = isFavourited ? FavoriteIcon : FavoriteBorderIcon;

  return (
    <StyledButton
      disabled={isLoading}
      disableElevation
      onClick={toggle}
      endIcon={!isLoading && <Icon />}
      variant={isFavourited ? 'contained' : 'outlined'}
    >
      {isLoading ? (
        <CircularProgress
          size={30}
          sx={{
            color: isFavourited ? 'common.white' : pink[300],
          }}
        />
      ) : (
        <>{isFavourited ? 'Unfavourite feed' : 'Favourite feed'}</>
      )}
    </StyledButton>
  );
};

export default FavouriteFeedButton;
