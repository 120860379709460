import { Button } from '@mui/material';
import { paths } from 'features/routing';
import { Link } from 'react-router-dom';

type GoToCompanyButtonProps = {
  companyId: string;
};

const GoToCompanyButton = ({ companyId }: GoToCompanyButtonProps) => (
  <Button
    variant='outlined'
    component={Link}
    to={paths.companyOverview(companyId)}
  >
    Go To Company Page
  </Button>
);

export default GoToCompanyButton;
