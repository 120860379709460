import { formatDate } from '@brandbank/portal-components';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { CardRow, Expandable } from 'features/ui';
import { useMemo } from 'react';
import { useFeedDetailsCtx } from '../FeedDetails.context';
import { getSubcodesFromScopes } from '../feeds.helpers';

const FeedsOverviewProductScope = () => {
  const { detail, scopes } = useFeedDetailsCtx();

  const patternSubcodes = getSubcodesFromScopes(scopes, 1);
  const routingSubcodes = getSubcodesFromScopes(scopes, 2);

  const join = (arr: string[] | null) => (arr ? arr.join(', ') : null);

  const productStartDate = useMemo(() => {
    if (!detail.startDate) return null;

    const formattedDate = formatDate(detail.startDate, true);
    return formattedDate.indexOf('/1900') > -1 ? 'All products' : formattedDate;
  }, [detail.startDate]);

  const renderExpandable = (title: string, items: string[]) => {
    if (items.length === 0) return <CardRow label={title} value={null} />;

    return (
      <Expandable summary={title}>
        {items.map((item) => (
          <Typography key={item}>{item}</Typography>
        ))}
      </Expandable>
    );
  };

  return (
    <Card>
      <CardHeader
        title='Purpose Information'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        <Stack spacing={1}>
          <CardRow label='Product Start Date:' value={productStartDate} />
          <CardRow
            label='Target Market:'
            value={join(detail.targetMarketList)}
          />
          <CardRow label='Usage:' value={join(detail.targetUsageList)} />

          {renderExpandable('Pattern Subcodes', patternSubcodes)}
          {renderExpandable('Routing Subcodes', routingSubcodes)}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FeedsOverviewProductScope;
