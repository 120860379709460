import { StatusEnum, StatusUI } from '@brandbank/portal-components';
import { Alert, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BroadcastChannel } from 'broadcast-channel';
import { FavouriteFeedButton } from 'features/favourite-feeds';
import { FeedDetailsCtx, useFeedDetails } from 'features/feeds';
import { ProductSearchProvider } from 'features/product-search';
import { paths } from 'features/routing';
import { usePrompt } from 'hooks/useBlocker';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import FeedDetailsLayoutError from './FeedDetailsLayoutError';
import FeedDetailsNavbar from './FeedDetailsNavbar';

/*
    PurposeUIManagement can use this broadcast channel to
    post a message with a boolean on whether to block navigation away
    from feed details pages
*/
const bc = new BroadcastChannel('feed-details-routing');

// Allows purpose management refetch feed details info
const refetchFeedDetailsChannel = new BroadcastChannel('refetch-feed-details');

const FeedDetailsLayout = () => {
  const { id = '' } = useParams();
  const { data, error, status, refetch } = useFeedDetails(id);

  const location = useLocation();
  const navigate = useNavigate();

  const [blockNavigation, setBlockNavigation] = useState(false);

  const purposeId = Number(id);
  const purposeName = data?.detail.purposeName || '';

  bc.onmessage = (shouldBlockNavigation: boolean) => {
    setBlockNavigation(shouldBlockNavigation);
  };

  refetchFeedDetailsChannel.onmessage = (shouldRefetch: boolean) => {
    if (shouldRefetch) refetch();
  };

  useEffect(() => {
    if (location.pathname === `/feeds/${id}`) {
      navigate(paths.feedDetailsOverview(id), { replace: true });
    }
  }, [id, location.pathname, navigate]);

  usePrompt('Any unsaved changes will be lost', blockNavigation);

  return (
    <>
      {status === 'loading' && (
        <StatusUI splashPage status={StatusEnum.LOADING} />
      )}

      {status === 'error' && (
        <FeedDetailsLayoutError
          error={error}
          purposeId={purposeId}
          purposeName={purposeName}
        />
      )}

      {status === 'success' && (
        <>
          {data.detail.isDeleted ? (
            <Alert
              severity='warning'
              sx={{
                border: (theme) => `solid 1px ${theme.palette.warning.main}`,
                margin: '8px 24px 0',
                width: 'max-content',
                borderRadius: 0.75,
              }}
            >
              This feed has been deleted. If you believe this to be a mistake,
              please contact your Account Handler or Administrator.
            </Alert>
          ) : (
            <></>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 24px 0',
            }}
          >
            {purposeName.length > 0 && (
              <Typography
                variant='h6'
                sx={{
                  fontStyle: 'italic',
                  alignSelf: 'center',
                }}
              >
                {purposeName}
              </Typography>
            )}

            <FavouriteFeedButton
              purposeId={Number(purposeId)}
              purposeName={purposeName}
            />
          </Box>

          <FeedDetailsNavbar
            purposeId={id}
            purposeSubcode={data.detail.subscriberCode || '-'}
            purposeCompanyId={data.detail.companyId}
          />

          <Box sx={{ p: 2 }}>
            <FeedDetailsCtx.Provider value={data}>
              <ProductSearchProvider>
                <Outlet />
              </ProductSearchProvider>
            </FeedDetailsCtx.Provider>
          </Box>
        </>
      )}
    </>
  );
};

export default FeedDetailsLayout;
