const feedPaths = {
  feeds: '/feeds',
  feedDetails: '/feeds/:id/',
  createPurpose: '/create-purpose',
  feedDetailsActions: (id: string) => `/feeds/${id}/actions`,
  feedDetailsOverview: (id: string) => `/feeds/${id}/overview`,
  feedDetailsProducts: (id: string) => `/feeds/${id}/products`,
  feedDetailsConnectTemplates: (id: string) => `/feeds/${id}/connect-templates`,
  feedDetailsAuditLogs: (id: string) => `/feeds/${id}/audit-logs`,
  feedDetailsPurposeSettings: (id: string) => `/feeds/${id}/purpose-settings`,
};

const extractPaths = {
  extractList: '/extracts',
  extracts: '/extracts/*',
};

const companyPaths = {
  companies: '/companies',
  companyDetails: '/companies/:id/',
  companyOverview: (id: string) => `/companies/${id}/details`,
  companyConnectMax: (id: string) => `/companies/${id}/connect-max`,
  companyIntegrations: (id: string) => `/companies/${id}/integrations`,
  companyUsers: (id: string) => `/companies/${id}/users`,
  companyLicenses: (id: string) => `/companies/${id}/licenses`,
};

const adminPaths = {
  admin: '/admin',
  adminAuditLogs: '/admin/audit-logs',
  adminApprovalRoutes: '/admin/feed-reset-approval-routes',
};

const paths = {
  dashboard: '/',
  login: '/login',
  documentation: '/documentation/*',
  ...adminPaths,
  ...extractPaths,
  ...companyPaths,
  ...feedPaths,
} as const;

export default paths;
