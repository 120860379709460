import { AxiosError } from 'axios';
import axios from 'config/axios';
import {
  ProductResendBatch,
  ProductResendError,
  ProductResendLimitError,
} from '../productResend.types';

type Params = {
  gtins: string[];
  purposeId: string;
  pvids: number[];
  reason: string;
  requestSource: string;
};

export default async function postProductsForResend(params: Params) {
  const { gtins, pvids, purposeId, reason, requestSource } = params;

  try {
    const response = await axios.post('ResendProductVersions', {
      reason,
      requestSource,
      productIds: gtins,
      productVersionIds: pvids,
      purposeId,
    });

    if (response.status !== 200) return Promise.reject(response.statusText);

    return {
      data: response.data as ProductResendBatch,
      status: 'success' as const,
    };
  } catch (err) {
    const error = err as AxiosError;

    if (!error.response) return Promise.reject(error);

    const retryAfter = error.response.headers['retry-after'] || '';

    if (error.code === '500') {
      return {
        data: null,
        status: 'server-error' as const,
      };
    }

    if (error.code === '400') {
      return {
        data: error.response.data as ProductResendError[],
        retryAfter,
        status: 'resend-error' as const,
      };
    }

    if (error.code === '429') {
      return {
        data: error.response.data as ProductResendLimitError,
        retryAfter,
        status: 'limit-error' as const,
      };
    }

    return Promise.reject(error);
  }
}
