import withErrorBoundary from 'hocs/withErrorBoundary';
import { List } from '@mui/material';
import { AdvancedSearch } from 'features/ui';
// import CompanyListItem from './CompanyListItem';

const CompaniesList = (): JSX.Element => {
  // useCompaniesList()

  return (
    <>
      <AdvancedSearch />
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {/* {[].map((company) => {
          return <CompanyListItem key={company.id} company={company} />;
        })} */}
      </List>
    </>
  );
};

export default withErrorBoundary(CompaniesList);
