import Providers from './providers';
import { Routes, useAuthErrorListener } from 'features/routing';
import './fonts.css';
import CssBaseline from '@mui/material/CssBaseline';
import CookieBannerOverlay from 'features/cookies/CookieBannerOverlay';

const App = (): JSX.Element => {
  useAuthErrorListener();

  return (
    <Providers>
      <CssBaseline />
      <CookieBannerOverlay />
      <Routes />
    </Providers>
  );
};

export default App;
