import { useEffect, useState } from 'react';
import { Modal, StatusEnum, StatusUI } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import UserFeedbackForm, {
  FormValues,
  initialFormValues,
} from './UserFeedbackForm';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import usePostFeedback from '../api/usePostFeedback';

type FormType = 'feedback' | 'bug';

const StyledModal = styled(Modal)({
  minHeight: '30vh',
});

export default NiceModal.create(() => {
  const { hide, remove, visible } = useModal();

  const [formStatus, setFormStatus] = useState('idle');
  const [formType, setFormType] = useState<FormType>('feedback');
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const { mutate: postFeedback, status } = usePostFeedback();

  const handleClose = () => {
    if (formStatus !== 'loading') {
      setFormValues(initialFormValues);
      hide();
    }
  };

  const handleFormTypeChange = (event: SelectChangeEvent): void => {
    const newType = event.target.value as FormType;
    setFormType(newType);
  };

  const handleSubmit = (values: FormValues) => {
    const { description, files, steps, subject } = values;

    setFormValues(values);

    const fullSteps = `\n\n STEPS TO REPRODUCE\n ${steps}`;

    const fullDescription = `${description}${
      formType === 'bug' ? fullSteps : ''
    }`;

    const formattedFiles = files.map((item) => {
      let encoded = item.base64.replace(/^data:(.*,)?/, '');

      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }

      return { Filename: item.file.name, Data: encoded };
    });

    postFeedback({
      subject,
      description: fullDescription,
      images: formattedFiles,
      type: formType,
    });
  };

  useEffect(() => setFormStatus(status), [status]);

  return (
    <StyledModal
      action={
        formStatus === 'idle' && (
          <>
            <Button onClick={hide} variant='outlined'>
              Cancel
            </Button>
            <Button form='feedback-form' type='submit' variant='contained'>
              Submit
            </Button>
          </>
        )
      }
      muiTransitionProps={{ onExited: remove }}
      onClose={handleClose}
      open={visible}
      title='Give feedback'
    >
      {formStatus === 'loading' && (
        <StatusUI
          splashPage
          status={StatusEnum.LOADING}
          title='Submitting...'
        />
      )}

      {formStatus === 'error' && (
        <StatusUI
          action={
            <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
              <Button
                color='error'
                onClick={() => setFormStatus(StatusEnum.IDLE)}
                variant='outlined'
              >
                Back to form
              </Button>
              <Button color='error' onClick={hide} variant='contained'>
                Close
              </Button>
            </Stack>
          }
          status={StatusEnum.ERROR}
          subtitle='There was an error submitting your query'
        />
      )}

      {formStatus === 'idle' && (
        <>
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography>
              Please use this form to submit feedback, or report any issues,
              with the Client Integration Hub.
            </Typography>

            <Typography>
              If you are experiencing any bugs or issues with any aspect of your
              feed(s), please contact our Client Integration Support Team via{' '}
              <Link
                href='https://brandbank.atlassian.net/servicedesk/customer/portal/1'
                target='_blank'
              >
                JIRA
              </Link>
            </Typography>

            <Typography>
              If you need to change your feed configuration, update any aspects
              or would like to talk to us about upgrading to include
              functionality such as rich content, health &amp; wellness or
              sustainability, please contact our Pre-Sales Team via{' '}
              <Link href='mailto:presales@smb.nielseniq.com'>
                presales@smb.nielseniq.com
              </Link>
            </Typography>
          </Stack>

          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel
              id='form-type-label'
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              Feedback type
            </InputLabel>
            <Select
              labelId='form-type-label'
              onChange={handleFormTypeChange}
              value={formType}
            >
              <MenuItem value='feedback'>Give Feedback</MenuItem>
              <MenuItem value='bug'>Report Bug</MenuItem>
            </Select>
          </FormControl>

          <UserFeedbackForm
            formType={formType}
            initialValues={formValues}
            onSubmit={handleSubmit}
          />
        </>
      )}

      {formStatus === 'success' && (
        <StatusUI
          action={
            <Button onClick={hide} variant='contained'>
              OK
            </Button>
          }
          status={StatusEnum.SUCCESS}
          subtitle='Your query was submitted successfully!'
          sx={{ alignItems: 'center' }}
        />
      )}
    </StyledModal>
  );
});
