import { createContext, Dispatch, ReactNode, useContext } from 'react';
import {
  ProductSearchReducerAction,
  ProductSearchReducerState,
} from './productSearch.types';
import useProductSearchReducer from './ProductSearch.reducer';

export const ProductSearchCtx = createContext<{
  state: ProductSearchReducerState;
  dispatch: Dispatch<ProductSearchReducerAction>;
} | null>(null);

export const useProductSearchCtx = () => {
  const data = useContext(ProductSearchCtx);

  if (!data) {
    throw new Error(
      'useProductSearchCtx is being used outside of ProductSearch.Provider'
    );
  }

  return data;
};

type ProductSearchProviderProps = {
  children: ReactNode;
};

export const ProductSearchProvider = ({
  children,
}: ProductSearchProviderProps) => {
  const [state, dispatch] = useProductSearchReducer();

  return (
    <ProductSearchCtx.Provider value={{ dispatch, state }}>
      {children}
    </ProductSearchCtx.Provider>
  );
};
