import { Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Select } from 'formik-mui';
import { isEqual } from 'lodash';
import * as yup from 'yup';
import { MappingValueField } from '.';
import { userFacingMappingType } from '../connect-max.constants';
import { getMappingTypeOptions } from '../connect-max.helpers';
import {
  ConnectMaxAssetItem,
  ConnectMaxAssetType,
  ConnectMaxMappingItem,
} from '../connect-max.types';
import { mappingTypeValidation, mappingValidation } from '../schemas';

type EditConnectMaxAssetModal = {
  assetType: ConnectMaxAssetType;
  item: ConnectMaxMappingItem;
  assets: ConnectMaxAssetItem[];
};

export default NiceModal.create(
  ({ assetType, item, assets }: EditConnectMaxAssetModal) => {
    const { hide, remove, visible, resolve } = useModal();

    const formId = 'edit-connect-max-asset-form';

    const editConnectMaxAssetModalSchema = yup.object({
      mappingType: mappingTypeValidation.test(
        'valid-mapping-type-test',
        `Shot Type can only be set for Image Files`,
        (value) => {
          if (!value) return true;

          if (assetType === 'imageFile') return true; // imageFile can be any mapping type
          if (value === 'shotType') return false; // shotType only valid for imageFile

          return true;
        }
      ),
      mapping: mappingValidation,
    });

    const onSubmit = (
      values: ConnectMaxMappingItem,
      formikHelpers: FormikHelpers<ConnectMaxMappingItem>
    ) => {
      formikHelpers.setSubmitting(false);

      if (assets) {
        const existingMappings =
          assets
            .flatMap((asset) => asset.positions)
            ?.map((pos) => pos.mapping) || [];

        if (existingMappings.includes(values.mapping)) {
          formikHelpers.setFieldError(
            'mapping',
            'This mapping value already exists for this Connect Max configuration'
          );
          return;
        }
      }

      resolve(values);
      hide();
      return;
    };

    const onCancel = () => {
      resolve(item);
      hide();
    };

    return (
      <Formik
        initialValues={item}
        onSubmit={onSubmit}
        validationSchema={editConnectMaxAssetModalSchema}
      >
        {({ handleChange, handleSubmit, values }) => {
          const noChanges = isEqual(item, values);

          return (
            <Modal
              action={
                <>
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button
                    type='submit'
                    variant='contained'
                    form={formId}
                    disabled={noChanges}
                  >
                    Submit
                  </Button>
                </>
              }
              muiTransitionProps={{ onExited: remove }}
              open={visible}
              onClose={hide}
              title='Edit Item'
              width='30%'
            >
              <Form id={formId} onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Field
                    component={Select}
                    fullWidth
                    label='Mapping Type'
                    name='mappingType'
                    onChange={handleChange}
                  >
                    {getMappingTypeOptions(assetType).map(
                      (mappingType, idx) => (
                        <MenuItem
                          key={`mapping-type-option-${idx}`}
                          value={mappingType}
                        >
                          {userFacingMappingType[mappingType]}
                        </MenuItem>
                      )
                    )}
                  </Field>

                  <MappingValueField
                    handleChange={handleChange}
                    showInfoIcon={values.mappingType === 'additionalAsset'}
                  />
                </Stack>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
);
