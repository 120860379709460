import {
  booleanToString,
  createSearchColumnDef,
  formatDate,
  useTable,
} from '@brandbank/portal-components';
import { ColumnDef, FilterFn, Row } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import useUser from 'hooks/useUser';
import { ProductItem } from '../productSearch.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Checkbox from '@mui/material/Checkbox';
import { useFeedDetailsCtx } from 'features/feeds';
import { useProductSearchCtx } from '../ProductSearch.context';
import ProductsTableSelectAll from '../components/ProductsTableSelectAll';
import { isRowSelectable } from '../productSearch.helpers';

dayjs.extend(utc);

export type TableOptionsMeta = {
  canResend: boolean;
  enableRowSelection: (row: Row<ProductItem>) => boolean;
};

const SEARCHABLE_KEYS: (keyof ProductItem)[] = [
  'gtin',
  'productVersionId',
  'productVersionProductDescription',
];

const useProductsTable = (initialItems: ProductItem[]) => {
  const { hasRole } = useUser();
  const { detail } = useFeedDetailsCtx();
  const { dispatch, state } = useProductSearchCtx();
  const { columnFilters, selectedProducts } = state;

  const subcode = detail.subscriberCode || '-';

  const hasLimitedResends = hasRole('Resends - Limited', subcode);
  const hasUnlimitedResends = hasRole('Resends - Unlimited', subcode);
  const canResend = hasLimitedResends || hasUnlimitedResends;

  const hasCurrentItems = initialItems.some(
    (item) => item.productVersionStatusDescription === 'Current'
  );

  const subcodeFilter: FilterFn<ProductItem> = (row, columnId, value) => {
    const subcodes: string[] = value.split(',');

    return subcodes.some((subcode) => subcode === row.getValue(columnId));
  };

  const columns = useMemo<ColumnDef<ProductItem>[]>(
    () => [
      createSearchColumnDef('search', SEARCHABLE_KEYS),
      {
        id: 'select',
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!isRowSelectable(row)}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        header: ({ table }) => <ProductsTableSelectAll table={table} />,
      },
      {
        accessorKey: 'gtin',
        cell: (info) => info.getValue(),
        header: () => 'GTIN',
      },
      {
        accessorFn: (row) => String(row.productVersionId),
        cell: (info) => info.getValue(),
        header: () => 'PVID',
        id: 'productVersionId',
      },
      {
        accessorKey: 'productVersionProductDescription',
        cell: (info) => info.getValue(),
        header: () => 'Product Description',
      },
      {
        accessorKey: 'subscriberCode',
        cell: (info) => info.getValue(),
        filterFn: subcodeFilter,
        header: () => 'Subcode',
      },
      {
        accessorKey: 'productVersionStatusDescription',
        cell: (info) => info.getValue(),
        header: () => 'Status',
      },
      {
        accessorFn: (row) => dayjs.utc(row.dateLastSent).toDate(),
        accessorKey: 'dateLastSent',
        cell: ({ row }) => {
          const date = row.original?.dateLastSent;
          return date ? formatDate(date, true, true) : '';
        },
        header: () => 'Date last sent',
        sortingFn: 'datetime',
      },
      {
        accessorFn: (row) => dayjs.utc(row.productVersionDateUtc).toDate(),
        accessorKey: 'productVersionDateUtc',
        cell: (info) => formatDate(info.getValue() as string, true, true),
        header: 'Product Version Date (UTC)',
        sortingFn: 'datetime',
      },
      {
        accessorFn: (row) => booleanToString(row.isQueued),
        enableSorting: false,
        id: 'isQueued',
        header: 'Queued?',
      },
      {
        accessorFn: (row) => booleanToString(row.isRanged),
        enableSorting: false,
        id: 'isRanged',
        header: 'Ranged?',
      },
    ],
    []
  );

  const table = useTable({
    columns,
    data: initialItems,
    initialColumnFilters: columnFilters,
    initialRowSelection: selectedProducts,
    state: {
      columnVisibility: { search: false, select: canResend && hasCurrentItems },
    },
    meta: {
      canResend,
      enableRowSelection: isRowSelectable,
      hasCurrentItems,
    },
  });

  const currentColumnFilters = table.getState().columnFilters;
  const currentItems = table.getRowModel().rows;
  const currentRowSelection = table.getState().rowSelection;

  useEffect(() => {
    dispatch({ payload: currentColumnFilters, type: 'UPDATE_COLUMN_FILTERS' });
  }, [currentColumnFilters, dispatch]);

  useEffect(() => {
    dispatch({
      payload: currentRowSelection,
      type: 'UPDATE_SELECTED_PRODUCTS',
    });
  }, [currentRowSelection, dispatch]);

  useEffect(() => {
    const hasNoCurrentProducts = !currentItems.some(
      (item) => item.original.productVersionStatusDescription === 'Current'
    );

    if (hasNoCurrentProducts) {
      const hasCurrentFilter = currentColumnFilters.find(
        ({ id, value }) =>
          id === 'productVersionStatusDescription' && value === 'Current'
      );

      if (hasCurrentFilter) {
        dispatch({
          payload: [...currentColumnFilters].filter(
            ({ id }) => id !== 'productVersionStatusDescription'
          ),
          type: 'UPDATE_COLUMN_FILTERS',
        });
      }
    }
  }, [currentColumnFilters, currentItems, dispatch]);

  return table;
};

export default useProductsTable;
