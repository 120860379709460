import * as yup from 'yup';
import { userFacingMappingType } from './connect-max.constants';
import { CONNECT_TEMPLATE_VALIDATIONS } from 'features/connect-templates/components/ConnectTemplateForm';

export const mappingTypeValidation = yup
  .string()
  .typeError('Mapping Type is required.')
  .required('Mapping Type is required.')
  .oneOf(Object.keys(userFacingMappingType), 'Invalid Mapping Type');

export const mappingValidation = yup
  .string()
  .test(
    'mapping-numeric-test',
    'Mapping value must only contain numeric characters when mapping type is "Shot Type"',
    (value, context) => {
      if (!value) return true;
      if (
        context.parent.mappingType &&
        context.parent.mappingType === 'shotType'
      ) {
        const isNumeric = new RegExp('^[0-9]*$').test(value);
        return isNumeric;
      }
      return true;
    }
  )
  .test(
    'mapping-shot-type-test',
    'Mapping value cannot be 1 for Shot Types',
    (value, context) => {
      if (!value) return true;
      if (
        context.parent.mappingType &&
        context.parent.mappingType === 'shotType' &&
        value === '1'
      )
        return false;
      return true;
    }
  )
  .test(
    'mapping-character-test',
    'Mapping value must only contain alphanumeric, space or underscore characters when mapping type is "Additional Asset"',
    (value, context) => {
      if (!value) return true;
      if (
        context.parent.mappingType &&
        context.parent.mappingType === 'additionalAsset'
      ) {
        // Additional asset mapping value should match connect template names
        const isValid =
          CONNECT_TEMPLATE_VALIDATIONS.characterValidation.regex.test(value);
        return isValid;
      }
      return true;
    }
  )
  .test(
    'mapping-exclusively-numeric-test',
    'Mapping value cannot exclusively contain numbers when mapping type is "Additional Asset"',
    (value, context) => {
      if (!value) return true;
      if (
        context.parent.mappingType &&
        context.parent.mappingType === 'additionalAsset'
      ) {
        // Additional asset mapping value should match connect template names
        const isValid =
          CONNECT_TEMPLATE_VALIDATIONS.nonNumeric.regex.test(value);
        return isValid;
      }
      return true;
    }
  )
  .required('Mapping is required.')
  .typeError('Mapping is required.');
