import {
  useFavouriteFeeds,
  useAddFavouriteFeed,
  useDeleteFavouriteFeed,
} from '../api';

const useFavouriteFeedActions = (purposeId: number, purposeName: string) => {
  const { data, error, isFetching } = useFavouriteFeeds();

  const { mutate: addFavourite, isLoading: isAdding } = useAddFavouriteFeed();

  const { mutate: deleteFavourite, isLoading: isDeleting } =
    useDeleteFavouriteFeed();

  const isLoading = isFetching || isAdding || isDeleting;

  const isFavourited = !!data?.find((item) => item.id === purposeId);

  const toggle = () => {
    isFavourited
      ? deleteFavourite(String(purposeId))
      : addFavourite({ id: purposeId, name: purposeName });
  };

  return {
    data,
    error,
    isLoading,
    isFavourited,
    toggle,
  };
};

export default useFavouriteFeedActions;
