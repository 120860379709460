import axios from 'config/axios';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Company } from '../companies.types';

async function getCompanyDetails(companyId: string) {
  try {
    const response = await axios.get<Company>('GetCompanyDetails', {
      params: { companyId },
    });

    if (response.status === 204) {
      return Promise.reject(
        new Error(`No details were found for companyId: ${companyId}`)
      );
    }

    return response.data;
  } catch (err) {
    const error = err as AxiosError;

    if (error.code === '401') {
      return Promise.reject(
        new Error('You do not have authorisation to view this company')
      );
    }

    if (error.code === '422') {
      return Promise.reject(new Error('Please provide a valid companyId'));
    }

    return Promise.reject(err);
  }
}

const useCompanyDetails = (companyId: string) =>
  useQuery<Company, AxiosError>(
    ['companyDetails', companyId],
    () => getCompanyDetails(companyId),
    { enabled: companyId !== '0' }
  );

export default useCompanyDetails;
