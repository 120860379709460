import { useEffect } from 'react';
import { show } from '@ebay/nice-modal-react';
import AuthErrorModal from '../components/AuthErrorModal';
import WindowErrorModal from '../components/WindowErrorModal';

const useAuthErrorListener = () => {
  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );

    if (params.error) {
      show(
        params.errors === 'unauthorized' ? AuthErrorModal : WindowErrorModal
      );
    }
  }, []);
};

export default useAuthErrorListener;
