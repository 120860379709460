import {
  Icon,
  Modal,
  StatusEnum,
  StatusUI,
} from '@brandbank/portal-components';
import { create, useModal } from '@ebay/nice-modal-react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useProductSearchCtx } from '../ProductSearch.context';
import { useFeedDetailsCtx } from 'features/feeds';
import { paths } from 'features/routing';
import ProductSearchForm from './ProductSearchForm';
import useProductSearch from '../hooks/useProductSearch';
import { useEffect } from 'react';

export default create(() => {
  const navigate = useNavigate();

  const { detail } = useFeedDetailsCtx();
  const { hide, visible } = useModal();
  const { state } = useProductSearchCtx();

  const purposeId = detail.id || 0;

  const search = useProductSearch(purposeId);

  const backToOverviewTab = () => {
    navigate(paths.feedDetailsOverview(String(purposeId)));
  };

  const handleClose = () => {
    if (state.status === 'loading') return;
    if (state.status === 'idle') backToOverviewTab();
    hide();
  };

  useEffect(() => {
    if (state.status === 'success') {
      hide();
    }
  }, [hide, state.status]);

  return (
    <Modal
      onClose={handleClose}
      open={visible}
      title={state.status === 'idle' ? 'Product search' : ''}
    >
      {state.status === 'loading' ? (
        <StatusUI
          status={StatusEnum.LOADING}
          title='Searching for products...'
        />
      ) : (
        <Stack spacing={2}>
          <Typography variant='h6'>
            Please enter some search criteria
          </Typography>

          <ProductSearchForm id='product-search-form' onSubmit={search} />

          <Button
            form='product-search-form'
            startIcon={<Icon iconName='search' />}
            sx={{ alignSelf: 'flex-end' }}
            type='submit'
            variant='contained'
          >
            Accept and search
          </Button>
        </Stack>
      )}
    </Modal>
  );
});
