import { Company } from 'features/companies';
import { ConnectMaxInfoButton } from 'features/connect-max';
import { paths } from 'features/routing';
import { RoutingTabs } from 'features/ui';
import useUser from 'hooks/useUser';
import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

type CompanyDetailsNavbarProps = {
  companyId: string;
  company: Company;
};

const CompanyDetailsNavbar = ({
  companyId,
  company,
}: CompanyDetailsNavbarProps) => {
  const { hasRole } = useUser();
  const location = useLocation();
  const routeMatch = useMatch(`/companies/${companyId}/:tab`);

  const currentTab = useMemo(() => {
    if (location.pathname.indexOf('/connect-max/') > -1) {
      return 'connect-max';
    }

    if (routeMatch) {
      const { tab } = routeMatch.params;
      return tab || 'details';
    }

    return 'details';
  }, [location, routeMatch]);

  return (
    <RoutingTabs
      tabs={[
        {
          label: 'Details',
          to: paths.companyOverview(companyId),
        },
        {
          label: 'Integrations',
          to: paths.companyIntegrations(companyId),
        },
        {
          label: 'Connect Max',
          to: paths.companyConnectMax(companyId),
          show: hasRole('Manage Purposes') && company.companyTypeId === 2, // Retailer Type
        },
        {
          label: 'Licenses',
          to: paths.companyLicenses(companyId),
        },
        {
          label: 'Users',
          to: paths.companyUsers(companyId),
        },
      ]}
      value={currentTab}
    >
      {currentTab === 'connect-max' && <ConnectMaxInfoButton />}
    </RoutingTabs>
  );
};

export default CompanyDetailsNavbar;
