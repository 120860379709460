import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import axios from 'config/axios';
import { FeedActivityLogItem } from '../feeds.types';

type Response = {
  activityLog: {
    entries: FeedActivityLogItem[];
  };
};

async function getFeedActivityLog(purposeId: string) {
  const response = await axios.get<Response>('GetActivityLog', {
    params: {
      activityLogHistoryDays: 14,
      purposeId,
    },
  });

  return response.data.activityLog.entries;
}

const useFeedActivityLog = (purposeId: string) =>
  useQuery<FeedActivityLogItem[], AxiosError>(['feedActivity', purposeId], () =>
    getFeedActivityLog(purposeId)
  );

export default useFeedActivityLog;
