import GoToCompanyButton from 'features/companies/components/GoToCompanyButton';
import { paths } from 'features/routing';
import { RoutingTabs } from 'features/ui';
import useUser from 'hooks/useUser';
import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

type FeedDetailsNavbarProps = {
  purposeId: string;
  purposeSubcode: string;
  purposeCompanyId: string | null;
};

const FeedDetailsNavbar = ({
  purposeId,
  purposeSubcode,
  purposeCompanyId,
}: FeedDetailsNavbarProps) => {
  const { hasRole } = useUser();
  const location = useLocation();
  const routeMatch = useMatch(`/feeds/${purposeId}/:tab`);

  const canViewConnectTemplates = hasRole('Connect 1.0 (CRUD)');
  const canViewAuditLogs = hasRole('Audit Log Viewer', purposeSubcode);
  const canManagePurposes = hasRole('Manage Purposes');
  const canResetFeed = hasRole('Feed Reset');

  const currentTab = useMemo(() => {
    if (location.pathname.indexOf('/connect-templates/') > -1) {
      return 'connect-templates';
    }

    if (routeMatch) {
      const { tab } = routeMatch.params;
      return tab || 'overview';
    }

    return 'overview';
  }, [location, routeMatch]);

  return (
    <RoutingTabs
      tabs={[
        {
          label: 'Overview',
          to: paths.feedDetailsOverview(purposeId),
        },
        {
          label: 'Products',
          to: paths.feedDetailsProducts(purposeId),
        },
        {
          label: 'Connect Templates',
          show: canViewConnectTemplates,
          to: paths.feedDetailsConnectTemplates(purposeId),
        },
        {
          label: 'Audit Logs',
          show: canViewAuditLogs,
          to: paths.feedDetailsAuditLogs(purposeId),
        },
        {
          label: 'Purpose settings',
          show: canManagePurposes,
          to: paths.feedDetailsPurposeSettings(purposeId),
        },
        {
          label: 'Actions',
          show: canResetFeed,
          to: paths.feedDetailsActions(purposeId),
        },
      ]}
      value={currentTab}
    >
      {hasRole('Manage Purposes') && purposeCompanyId && (
        <GoToCompanyButton companyId={purposeCompanyId} />
      )}
    </RoutingTabs>
  );
};

export default FeedDetailsNavbar;
