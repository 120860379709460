import * as React from 'react';
import {
  FallbackProps,
  withErrorBoundary as withBoundary,
} from 'react-error-boundary';
import Button from '@mui/material/Button';
import { StatusUI, StatusEnum } from '@brandbank/portal-components';

const withErrorBoundary = <T,>(
  Component: React.ComponentType<T>,
  onError?: (error: Error, info: { componentStack: string }) => void,
  onReset?: (...args: Array<unknown>) => void
): React.ComponentType<T> => {
  const fallback = (props: FallbackProps): JSX.Element => (
    <StatusUI
      status={StatusEnum.ERROR}
      {...props}
      action={
        onReset ? (
          <Button color='error' onClick={onReset} variant='contained'>
            Try again
          </Button>
        ) : undefined
      }
    />
  );

  return withBoundary(Component, {
    FallbackComponent: fallback,
    onError: onError || undefined,
    onReset: onReset || undefined,
  });
};

export default withErrorBoundary;
