import { generateUniqueID } from '@brandbank/portal-components';
import NiceModal from '@ebay/nice-modal-react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { TargetMarketChip, TargetMarketSelectModal } from '.';
import { CONNECT_MAX_TARGET_MARKETS } from '../connect-max.constants';
import { TargetMarket } from '../connect-max.types';

type TargetMarketWidgetProps = {
  targetMarkets: TargetMarket[] | null;
  setTargetMarkets: React.Dispatch<React.SetStateAction<TargetMarket[]>>;
};

const TargetMarketWidget = ({
  targetMarkets,
  setTargetMarkets,
}: TargetMarketWidgetProps) => {
  const targetMarketCodes = targetMarkets?.map((item) => item.code) || [];

  const showEditTargetMarketsModal = async () => {
    const initialSelected = CONNECT_MAX_TARGET_MARKETS.filter((x) =>
      targetMarketCodes.includes(x.code)
    );

    const newValues: TargetMarket[] = await NiceModal.show(
      TargetMarketSelectModal,
      {
        options: CONNECT_MAX_TARGET_MARKETS,
        initialSelected,
      }
    );

    newValues && setTargetMarkets(newValues);
  };

  return (
    <Card sx={{ padding: '0 0.5rem' }}>
      <CardHeader
        title='Target Markets'
        action={
          <Button variant='outlined' onClick={showEditTargetMarketsModal}>
            {targetMarkets?.length ? 'Edit' : 'Add'} Target Markets
          </Button>
        }
      />

      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
      >
        <Typography>
          The Connect Max layout for this company will be available for
          suppliers to edit in SXP for products in the below target markets
        </Typography>
        <Stack
          sx={{
            display: 'flex',
            gap: '0.5rem',
            flexDirection: 'row',
            maxWidth: '100%',
            flexWrap: 'wrap',
          }}
        >
          {CONNECT_MAX_TARGET_MARKETS.filter((item) =>
            targetMarketCodes.includes(item.code)
          ).map((tm) => (
            <TargetMarketChip
              label={`${tm.code} | ${tm.description}`}
              key={generateUniqueID()}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TargetMarketWidget;
