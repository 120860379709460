import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as DashboardSVGBase } from 'assets/svg/dashboard.svg';
import { styled } from '@mui/material';
import { show } from '@ebay/nice-modal-react';
import useUser from 'hooks/useUser';
import WelcomeWidgetGetInTouchModal from './WelcomeWidgetGetInTouchModal';

const DashboardSVG = styled(DashboardSVGBase)({
  height: 200,
  width: 200,
});

const WelcomeWidget = (): JSX.Element => {
  const { user } = useUser();
  const userFirstName = user ? user.name.split(' ')[0] : '';

  const handleOpenModal = () => {
    show(WelcomeWidgetGetInTouchModal);
  };

  return (
    <Card
      sx={{ bgcolor: 'primary.dark', color: 'common.white', height: '100%' }}
    >
      <CardContent sx={{ height: '100%', px: [1, 5] }}>
        <Stack
          alignItems='center'
          direction='row'
          spacing={{
            sm: 0,
            md: 4,
          }}
          sx={{ height: '100%' }}
        >
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
              },
            }}
          >
            <DashboardSVG />
          </Box>

          <Stack spacing={2} sx={{ maxWidth: 500, ml: 0 }}>
            <Typography fontWeight='bold' variant='h4'>
              Welcome back{userFirstName ? `, ${userFirstName}!` : '!'}
            </Typography>

            <Typography>
              Use your dashboard to navigate quickly around the Client
              Integration Hub. You can also view important notices, alerts, and
              communications issued by the Client Integration Team.
            </Typography>

            <Button
              onClick={handleOpenModal}
              size='large'
              sx={{ alignSelf: 'start' }}
              variant='contained'
            >
              Get in touch
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default WelcomeWidget;
