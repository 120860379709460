import {
  formatDate,
  SimpleTable,
  TableSkeleton,
} from '@brandbank/portal-components';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { useFeedActivityLog } from '../api';
import { useFeedDetailsCtx } from '../FeedDetails.context';

const FeedsOverviewActivityLog = () => {
  const { detail } = useFeedDetailsCtx();
  const purposeId = String(detail.id || '');

  const { data = [], error, refetch, status } = useFeedActivityLog(purposeId);

  return (
    <Card>
      <CardHeader
        action={
          <Button
            disabled={status === 'loading'}
            onClick={() => refetch()}
            size='small'
            variant='contained'
          >
            Refresh
          </Button>
        }
        subheader='Past 14 days'
        subheaderTypographyProps={{
          variant: 'caption',
        }}
        title='Activity Log'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        {status === 'loading' && <TableSkeleton numColumns={2} numRows={2} />}

        {status === 'error' && (
          <Alert severity='error'>
            <AlertTitle>
              <strong>Unable to load activities</strong>
            </AlertTitle>
            {error
              ? error.message
              : 'An error occurred whilst getting activities'}
          </Alert>
        )}

        {status === 'success' && (
          <>
            {data.length === 0 ? (
              <Typography>No activities found</Typography>
            ) : (
              <Box data-testid='feeds-activity-log-table'>
                <SimpleTable
                  columns={[
                    { label: 'Event Name', id: 'eventName' },
                    {
                      label: 'Event Date',
                      id: 'eventDateTime',
                      render: (item) => formatDate(item.eventDateTime, true),
                    },
                  ]}
                  items={data}
                  sx={{ borderRadius: 1, maxHeight: 300 }}
                  tableProps={{
                    size: 'small',
                    stickyHeader: true,
                  }}
                />
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default FeedsOverviewActivityLog;
