import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, StatusEnum, StatusUI } from '@brandbank/portal-components';
import { styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useFullFeedCredential from '../api/useFullFeedCredential';

const StyledModal = styled(Modal)({
  minHeight: '30vh',
});

type FullFeedCredentialModalProps = {
  purposeId: number;
};

export default NiceModal.create((props: FullFeedCredentialModalProps) => {
  const { hide, remove, visible } = useModal();
  const { purposeId } = props;

  const { data, isError, isFetching, isSuccess, refetch } =
    useFullFeedCredential(purposeId);

  const handleCopyToClipboard = (): void => {
    navigator.clipboard.writeText(data || '');
  };

  return (
    <StyledModal
      muiTransitionProps={{ onExited: remove }}
      onClose={hide}
      open={visible}
      title='Feed Credential'
      width='50%'
    >
      {isError && (
        <StatusUI
          action={
            <Button onClick={() => refetch()} color='error' variant='contained'>
              Try again
            </Button>
          }
          status={StatusEnum.ERROR}
          subtitle='Could not retrieve credential'
        />
      )}

      {isFetching && <StatusUI splashPage status={StatusEnum.LOADING} />}

      {isSuccess && (
        <Stack spacing={1}>
          <Alert severity='warning' sx={{ mb: 2 }}>
            To maintain the security of this feed, we kindly ask you not to
            share this credential with anyone. Any individual requiring access
            to the full credential should be setup as a user on the Client
            Integration Hub so they may retrieve this themselves.
            <br />
            <br />
            When referencing a specific feed on a help desk ticket, please only
            ever use the last 5 digits of the credential.
          </Alert>

          <Paper
            elevation={0}
            sx={{
              alignItems: 'center',
              border: 1,
              borderColor: (theme) => theme.palette.grey[300],
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            <Typography>{data}</Typography>
            <Button onClick={handleCopyToClipboard} variant='contained'>
              Copy to clipboard
            </Button>
          </Paper>
        </Stack>
      )}
    </StyledModal>
  );
});
