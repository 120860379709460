export const CONNECTION_COOKIE_NAME = 'ConnectionNameCookie';
export const TIMEOUT_COOKIE_NAME = 'SessionTimeoutCookie';

const SESSION_EXPIRY_MINS = 60 as const;
const CONNECTION_EXPIRY_DAYS = 2 as const;

export const getCookieByName = (name: string): string | null => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === name) {
      return cookie[1];
    }
  }
  return null;
};

export const isSessionCookieExpired = (cookie: string | null) => {
  if (!cookie) return true;
  const isExpirationBeforeNow = parseInt(cookie) < new Date().getTime();

  if (cookie.length <= 0 || isExpirationBeforeNow) return true;
  return false;
};

// Refreshes/Removes session inactivity cookie (defaults to refresh)
export const updateTimeoutCookie = (type?: 'refresh' | 'remove'): void => {
  type = type || 'refresh';

  const now = new Date();
  const timeInMilliseconds = now.getTime() + SESSION_EXPIRY_MINS * 60 * 1000;

  const value = String(timeInMilliseconds);
  const expirationDate = new Date(timeInMilliseconds).toUTCString();

  document.cookie = `${TIMEOUT_COOKIE_NAME}=${
    type === 'remove' ? '' : value
  }; expires=${expirationDate}; Secure; path=/`;
};

// Sets/Updates connection cookie
export const setConnectionCookie = (connection: string) => {
  const expirationDate = new Date(
    new Date().getTime() + CONNECTION_EXPIRY_DAYS * 24 * 60 * 1000
  ).toUTCString();

  document.cookie = `${CONNECTION_COOKIE_NAME}=${connection}; expires=${expirationDate}; Secure; path=/`;
};
