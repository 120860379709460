import * as yup from 'yup';
import { Field, FieldArray, Formik, Form, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Placeholder } from '../connectTemplates.types';
import PlaceholderFieldArray from './PlaceholderFieldArray';
import ConnectTemplateEnabledSwitch from './ConnectTemplateEnabledSwitch';
import useConnectTemplateCategories from '../api/useConnectTemplateCategories';
import { StatusEnum, StatusUI } from '@brandbank/portal-components';
import { Button } from '@mui/material';

export type FormValues = {
  html: string;
  name: string;
  url: string;
  enabled: boolean;
  placeholders?: Placeholder[];
};

type ConnectTemplateFormProps = {
  id: string;
  initialValues?: FormValues;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  hasLinkedAssets?: boolean;
  showUrl?: boolean;
};

export const CONNECT_TEMPLATE_VALIDATIONS = {
  characterValidation: {
    regex: new RegExp('^[a-zA-Z0-9_ ]*$'),
  },
  nonNumeric: {
    regex: new RegExp('[^0-9]'),
  },
};

const formSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
  url: yup.string(),
  enabled: yup.boolean(),
  html: yup.string().required('Please enter some HTML'),
  placeholders: yup.array().of(
    yup.object({
      name: yup
        .string()
        .matches(
          CONNECT_TEMPLATE_VALIDATIONS.characterValidation.regex,
          'Name must only contain alphanumeric, space or underscore characters'
        )
        .matches(
          CONNECT_TEMPLATE_VALIDATIONS.nonNumeric.regex,
          'Name cannot exclusively contain numbers'
        )
        .required('Please enter a placeholder name'),
      typeId: yup.string().required('Please select an option'),
      styleId: yup.string().required('Please select an option'),
      imageProfile: yup
        .string()
        .matches(
          /^(?=.*{[0-9]+}+\?)(?=.*&)(?=.*Height=[0-9])(?=.*Width=[0-9])(?=.*OutputFormat=[A-Za-z0-9_@./#&+-]).*$/,
          `Incorrect format`
        )
        .nullable(),
      optional: yup.bool(),
    })
  ),
});
const DEFAULT_INITIAL_VALUES = {
  html: '',
  name: '',
  url: '',
  enabled: false,
  placeholders: undefined,
};

const ConnectTemplateForm = (props: ConnectTemplateFormProps): JSX.Element => {
  const { id, initialValues, onSubmit, hasLinkedAssets, showUrl } = props;
  const { data: categories, refetch, status } = useConnectTemplateCategories();

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ): void => {
    const { placeholders } = values;

    if (placeholders && placeholders.length > 0) {
      let hasDuplicates = false;

      placeholders.forEach((placeholder, index, array) => {
        const firstInstanceIndex = array.findIndex(
          (p) => p.name === placeholder.name
        );

        const errorMessage = 'Placeholder names must be unique';

        if (firstInstanceIndex !== index) {
          hasDuplicates = true;
          helpers.setFieldError(
            `placeholders.${firstInstanceIndex}.name`,
            errorMessage
          );
          helpers.setFieldError(`placeholders.${index}.name`, errorMessage);
        }
      });

      if (hasDuplicates) {
        helpers.setSubmitting(false);
        return;
      }
    }

    onSubmit(values, helpers);
  };

  return (
    <>
      {status === 'error' && (
        <StatusUI
          action={
            <Button color='error' onClick={() => refetch()} variant='contained'>
              Try again
            </Button>
          }
          status={StatusEnum.ERROR}
        />
      )}

      {status === 'loading' && (
        <StatusUI
          status={StatusEnum.LOADING}
          title='Loading template categories...'
          splashPage
        />
      )}

      {status === 'success' && (
        <Formik
          initialValues={initialValues || DEFAULT_INITIAL_VALUES}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          <Form id={id}>
            <Stack spacing={2}>
              <Field component={TextField} fullWidth label='Name' name='name' />

              {showUrl && (
                <Field
                  component={TextField}
                  fullWidth
                  label='Url'
                  name='url'
                  disabled={true}
                />
              )}

              <Field
                component={TextField}
                label='HTML'
                name='html'
                multiline
                minRows={10}
                maxRows={20}
              />

              <ConnectTemplateEnabledSwitch hasLinkedAssets={hasLinkedAssets} />

              {initialValues && initialValues.placeholders && categories && (
                <>
                  <Typography variant='h6'>Placeholders</Typography>

                  <FieldArray
                    render={(props) => (
                      <PlaceholderFieldArray
                        styles={categories.styles}
                        types={categories.types}
                        {...props}
                      />
                    )}
                    name='placeholders'
                  />
                </>
              )}
            </Stack>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ConnectTemplateForm;
