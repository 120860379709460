import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useModal as useControls, Avatar } from '@brandbank/portal-components';
import { show } from '@ebay/nice-modal-react';
import useUser from 'hooks/useUser';
import { UserFeedbackModal } from 'features/user-feedback';

const UserMenu = (): JSX.Element => {
  const { logout, user } = useUser();

  const ref = React.useRef(null);

  const menuControls = useControls();

  const avatarName = (name: string): string =>
    `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

  const handleOpenFeedbackModal = (): void => {
    show(UserFeedbackModal);
    menuControls.close();
  };

  const handleLogoutClick = (): void => {
    localStorage.removeItem('userPermission');
    logout();
  };

  return (
    <>
      {user && (
        <>
          <IconButton
            data-testid='menu-button'
            onClick={menuControls.open}
            ref={ref}
            sx={{ ml: 'auto', p: 0 }}
          >
            <Avatar
              sx={{
                bgcolor: 'primary.main',
                display: 'flex',
                height: 48,
                width: 48,
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              {avatarName(user.name)}
            </Avatar>
          </IconButton>

          <Menu
            anchorEl={ref.current}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            data-testid='user-menu'
            onClose={menuControls.close}
            open={menuControls.isOpen}
            sx={{
              '& .MuiPaper-root': {
                border: 1,
                borderColor: (theme) => theme.palette.grey[200],
                boxShadow: 3,
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <Box sx={{ px: 2, py: 1 }}>
              <Typography fontWeight='bold'>{user.name}</Typography>
              <Typography sx={{ color: (theme) => theme.palette.grey[500] }}>
                {user.email}
              </Typography>
            </Box>

            <Divider />

            <MenuItem
              component={Link}
              href='/terms-and-conditions.pdf'
              target='_blank'
              dense
            >
              Terms &amp; conditions
            </MenuItem>
            <MenuItem
              component={Link}
              href='/cookie-policy.pdf'
              target='_blank'
              dense
            >
              Cookie policy
            </MenuItem>

            <MenuItem
              component={Link}
              href='https://www.brandbank.com/privacynotice'
              target='_blank'
              dense
            >
              Privacy policy
            </MenuItem>

            <MenuItem dense onClick={handleOpenFeedbackModal}>
              Give feedback
            </MenuItem>

            <Divider />

            <MenuItem
              dense
              onClick={handleLogoutClick}
              sx={{
                transition: '0.2s color ease-in-out',

                '&:hover': {
                  color: 'error.main',
                },
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default UserMenu;
