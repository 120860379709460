import { Chip, ChipProps, Tooltip, Typography } from '@mui/material';

interface TargetMarketChipProps extends ChipProps {
  tooltip?: string;
}

const TargetMarketChip = ({
  tooltip,
  label,
  ...props
}: TargetMarketChipProps) => (
  <Tooltip title={tooltip} enterDelay={1000} enterNextDelay={1000}>
    <Chip
      variant='outlined'
      color='primary'
      label={
        <Typography fontWeight={600} fontSize='1rem'>
          {label}
        </Typography>
      }
      sx={{
        p: '0.4125rem',
        height: '2.25rem',
      }}
      {...props}
    />
  </Tooltip>
);

export default TargetMarketChip;
