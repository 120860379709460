import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Modal } from '@brandbank/portal-components';
import { Formik, Form } from 'formik';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import CookieSwitches from './CookieSwitches';
import CookieTable from './CookieTable';

type CookiePrivacyModalProps = {
  onSaveAndAccept: (values: {
    necessary: boolean;
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
  }) => void;
};

const INITIAL_VALUES = {
  necessary: true,
  preferences: false,
  statistics: false,
  marketing: false,
};

export default NiceModal.create<CookiePrivacyModalProps>(
  ({ onSaveAndAccept }) => {
    const { hide, remove, visible } = useModal();

    const handleSubmit = (values: typeof INITIAL_VALUES): void => {
      onSaveAndAccept(values);
      hide();
    };

    return (
      <Modal
        action={
          <>
            <Button onClick={hide} variant='outlined'>
              Cancel
            </Button>
            <Button form='privacy-form' type='submit' variant='contained'>
              Save &amp; accept
            </Button>
          </>
        }
        muiTransitionProps={{ onExited: remove }}
        onClose={hide}
        open={visible}
        title='Privacy Overview'
      >
        <Typography sx={{ mb: 2 }}>
          This website uses cookies to improve your experience while you
          navigate through the website. Out of these, the cookies that are
          categorized as necessary are stored on your browser as they are
          essential for the working of basic functionalities of the website. We
          also use third-party cookies that help us analyze and understand how
          you use this website. These cookies will be stored in your browser
          only with your consent. You also have the option to opt-out of these
          cookies. But opting out of some of these cookies may affect your
          browsing experience.
        </Typography>

        <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
          <Form id='privacy-form'>
            <CookieSwitches disabled name='necessary' title='Necessary'>
              <Typography>
                Necessary cookies are absolutely essential for the website to
                function properly. These cookies ensure basic functionalities
                and security features of the website, anonymously.
              </Typography>

              <CookieTable
                items={[
                  {
                    cookie: 'USE_COOKIE_CONSENT_STATE',
                    duration: '1 year',
                    description:
                      'This cookie is used to store the user consent for cookies across various categories',
                  },
                ]}
              />
            </CookieSwitches>

            <CookieSwitches
              name='preferences'
              title='Preferences (functionality cookies)'
            >
              <Typography>
                These cookies allow a website to remember choices you have made
                in the past, like what language you prefer, what region you
                would like weather reports for, or what your user name and
                password are so you can automatically log in.
              </Typography>

              <CookieTable
                items={[
                  {
                    cookie: '_legacy_auth0',
                    duration: '1 day',
                    description:
                      'This cookie is set by auth0 and is used to sign you in automatically',
                  },
                  {
                    cookie: 'auth0',
                    duration: '1 day',
                    description:
                      'This cookie is set by auth0 and is used to sign you in automatically',
                  },
                ]}
              />
            </CookieSwitches>

            <CookieSwitches
              name='statistics'
              title='Statistics (performance cookies)'
            >
              <Typography>
                These cookies collect information about how you use the site,
                like which pages you visited and which links you clicked on.
                None of this information can be used to identify you. It is all
                aggregated and, therefore, anonymized. Their sole purpose is to
                improve website functions.
              </Typography>

              <CookieTable
                items={[
                  {
                    cookie: '_ga',
                    duration: '2 years',
                    description:
                      'A Google Analytics cookie used to distinguish users',
                  },
                  {
                    cookie: '_ga_DKPX2LM37E',
                    duration: '2 years',
                    description:
                      'A Google Analytics cookie used to persist session state',
                  },
                ]}
              />
            </CookieSwitches>

            <CookieSwitches name='marketing' title='Marketing Cookies'>
              <Typography>
                These cookies track your online activity to help advertisers
                deliver more relevant advertising or to limit how many times you
                see an ad. These cookies can share that information with other
                organizations or advertisers.
              </Typography>
            </CookieSwitches>
          </Form>
        </Formik>
      </Modal>
    );
  }
);
