import axios from 'config/axios';
import { AxiosError } from 'axios';
import { ProductSearchPayload } from '../productSearch.types';

export default async function getProducts(
  gtins: string[],
  purposeId: number | null
) {
  try {
    const response = await axios.post('GetProductMetaData', {
      productIdentifiers: gtins,
      purposeId,
    });

    if (response.status !== 200) {
      return Promise.reject(
        new Error('An error occurred searching for products')
      );
    }

    return {
      data: response.data as ProductSearchPayload,
      status: 'SUCCESS' as const,
    };
  } catch (err) {
    const error = err as AxiosError;

    if (error.code === '400' && error.response) {
      return {
        errors: error.response.data,
        status: 'FAIL' as const,
      };
    }

    return Promise.reject(error);
  }
}
