import { Field, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type ConnectTemplateEnabledSwitchProps = {
  hasLinkedAssets?: boolean;
};

const ConnectTemplateEnabledSwitch = (
  props: ConnectTemplateEnabledSwitchProps
): JSX.Element => {
  const { hasLinkedAssets } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <Stack
      alignItems='center'
      component={InputLabel}
      direction='row'
      spacing={2}
      sx={{
        border: 1,
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: 1,
        cursor: 'pointer',
        mx: 2,
        p: 1,
        width: 'fit-content',
      }}
    >
      <Box>
        <Typography variant='h6'>Enabled?</Typography>
        <Typography variant='subtitle2'>
          Disabled templates will not be sent to customers
        </Typography>
      </Box>

      <Field
        component={Switch}
        disabled={hasLinkedAssets || isSubmitting}
        type='checkbox'
        name='enabled'
      />

      {hasLinkedAssets && (
        <Alert severity='error'>
          To disable this template please remove linked products
        </Alert>
      )}
    </Stack>
  );
};

export default ConnectTemplateEnabledSwitch;
