import { Stack, Typography } from '@mui/material';

type CardRowProps = {
  label: string;
  value: number | string | null;
};

const CardRow = ({ label, value }: CardRowProps) => (
  <Stack direction='row' justifyContent='space-between'>
    <Typography>{label}</Typography>
    <Typography noWrap sx={{ color: (theme) => theme.palette.grey[600] }}>
      {value || '-'}
    </Typography>
  </Stack>
);

export default CardRow;
