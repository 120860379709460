import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axios from 'config/axios';
import { useSnackbar } from 'notistack';
import { UpdateConnectMaxPayload } from '../connect-max.types';

type Response = {
  message?: string;
};

async function updateConnectMaxConfig(connectMax: UpdateConnectMaxPayload) {
  const response = await axios.put<Response>(
    '/UpdateRetailerConfiguration',
    connectMax
  );
  return response.data;
}

const useUpdateConnectMaxConfig = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const mutation = useMutation<
    Response,
    AxiosError,
    UpdateConnectMaxPayload,
    unknown
  >(updateConnectMaxConfig, {
    onError: (err) => {
      console.error(err);
      enqueueSnackbar('Unable to save Connect Max layout', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully updated Connect Max layout', {
        variant: 'success',
      });
      queryClient.invalidateQueries(['connectMaxConfig']);
    },
  });

  return mutation;
};

export default useUpdateConnectMaxConfig;
