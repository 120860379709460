import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface ProductResendAlertProps {
  children: ReactNode;
  status: 'error' | 'warning' | 'success';
  title: string;
}

const ProductResendAlert = ({
  children,
  status,
  title,
}: ProductResendAlertProps) => {
  const Icon = status === 'success' ? CheckCircleRoundedIcon : InfoIcon;

  return (
    <Stack alignItems='center' sx={{ textAlign: 'center' }}>
      <Icon color={status} sx={{ height: 50, width: 50 }} />
      <Typography sx={{ my: 1 }} variant='h5'>
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

export default ProductResendAlert;
