import { ReactNode } from 'react';
import { styled } from '@mui/material';
import useUser from 'hooks/useUser';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as Svg } from 'assets/svg/auth.svg';
import { AuthRoles } from '@brandbank/portal-components';

const AuthSvg = styled(Svg)({
  height: '100%',
  maxWidth: 500,
  width: '90%',
});

type RoleGuardProps = {
  children: ReactNode;
  roles: AuthRoles[];
};

export const RoleGuard = (props: RoleGuardProps): JSX.Element => {
  const { hasRole } = useUser();
  const { children, roles } = props;

  const canViewRoles = roles.every((role) => hasRole(role));

  return (
    <>
      {canViewRoles ? (
        children
      ) : (
        <Stack
          alignItems='center'
          spacing={2}
          sx={{ mt: 5, textAlign: 'center' }}
        >
          <AuthSvg />
          <Typography variant='h4'>Not Authorised</Typography>
          <Typography variant='subtitle1'>
            You do not have permission to view this page
          </Typography>
        </Stack>
      )}
    </>
  );
};

export const withRoleGuard = (node: ReactNode, roles: AuthRoles[]) => (
  <RoleGuard roles={roles}>{node}</RoleGuard>
);
