import axios from 'config/axios';
import { AxiosError } from 'axios';
import { PlaceholderCategory } from '../connectTemplates.types';
import { useQuery } from '@tanstack/react-query';
import connectTemplateTags from './connectTemplate.tags';

type Response = {
  connectTemplatesCategoriesStyles: PlaceholderCategory[];
  connectTemplatesCategoriesTypes: PlaceholderCategory[];
};

async function getCategories() {
  const response = await axios.get<Response>('GetConnectTemplateCategories', {
    params: { languageId: 1 },
  });

  return {
    styles: response.data.connectTemplatesCategoriesStyles,
    types: response.data.connectTemplatesCategoriesTypes,
  };
}

const useConnectTemplateCategories = () =>
  useQuery<
    { styles: PlaceholderCategory[]; types: PlaceholderCategory[] },
    AxiosError
  >([connectTemplateTags.connectTemplateCategories], getCategories);

export default useConnectTemplateCategories;
