import { Icon } from '@brandbank/portal-components';
import NiceModal from '@ebay/nice-modal-react';
import { Button } from '@mui/material';
import { ConnectMaxInfoModal } from '.';

const ConnectMaxInfoButton = () => {
  const showInfoModal = () =>
    NiceModal.show(ConnectMaxInfoModal, { hideCheckbox: true });

  return (
    <Button
      variant='outlined'
      endIcon={<Icon iconName='info' />}
      onClick={showInfoModal}
      sx={{ height: 'min-content' }}
    >
      Connect Max Info
    </Button>
  );
};

export default ConnectMaxInfoButton;
