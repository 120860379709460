import { ErrorScreen, ErrorScreenVariants } from '@brandbank/portal-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AxiosError } from 'axios';
import {
  FavouriteFeedButton,
  useFavouriteFeedActions,
} from 'features/favourite-feeds';
import { paths } from 'features/routing';
import { Link } from 'react-router-dom';

type FeedDetailsLayoutErrorProps = {
  error: AxiosError | null;
  purposeId: number;
  purposeName: string;
};

const FeedDetailsLayoutError = ({
  error,
  purposeId,
  purposeName,
}: FeedDetailsLayoutErrorProps) => {
  const notAuthorised = error && error.response?.status === 401;
  const { isFavourited } = useFavouriteFeedActions(purposeId, purposeName);

  return (
    <ErrorScreen
      variant={ErrorScreenVariants.ERROR}
      title='Something went wrong.'
      subtitle={
        error?.message || "We've encountered an error. Please try again later."
      }
      action={
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            justifyContent: ['center', 'center', 'left'],
          }}
        >
          <Button component={Link} to={paths.feeds} variant='contained'>
            Back to feeds
          </Button>

          {notAuthorised && isFavourited && (
            <FavouriteFeedButton
              purposeId={purposeId}
              purposeName={purposeName}
            />
          )}
        </Box>
      }
    />
  );
};

export default FeedDetailsLayoutError;
