import { ErrorScreen, ErrorScreenVariants } from '@brandbank/portal-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { paths } from 'features/routing';
import withErrorBoundary from 'hocs/withErrorBoundary';
import { Link } from 'react-router-dom';

const PageNotFound = (): JSX.Element => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
    }}
  >
    <ErrorScreen
      variant={ErrorScreenVariants.NOT_FOUND}
      action={
        <Button component={Link} to={paths.dashboard} variant='contained'>
          Back to home
        </Button>
      }
    />
  </Box>
);

export default withErrorBoundary(PageNotFound);
