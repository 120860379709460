import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { useCompanyDetailsCtx } from 'features/companies';
import { CardRow } from 'features/ui';

const CompaniesOverviewGeneralInfo = () => {
  const { active, name, id, companyTypeDescription } = useCompanyDetailsCtx();

  return (
    <Card>
      <CardHeader
        title='General Info'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        <Stack spacing={1}>
          <CardRow label='Name:' value={name} />
          <CardRow label='Salesforce ID: ' value={id} />
          <CardRow label='Company Type: ' value={companyTypeDescription} />
          <Stack direction='row' justifyContent='space-between'>
            <Typography>Active? </Typography>
            <Chip
              label={active ? 'Active' : 'Inactive'}
              variant='filled'
              color={active ? 'success' : 'error'}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CompaniesOverviewGeneralInfo;
