import { show } from '@ebay/nice-modal-react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';
import { CardRow } from 'features/ui';
import useUser from 'hooks/useUser';
import { useFeedDetailsCtx } from '../FeedDetails.context';
import FullFeedCredentialModal from './FullFeedCredentialModal';

const FeedsOverviewCompanyCredentials = () => {
  const { detail } = useFeedDetailsCtx();
  const { hasRole } = useUser();

  const subcode = detail.subscriberCode || '-';
  const hasPermission = hasRole('View Full Feed Credential', subcode);
  const showModalButton = hasPermission && !!detail.credential;

  const showModal = () => {
    if (detail.id !== null) {
      show(FullFeedCredentialModal, { purposeId: detail.id });
    }
  };

  return (
    <Card>
      <CardHeader
        title='Company &amp; Credentials'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        <Stack spacing={1}>
          <CardRow label='Company Name:' value={detail.companyName} />
          <CardRow label='Subscriber Name:' value={detail.subscriberName} />
          <CardRow label='Subscriber Code:' value={detail.subscriberCode} />
          <CardRow label='Handler:' value={detail.handler} />
          <CardRow label='Credential:' value={detail.credential} />
          {showModalButton && (
            <Box sx={{ textAlign: 'right' }}>
              <Button
                onClick={showModal}
                size='small'
                sx={{ ml: 'auto' }}
                variant='contained'
              >
                Get Full credential
              </Button>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FeedsOverviewCompanyCredentials;
