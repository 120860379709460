import { useEffect } from 'react';
import useUser from 'hooks/useUser';

interface CustomWindow extends Window {
  [key: string]: any;
}

type MicroFrontendProps = {
  host: string | undefined;
  name: string;
};

declare let window: CustomWindow;

const MicroFrontend = (props: MicroFrontendProps): JSX.Element => {
  let isMounted = false;

  const user = useUser();

  const { host, name } = props;
  const { token } = user;

  useEffect(() => {
    // eslint-disable-next-line
    isMounted = true;

    const scriptID = `micro-frontend-script-${name}`;

    const renderMicroFrontend = (): void => {
      window[`render${name}`](`${name}-container`, user);
    };

    if (document.getElementById(scriptID)) {
      renderMicroFrontend();
      return undefined;
    }

    fetch(`${host}/asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        if (isMounted) {
          const script = document.createElement('script');
          script.id = scriptID;
          script.crossOrigin = '';
          script.src = `${host}${manifest.files['main.js']}`;
          script.onload = () => {
            renderMicroFrontend();
          };
          document.head.appendChild(script);
        }
      });

    return () => {
      isMounted = false;
      if (window[`unmount${name}`]) {
        window[`unmount${name}`](`${name}-container`);
      }
    };
  }, [token, name, host, user]);

  return <main id={`${name}-container`} />;
};

export default MicroFrontend;
