import { Icon } from '@brandbank/portal-components';
import { InputAdornment, Tooltip } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { ChangeEvent } from 'react';

type Props = {
  handleChange: (e: ChangeEvent<any>) => void;
  showInfoIcon: boolean;
};

const MappingValueField = ({ handleChange, showInfoIcon }: Props) => (
  <Field
    component={TextField}
    fullWidth
    label='Mapping Value'
    name='mapping'
    autoComplete='off'
    onChange={handleChange}
    InputProps={{
      endAdornment: showInfoIcon ? (
        <Tooltip
          title="This value should match the placeholder
      names used in the company's Connect 1 templates if this company is configured for Connect 1."
          sx={{ zIndex: 999999999 }}
        >
          <InputAdornment position='end' sx={{ height: '100%' }}>
            <Icon iconName='info' />
          </InputAdornment>
        </Tooltip>
      ) : (
        <></>
      ),
    }}
  />
);

export default MappingValueField;
