import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FeedSummary } from '../feeds.types';
import { useNavigate } from 'react-router-dom';
import {
  booleanToString,
  DataTable,
  DataTableNoItemsOptions,
  formatDate,
  Icon,
} from '@brandbank/portal-components';
import { NoItems } from 'features/ui';
import { FavouriteFeedIconButton } from 'features/favourite-feeds';

type FeedsTableProps = {
  isLoading?: boolean;
  items: FeedSummary[];
  onRefresh: () => void;
};

const FeedsTable = (props: FeedsTableProps): JSX.Element => {
  const { isLoading, items, onRefresh } = props;

  const navigate = useNavigate();

  const handleRowClick = (row: FeedSummary): void => {
    navigate(`/feeds/${row.id}`);
  };

  const renderSubtypeCell = (item: FeedSummary): JSX.Element => {
    const subtype = item.purposeSubTypeDescription;

    const colorMap = {
      DEV: 'info',
      Live: 'success',
      UAT: 'warning',
    };

    return (
      <Box
        sx={{
          bgcolor: `${colorMap[subtype]}.light`,
          color: 'common.white',
          borderRadius: 1,
          m: 'auto',
          p: 0.5,
          width: 50,
        }}
      >
        <Typography fontWeight={600}>{subtype.toUpperCase()}</Typography>
      </Box>
    );
  };

  const renderToolbarActions = (): JSX.Element => {
    return (
      <>
        {isLoading ? (
          <Skeleton height={30} sx={{ ml: 1 }} variant='circular' width={30} />
        ) : (
          <IconButton onClick={onRefresh} sx={{ ml: 1 }}>
            <Icon iconName='refresh' />
          </IconButton>
        )}
      </>
    );
  };

  const renderNoItems = (options: DataTableNoItemsOptions): JSX.Element => {
    const { hasAppliedFilters, hasSearchValue } = options;

    return (
      <>
        {hasAppliedFilters || hasSearchValue ? (
          <Typography textAlign='center'>
            No feeds found matching given filters
          </Typography>
        ) : (
          <NoItems
            actions={
              <Button color='warning' onClick={onRefresh} variant='contained'>
                Refresh
              </Button>
            }
            sx={{ minHeight: '50vh' }}
            title='No feeds found'
            message={`We couldn't find any feeds for your account.`}
          />
        )}
      </>
    );
  };

  return (
    <DataTable
      columns={[
        { id: 'purposeDescription', label: 'Feed Purpose' },
        {
          align: 'center',
          id: 'purposeSubTypeDescription',
          label: 'Subtype',
          render: renderSubtypeCell,
        },
        {
          align: 'center',
          id: 'credentials',
          label: 'Credentials',
        },
        {
          align: 'center',
          id: 'lastReceivedCoverage',
          label: 'Last Received Coverage',
          render: (item) => formatDate(item.lastReceivedCoverage),
          sortable: 'date',
        },
        {
          align: 'center',
          id: 'importCoverage',
          label: 'Import Coverage?',
          render: (item) => booleanToString(item.importCoverage),
        },
        {
          align: 'center',
          id: 'importFeedback',
          label: 'Import Feedback?',
          render: (item) => booleanToString(item.importFeedback),
        },
        {
          align: 'center',
          id: 'purposeDescription',
          label: 'Actions',
          render: (item) => (
            <FavouriteFeedIconButton
              purposeId={item.id}
              purposeName={item.purposeDescription}
            />
          ),
        },
      ]}
      filterBy={[
        {
          id: 'purposeSubTypeDescription',
          label: 'Subtype',
          options: [
            { label: 'Dev', value: 'DEV' },
            { label: 'Uat', value: 'UAT' },
            { label: 'Live', value: 'Live' },
          ],
        },
      ]}
      isLoading={isLoading}
      items={items}
      onRowClick={handleRowClick}
      renderNoItems={renderNoItems}
      renderToolbarActions={renderToolbarActions}
      searchBy={['purposeDescription', 'credentials', 'id']}
    />
  );
};

export default FeedsTable;
