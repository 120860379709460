import { SimpleTable } from '@brandbank/portal-components';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useFeedDetailsCtx } from '../FeedDetails.context';
import { FeedDetailMappedImage } from '../feeds.types';

const FeedsOverviewImages = () => {
  const { imageMeta } = useFeedDetailsCtx();

  const images = useMemo(() => {
    if (!imageMeta || imageMeta.length === 0) return [];

    const items: FeedDetailMappedImage[] = [];

    imageMeta.forEach((obj) => {
      const existingItemIndex = items.findIndex(
        (item) => item.shotType === obj.shotType
      );

      if (existingItemIndex > -1) {
        items[existingItemIndex].count += 1;
      } else {
        items.push({
          ...obj,
          count: 1,
        });
      }
    });

    return items;
  }, [imageMeta]);

  return (
    <Card>
      <CardHeader
        title='Images'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        {images.length === 0 ? (
          <Typography>No images found</Typography>
        ) : (
          <SimpleTable
            columns={[
              { id: 'shotType', label: 'Shot Type' },
              {
                id: 'height',
                label: 'Dimensions',
                render: (item) => `${item.height} x ${item.width}`,
              },
              { id: 'count', label: 'Number of images' },
            ]}
            items={images}
            sx={{ borderRadius: 1, maxHeight: 250 }}
            tableProps={{
              size: 'small',
              stickyHeader: true,
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default FeedsOverviewImages;
