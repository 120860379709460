import {
  DragDropList,
  generateUniqueID,
  Icon,
} from '@brandbank/portal-components';
import NiceModal from '@ebay/nice-modal-react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AddConnectMaxAssetModal,
  ConnectMaxListItem,
  EditConnectMaxAssetModal,
} from '.';
import {
  assetTypeMap,
  CONNECT_MAX_POSITION_TOOLTIP,
} from '../connect-max.constants';
import { getGridTemplateColumns } from '../connect-max.helpers';
import { ConnectMaxAction } from '../connect-max.reducer';
import {
  ConnectMaxAssetItem,
  ConnectMaxAssetType,
  ConnectMaxMappingItem,
} from '../connect-max.types';

type ConnectMaxAssetListProps = {
  assets: ConnectMaxAssetItem[];
  positions: ConnectMaxMappingItem[];
  assetType: ConnectMaxAssetType;
  index: number;
  dispatch: React.Dispatch<ConnectMaxAction>;
  handleDragEnd: (newPositions: ConnectMaxMappingItem[], index: number) => void;
};

const ConnectMaxAssetList = ({
  assets,
  positions,
  assetType,
  index: assetListIndex,
  dispatch,
  handleDragEnd,
}: ConnectMaxAssetListProps) => {
  const userFacingType = assetTypeMap[assetType];

  const showEditModal = async (item: ConnectMaxMappingItem) => {
    const newValue: ConnectMaxMappingItem = await NiceModal.show(
      EditConnectMaxAssetModal,
      { assetType, item, assets }
    );

    dispatch({
      type: 'edited',
      assetType,
      index: item.position - 1,
      item: newValue,
    });
  };

  const showAddNewModal = async () => {
    const newValue:
      | (Omit<ConnectMaxMappingItem, 'position'> & {
          assetType: ConnectMaxAssetType;
        })
      | null = await NiceModal.show(AddConnectMaxAssetModal, {
      assetType,
      assets,
    });

    if (newValue) {
      const { mapping, mappingType } = newValue;

      dispatch({
        type: 'added',
        assetType,
        item: {
          mapping: mapping,
          mappingType: mappingType,
        },
      });
    }
  };

  const dragDisabled = positions.length < 2;

  return (
    <Card sx={{ padding: '0 0.5rem' }}>
      <CardHeader
        title={userFacingType}
        action={
          <Button variant='outlined' onClick={showAddNewModal}>
            Add New {userFacingType} +
          </Button>
        }
      />

      <CardContent sx={{ paddingBottom: '16px' }}>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: getGridTemplateColumns(dragDisabled),
            columnGap: '1rem',
            alignItems: 'center',
            paddingBottom: '0.75rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.75,
            }}
          >
            <Typography fontWeight={600}>Position</Typography>
            <Tooltip title={CONNECT_MAX_POSITION_TOOLTIP} placement='top'>
              <div style={{ display: 'flex' }}>
                <Icon
                  iconName='info'
                  fontSize='small'
                  sx={{
                    color: 'grey.800',
                    zIndex: (theme) => theme.zIndex.modal - 1,
                  }}
                />
              </div>
            </Tooltip>
          </Box>

          <Typography fontWeight={600}>Mapping Type</Typography>
          <Typography fontWeight={600}>Mapping</Typography>
          <Typography textAlign='center' fontWeight={600}>
            Actions
          </Typography>
          <div />
        </Box>

        <DragDropList
          draggableId={(item) => item.position.toString()}
          droppableId={`connect-max-asset-list-${generateUniqueID()}`}
          items={positions}
          onChange={(newPositions) =>
            handleDragEnd(newPositions, assetListIndex)
          }
          disabled={dragDisabled}
          renderItem={(item, provided) => (
            <ListItem
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              disableGutters
              disablePadding
              ref={provided.innerRef}
            >
              <ConnectMaxListItem
                item={item}
                handleEditRow={() => showEditModal(item)}
                dragDisabled={dragDisabled}
                handleDeleteRow={() =>
                  dispatch({
                    type: 'deleted',
                    assetType,
                    index: item.position - 1,
                  })
                }
              />
            </ListItem>
          )}
        />
      </CardContent>
    </Card>
  );
};

export default ConnectMaxAssetList;
