import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, To } from 'react-router-dom';
import { SxProps } from '@mui/material';

type TabItem = {
  label: string;
  show?: boolean;
  to: To;
  value?: string;
};

interface RoutingTabsProps {
  children?: ReactNode;
  sx?: SxProps;
  tabs: TabItem[];
  value: string;
}

const RoutingTabs = (props: RoutingTabsProps) => {
  const { children, sx, tabs, value } = props;

  const tabsToShow = tabs.filter(({ show }) => show === undefined || show);

  return (
    <Stack
      alignItems='center'
      direction='row'
      justifyContent='space-between'
      sx={{ pt: 3, pl: 1, pr: 3, ...sx }}
    >
      <Tabs value={value}>
        {tabsToShow.map(({ label, to, value }) => {
          const formattedValue =
            value || label.toLowerCase().replace(/[ ]/gm, '-');

          return (
            <Tab
              component={Link}
              disableRipple
              key={label}
              label={label}
              to={to}
              value={formattedValue}
            />
          );
        })}
      </Tabs>

      <Stack alignItems='center' direction='row' spacing={1}>
        {children}
      </Stack>
    </Stack>
  );
};

export default RoutingTabs;
