import { Icon } from '@brandbank/portal-components';
import {
  Box,
  Button,
  ButtonTypeMap,
  ExtendButtonBase,
  SxProps,
} from '@mui/material';

type PositionMap = {
  bottomRight: SxProps;
  bottomLeft: SxProps;
};

type FloatingActionButtonWrapperProps = {
  children: React.ReactNode;
  position?: keyof PositionMap;
};

type FormActionButtonsProps = {
  formId?: string;
  position?: keyof PositionMap;
  disabled?: boolean;
  loading?: boolean;
  primaryButtonAction?: React.MouseEventHandler<HTMLButtonElement>;
  primaryButtonText?: string;
  primaryButtonProps?: ExtendButtonBase<
    ButtonTypeMap<Record<string, never>, 'button'>
  >;
  secondaryButtonAction?: React.MouseEventHandler<HTMLButtonElement>;
  secondaryButtonProps?: ExtendButtonBase<
    ButtonTypeMap<Record<string, never>, 'button'>
  >;
};

const positionMap: PositionMap = {
  bottomRight: {
    top: 'auto',
    bottom: 20,
    right: 20,
    left: 'auto',
    justifyContent: 'end',
  },
  bottomLeft: {
    top: 'auto',
    bottom: 20,
    left: 300,
    right: 'auto',
    justifyContent: 'start',
  },
};

export const FloatingActionButtonWrapper = ({
  children,
  position = 'bottomRight',
}: FloatingActionButtonWrapperProps) => (
  <Box
    sx={{
      display: 'flex',
      gap: '0.5rem',
      padding: '8px',
      margin: 0,
      position: 'fixed',
      zIndex: '1000',
      ...positionMap[position],
    }}
  >
    {children}
  </Box>
);

const FormActionButtons = ({
  formId,
  position,
  disabled,
  loading,
  primaryButtonAction,
  primaryButtonText,
  primaryButtonProps,
  secondaryButtonAction,
  secondaryButtonProps,
}: FormActionButtonsProps) => {
  return (
    <FloatingActionButtonWrapper position={position}>
      {secondaryButtonAction && (
        <Button
          disabled={disabled}
          variant='outlined'
          endIcon={<Icon iconName='reset' />}
          sx={{
            boxShadow: 3,
            bgcolor: 'common.white',
            '&:hover': {
              bgcolor: ({ palette }) => palette.grey[50],
            },
          }}
          onClick={secondaryButtonAction}
          {...secondaryButtonProps}
        >
          {loading ? 'Loading...' : 'Discard Changes'}
        </Button>
      )}
      {primaryButtonAction && (
        <Button
          type='submit'
          form={formId}
          onClick={primaryButtonAction}
          disabled={disabled}
          variant='contained'
          size='large'
          endIcon={<Icon iconName='saveBackup' />}
          sx={{
            boxShadow: 3,
            '&:disabled': {
              bgcolor: ({ palette }) => palette.grey[200],
            },
          }}
          {...primaryButtonProps}
        >
          {loading ? 'Loading...' : primaryButtonText || 'Save Changes'}
        </Button>
      )}
    </FloatingActionButtonWrapper>
  );
};

export default FormActionButtons;
