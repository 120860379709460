import { BroadcastChannel } from 'broadcast-channel';
import { Transition } from 'history';
import { useCallback } from 'react';
import { useBlocker } from './useBlocker';

const bc = new BroadcastChannel('Extract-Management');
const ResetDesignMessage = 'reset design';
const ResetSettingsMessage = 'reset settings';

export const usePromptForXMLayout = (message: string, when = true): void => {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) {
        bc.postMessage(ResetSettingsMessage);
        bc.postMessage(ResetDesignMessage);
        tx.retry();
      }
    },
    [message]
  );

  useBlocker(blocker, when);
};
