import { FieldArrayRenderProps, FormikProps } from 'formik';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormValues } from './ConnectTemplateForm';
import { PlaceholderCategory } from '../connectTemplates.types';
import PlaceholderFieldArrayItem from './PlaceholderFieldArrayItem';

interface PlaceholderFieldArrayProps extends FieldArrayRenderProps {
  styles: PlaceholderCategory[];
  types: PlaceholderCategory[];
}

const PlaceholderFieldArray = (
  props: PlaceholderFieldArrayProps
): JSX.Element => {
  const { form, push, remove, styles, types } = props;

  const { initialValues, isSubmitting, values } =
    form as FormikProps<FormValues>;

  const handleAddPlaceholder = (): void => {
    push({
      imageProfile: '',
      name: '',
      optional: false,
      placeholderId: '',
      styleId: '',
      styleName: '',
      typeId: '',
      typeName: '',
    });
  };

  const handleRemovePlaceholder = (index: number): void => {
    remove(index);
  };

  return (
    <>
      {!!initialValues.placeholders && (
        <>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align='center'>Type</TableCell>
                  <TableCell align='center'>Style</TableCell>
                  <TableCell>Asset Profile</TableCell>
                  <TableCell align='center'>Optional</TableCell>
                  <TableCell align='center'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(values.placeholders || []).map((placeholder, index) => (
                  <PlaceholderFieldArrayItem
                    index={index}
                    key={placeholder.placeholderId}
                    onRemove={handleRemovePlaceholder}
                    placeholder={placeholder}
                    styles={styles}
                    types={types}
                  />
                ))}
              </TableBody>
            </Table>

            <Stack justifyContent='end' sx={{ p: 1 }}>
              <Button
                disabled={isSubmitting}
                onClick={handleAddPlaceholder}
                size='small'
                sx={{ alignSelf: 'end' }}
                variant='outlined'
              >
                Add placeholder
              </Button>
            </Stack>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default PlaceholderFieldArray;
