import { Icon } from '@brandbank/portal-components';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import { alpha, styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import * as React from 'react';

type FileUploaderProps = {
  disabled?: boolean;
  id: string;
  imagesOnly?: boolean;
  maximum?: number;
  multiple?: boolean;
  name?: string;
  onChange?: (files: FileObject[]) => void;
};

type UploadedFileProps = {
  name: string;
  size: number;
  onDelete: () => void;
  invalid?: boolean;
};

export type FileObject = {
  base64: string;
  file: File;
};

const UploadedFile = (props: UploadedFileProps): JSX.Element => {
  const { name, size, onDelete, invalid } = props;

  const formatFileSize = (size: number): string => {
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(size) / Math.log(k));

    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <ListItem
      secondaryAction={
        <IconButton edge='end' onClick={onDelete}>
          <Icon iconName='trash' />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: `${invalid ? 'error' : 'primary'}.light` }}>
          <Icon iconName={invalid ? 'error' : 'report'} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={invalid ? 'Invalid file type' : formatFileSize(size)}
      />
    </ListItem>
  );
};

const Label = styled('label')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.grey[600],
  display: 'block',
  overflow: 'hidden',
  padding: theme.spacing(3, 2),
  position: 'relative',
  transition: '0.2s background-color ease-in-out',
  width: '100%',

  '&:not(.disabled):hover, &:not(.disabled).dragging': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&::before': {
      borderColor: alpha(theme.palette.primary.light, 0.2),
    },
  },

  '&.error': {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.light, 0.1),
    '&::before': {
      borderColor: alpha(theme.palette.error.light, 0.2),
    },
  },

  '&::before': {
    content: '" "',
    border: `6px dashed ${theme.palette.grey[200]}`,
    bottom: -4,
    left: -4,
    position: 'absolute',
    right: -4,
    top: -4,
    transition: '0.2s border-color ease-in-out',
  },

  '& svg, & p': {
    transition: '0.2s color ease-in-out',
  },
}));

const HiddenInput = styled('input')({
  height: 1,
  position: 'absolute',
  visibility: 'hidden',
  width: 1,
});

const overrideEvents = (event: any): void => {
  event.preventDefault();
  event.stopPropagation();
};

const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result ? reader.result.toString() : '');
    reader.onerror = (error) => reject(error);
  });
};

const FileUploader = (props: FileUploaderProps): JSX.Element => {
  const { disabled, id, imagesOnly, maximum, multiple, name, onChange } = props;
  const [error, setError] = React.useState('');
  const [invalidFiles, setInvalidFiles] = React.useState<File[]>([]);
  const [files, setFiles] = React.useState<FileObject[]>([]);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [numOfFilesHeld, setNumOfFilesHeld] = React.useState(0);

  const itemName = imagesOnly ? 'image' : 'file';
  const isDisabled = (!!maximum && files.length >= maximum) || disabled;
  const hasFiles = files.length > 0 || invalidFiles.length > 0;

  const exceedsMaximum = (numOfNewFiles: number): boolean => {
    if (!maximum) return false;
    return !!maximum && numOfNewFiles + files.length > maximum;
  };

  const removeFileByIndex = (index: number): void => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);

    onChange && onChange(newFiles);
  };

  const removeInvalidFileByIndex = (index: number): void => {
    setInvalidFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const resetEvents = (event: any): void => {
    overrideEvents(event);
    setIsDragging(false);
    setError('');
  };

  const processItems = async (items: File[]): Promise<void> => {
    const validItems: File[] = [];
    const invalidItems: File[] = [];

    items.map((item) => {
      if (imagesOnly) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        const isValid = allowedExtensions.exec(item.name);
        (isValid ? validItems : invalidItems).push(item);
      } else {
        validItems.push(item);
      }
    });

    if (validItems.length > 0) {
      setIsUploading(true);

      const mappedItems: FileObject[] = await Promise.all(
        items.map(async (item) => {
          const base64 = await toBase64(item);
          return { base64, file: item };
        })
      );

      if (onChange) {
        onChange([...files, ...mappedItems]);
      }

      setFiles(mappedItems);
    }

    setInvalidFiles(invalidItems);
    setIsUploading(false);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLLabelElement>): void => {
    overrideEvents(event);
    setIsDragging(true);

    const { items } = event.dataTransfer;

    if (maximum && exceedsMaximum(items.length)) {
      setError(
        `Maximum of ${maximum} ${itemName}${maximum > 1 ? 's' : ''} allowed`
      );
    }

    setNumOfFilesHeld(items.length);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>): void => {
    resetEvents(event);
    setNumOfFilesHeld(0);
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>): void => {
    resetEvents(event);

    if (event.dataTransfer.files) {
      const droppedFiles = Array.from(event.dataTransfer.files);

      if (maximum && exceedsMaximum(droppedFiles.length)) {
        setError(
          `Maximum of ${maximum} ${itemName}${maximum > 1 ? 's' : ''} allowed`
        );
        setTimeout(() => setError(''), 1500);
      } else {
        processItems(droppedFiles);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    overrideEvents(event);

    if (event.target.files) {
      const items = Array.from(event.target.files);

      if (maximum && exceedsMaximum(items.length)) {
        setError(
          `Maximum of ${maximum} ${itemName}${maximum > 1 ? 's' : ''} allowed`
        );
        setTimeout(() => setError(''), 1500);
      } else {
        processItems(items);
      }
    }
  };

  const message = React.useMemo(() => {
    if (error) return <Typography>{error}</Typography>;
    if (isUploading) return <Typography>Uploading...</Typography>;

    if (files.length > 0) {
      const numOfFiles = files.length;
      return (
        <Typography>
          {numOfFiles} {itemName}
          {numOfFiles > 1 ? 's' : ''} uploaded
          {maximum && `, ${maximum} maximum`}
        </Typography>
      );
    }

    if (isDragging && numOfFilesHeld) {
      return (
        <Typography>
          Holding {numOfFilesHeld} item
          {numOfFilesHeld > 1 ? 's' : ''}
        </Typography>
      );
    }

    return (
      <Typography>
        Drag and drop {itemName}
        {!!maximum && maximum === 1 ? '' : 's'} here, or <Link>browse</Link>
      </Typography>
    );
  }, [
    error,
    files,
    isDragging,
    isUploading,
    itemName,
    maximum,
    numOfFilesHeld,
  ]);

  return (
    <Box>
      <Label
        className={classnames({
          disabled: isDisabled,
          dragging: isDragging,
          error: !!error,
        })}
        htmlFor={id}
        onDrag={overrideEvents}
        onDragStart={overrideEvents}
        onDragEnd={overrideEvents}
        onDragOver={overrideEvents}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <HiddenInput
          accept={imagesOnly ? 'image/*' : '*'}
          disabled={isDisabled}
          id={id}
          multiple={multiple}
          name={name}
          onChange={handleChange}
          type='file'
        />
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='center'
          spacing={1}
        >
          <UploadIcon />
          {message}
        </Stack>
      </Label>

      {hasFiles && (
        <List
          sx={{
            border: 1,
            borderColor: (theme) => theme.palette.grey[200],
            borderRadius: 1,
            mt: 1,
          }}
        >
          {invalidFiles.map((item, i) => (
            <UploadedFile
              key={item.name}
              name={item.name}
              size={item.size}
              onDelete={() => removeInvalidFileByIndex(i)}
              invalid
            />
          ))}
          {files.map((item, i) => (
            <UploadedFile
              key={item.base64}
              name={item.file.name}
              size={item.file.size}
              onDelete={() => removeFileByIndex(i)}
            />
          ))}
        </List>
      )}
    </Box>
  );
};

export default FileUploader;
