import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { StatusUI, StatusEnum, Modal } from '@brandbank/portal-components';
import { create, useModal } from '@ebay/nice-modal-react';

export default create(() => {
  const { hide, remove, visible } = useModal();

  return (
    <Modal
      action={
        <Button color='error' onClick={hide} variant='contained'>
          OK
        </Button>
      }
      muiTransitionProps={{ onExited: remove }}
      onClose={hide}
      open={visible}
      title=''
      width='40%'
    >
      <StatusUI
        status={StatusEnum.ERROR}
        title='Login Error'
        subtitle='An error occurred whilst trying to log you in.'
        sx={{
          alignItems: 'center',
          '& svg': {
            height: 80,
            width: 80,
          },
          '& h3': {
            fontSize: '2rem',
          },
        }}
      />

      <Stack spacing={1} sx={{ mt: 2, textAlign: 'center' }}>
        <Typography>
          If you have not been granted access to the Client Integration Hub,
          please request this via your Account Handler.
        </Typography>

        <Typography>
          Otherwise, please retry shortly,{' '}
          <Link
            target='_blank'
            href='https://brandbank.atlassian.net/servicedesk/customer/portal/1'
          >
            raise an Integration Support Ticket
          </Link>
          , or contact your Account Handler.
        </Typography>
      </Stack>
    </Modal>
  );
});
