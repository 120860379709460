import axios from 'config/axios';
import { FeedSummary } from '../feeds.types';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

type Response = {
  purposeSummaries: FeedSummary[];
};

async function getFeeds() {
  const response = await axios.get<Response>('GetFeeds');

  if (response.status === 204) return [];
  return response.data.purposeSummaries;
}

const useFeeds = () => useQuery<FeedSummary[], AxiosError>(['feeds'], getFeeds);

export default useFeeds;
