import {
  ConnectMaxAssetType,
  ConnectMaxMappingType,
} from './connect-max.types';

export const connectMaxMappingTypes = ['shotType', 'additionalAsset'] as const;

export const connectMaxAssetTypes = [
  'imageFile',
  'videoFile',
  'embedded_Vimeo',
  'embedded_YouTube',
] as const;

export const assetTypeMap: Record<ConnectMaxAssetType, string> = {
  imageFile: 'Image File',
  videoFile: 'Video File',
  embedded_Vimeo: 'Embedded Vimeo',
  embedded_YouTube: 'Embedded YouTube',
};

export const userFacingMappingType: Record<ConnectMaxMappingType, string> = {
  shotType: 'Shot Type',
  additionalAsset: 'Additional Asset',
};

export const CONNECT_MAX_INFO_POINTS = [
  'The retailer must have the appropriate license for Connect Max in order for this configuration to be applied.',
  "This Connect Max configuration becomes available for application to Retailer's Purposes once you click save.",
  'Once saved you MUST add the correct Purpose Config option to apply this Connect Max configuration to a Purpose.',
  "Once the Retailer's Purpose Config has been changed to apply Connect Max, any previously transferred Products will need resending to be affected by this Connect Max configuration.",
] as const;

export const CONNECT_MAX_POSITION_TOOLTIP =
  "Order of additional images/assets (not including the T1) on the retailer's website. The T1 cannot be updated by Connect Max and should continue to be updated using the Image Switching processor in the feed Extract";

export const CONNECT_MAX_TARGET_MARKETS = [
  {
    id: 1,
    description: 'United Kingdom',
    code: 'GB',
  },
  {
    id: 2,
    description: 'Ireland',
    code: 'IE',
  },
  {
    id: 3,
    description: 'Czech Republic',
    code: 'CZ',
  },
  {
    id: 4,
    description: 'Poland',
    code: 'PL',
  },
  {
    id: 5,
    description: 'Germany',
    code: 'DE',
  },
  {
    id: 6,
    description: 'Slovakia',
    code: 'SK',
  },
  {
    id: 7,
    description: 'Thailand',
    code: 'TH',
  },
  {
    id: 8,
    description: 'Malaysia',
    code: 'MY',
  },
  {
    id: 9,
    description: 'Hungary',
    code: 'HU',
  },
  {
    id: 10,
    description: 'Netherlands',
    code: 'NL',
  },
  {
    id: 11,
    description: 'China',
    code: 'CN',
  },
  {
    id: 12,
    description: 'Turkey',
    code: 'TR',
  },
  {
    id: 13,
    description: 'Austria',
    code: 'AT',
  },
  {
    id: 14,
    description: 'United States',
    code: 'US',
  },
  {
    id: 15,
    description: 'Italy',
    code: 'IT',
  },
  {
    id: 16,
    description: 'France',
    code: 'FR',
  },
  {
    id: 17,
    description: 'Belgium',
    code: 'BE',
  },
  {
    id: 18,
    description: 'Luxembourg',
    code: 'LU',
  },
  {
    id: 19,
    description: 'Latvia',
    code: 'LV',
  },
  {
    id: 20,
    description: 'Croatia',
    code: 'HR',
  },
  {
    id: 21,
    description: 'Denmark',
    code: 'DK',
  },
  {
    id: 22,
    description: 'India',
    code: 'IN',
  },
  {
    id: 23,
    description: 'Canada',
    code: 'CA',
  },
  {
    id: 24,
    description: 'Russia',
    code: 'RU',
  },
  {
    id: 25,
    description: 'Colombia',
    code: 'CO',
  },
  {
    id: 26,
    description: 'Australia',
    code: 'AU',
  },
  {
    id: 27,
    description: 'United Arab Emirates',
    code: 'AE',
  },
  {
    id: 28,
    description: 'Indonesia',
    code: 'ID',
  },
  {
    id: 29,
    description: 'Mexico',
    code: 'MX',
  },
  {
    id: 30,
    description: 'Spain',
    code: 'ES',
  },
  {
    id: 31,
    description: 'Brazil',
    code: 'BR',
  },
  {
    id: 32,
    description: 'Vietnam',
    code: 'VD',
  },
  {
    id: 33,
    description: 'Argentina',
    code: 'AR',
  },
  {
    id: 34,
    description: 'Chile',
    code: 'CL',
  },
  {
    id: 35,
    description: 'South Korea',
    code: 'KR',
  },
  {
    id: 36,
    description: 'Finland',
    code: 'FI',
  },
  {
    id: 37,
    description: 'Norway',
    code: 'NO',
  },
  {
    id: 38,
    description: 'Sweden',
    code: 'SE',
  },
  {
    id: 39,
    description: 'Japan',
    code: 'JP',
  },
  {
    id: 40,
    description: 'Singapore',
    code: 'SG',
  },
  {
    id: 41,
    description: 'Switzerland',
    code: 'CH',
  },
  {
    id: 42,
    description: 'Portugal',
    code: 'PT',
  },
  {
    id: 43,
    description: 'Greece',
    code: 'GR',
  },
  {
    id: 44,
    description: 'Romania',
    code: 'RO',
  },
  {
    id: 45,
    description: 'Serbia',
    code: 'RS',
  },
  {
    id: 46,
    description: 'Ukraine',
    code: 'UA',
  },
  {
    id: 47,
    description: 'Philippines',
    code: 'PH',
  },
  {
    id: 48,
    description: 'Costa Rica',
    code: 'CR',
  },
  {
    id: 49,
    description: 'Estonia',
    code: 'EE',
  },
  {
    id: 50,
    description: 'Lithuania',
    code: 'LT',
  },
  {
    id: 51,
    description: 'South Africa',
    code: 'ZA',
  },
  {
    id: 53,
    description: 'El Salvador',
    code: 'SV',
  },
  {
    id: 54,
    description: 'Guatemala',
    code: 'GT',
  },
  {
    id: 55,
    description: 'Honduras',
    code: 'HN',
  },
  {
    id: 56,
    description: 'Nicaragua',
    code: 'NI',
  },
  {
    id: 57,
    description: 'Ecuador',
    code: 'EC',
  },
  {
    id: 58,
    description: 'Peru',
    code: 'PE',
  },
  {
    id: 59,
    description: 'Venezuela',
    code: 'VE',
  },
  {
    id: 60,
    description: 'Bulgaria',
    code: 'BG',
  },
  {
    id: 61,
    description: 'Nigeria',
    code: 'NG',
  },
  {
    id: 62,
    description: 'Egypt',
    code: 'EG',
  },
  {
    id: 63,
    description: 'Slovenia',
    code: 'SI',
  },
  {
    id: 64,
    description: 'Armenia',
    code: 'AM',
  },
  {
    id: 65,
    description: 'Moldova',
    code: 'MD',
  },
  {
    id: 66,
    description: 'North Macedonia',
    code: 'MK',
  },
  {
    id: 67,
    description: 'Panama',
    code: 'PA',
  },
  {
    id: 68,
    description: 'Belarus',
    code: 'BY',
  },
  {
    id: 69,
    description: 'Cyprus',
    code: 'CY',
  },
  {
    id: 70,
    description: 'Puerto Rico',
    code: 'PR',
  },
  {
    id: 71,
    description: 'Kazakhstan',
    code: 'KZ',
  },
  {
    id: 72,
    description: 'New Zealand',
    code: 'NZ',
  },
];
