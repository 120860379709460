import { Icon } from '@brandbank/portal-components';
import { styled } from '@mui/material/styles';
import { SnackbarProvider as UnstyledProvider } from 'notistack';
import React from 'react';

const StyledSnackbarProvider = styled(UnstyledProvider)(({ theme }) => ({
  '&.SnackbarItem-contentRoot': {
    backgroundColor: theme.palette.background.paper,
    '.SnackbarItem-message': {
      color: theme.palette.text.primary,
    },
  },
  '&.SnackbarItem-variantSuccess': {
    borderLeft: `solid 8px ${theme.palette.success.main}`,
    '.SnackbarItem-message': {
      '& svg': {
        color: theme.palette.success.main,
      },
    },
  },
  '&.SnackbarItem-variantWarning': {
    borderLeft: `solid 8px ${theme.palette.warning.main}`,
    '.SnackbarItem-message': {
      '& svg': {
        color: theme.palette.warning.main,
      },
    },
  },
  '&.SnackbarItem-variantError': {
    borderLeft: `solid 8px ${theme.palette.error.main}`,
    '.SnackbarItem-message': {
      '& svg': {
        color: theme.palette.error.main,
      },
    },
  },
  '&.SnackbarItem-variantInfo': {
    borderLeft: `solid 8px ${theme.palette.info.main}`,
    '.SnackbarItem-message': {
      '& svg': {
        color: theme.palette.info.main,
      },
    },
  },
}));

const SnackbarProvider: React.FC = ({ children }) => (
  <StyledSnackbarProvider
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'top',
    }}
    maxSnack={1}
    iconVariant={{
      success: (
        <Icon
          iconName='success'
          fontSize='small'
          color='success'
          sx={{ mr: '0.5rem' }}
        />
      ),
      warning: (
        <Icon
          iconName='error'
          fontSize='small'
          color='warning'
          sx={{ mr: '0.5rem' }}
        />
      ),
      error: (
        <Icon
          iconName='restricted'
          fontSize='small'
          color='error'
          sx={{ mr: '0.5rem' }}
        />
      ),
      info: (
        <Icon
          iconName='info'
          fontSize='small'
          color='info'
          sx={{ mr: '0.5rem' }}
        />
      ),
    }}
  >
    {children}
  </StyledSnackbarProvider>
);

export default SnackbarProvider;
