import axios from 'config/axios';
import { FavouriteFeed } from '../favouriteFeeds.type';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

async function addFavouriteFeed(item: Pick<FavouriteFeed, 'id' | 'name'>) {
  const response = await axios.put<boolean>('AddFavouriteFeed', item);

  if (!response.data) {
    return Promise.reject(
      new Error(`Unable to add feed with purposeId ${item.id} to favourites`)
    );
  }

  return true;
}

const useAddFavouriteFeed = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const mutation = useMutation(addFavouriteFeed, {
    onError: () => {
      enqueueSnackbar('Unable to add favourite', { variant: 'error' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['favouriteFeeds']);
    },
  });

  return mutation;
};

export default useAddFavouriteFeed;
