import { createContext, useContext } from 'react';
import { FeedDetailItem } from './feeds.types';

export const FeedDetailsCtx = createContext<FeedDetailItem | null>(null);

export const useFeedDetailsCtx = () => {
  const data = useContext(FeedDetailsCtx);

  if (!data) {
    throw new Error(
      'useFeedDetailsCtx is being used outside of FeedDetailsCtx.Provider'
    );
  }

  return data;
};
