import { useCallback, useContext, useEffect } from 'react';
import type { Blocker, Transition } from 'history';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export const useBlocker = (blocker: Blocker, when = true): void => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as any).block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const usePrompt = (message: string, when = true): void => {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message]
  );

  useBlocker(blocker, when);
};
