import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useProductSearchCtx } from '../ProductSearch.context';
import { ProductPageView } from '../productSearch.types';

const useProductViewControls = (): [
  ProductPageView,
  Dispatch<SetStateAction<ProductPageView>>
] => {
  const [view, setView] = useState<ProductPageView>('products');
  const { state } = useProductSearchCtx();
  const { numErrors, numProducts, numWarnings } = state;

  useEffect(() => {
    if (numProducts === 0) {
      if (numErrors > 0) setView('errors');
      if (numWarnings > 0) setView('warnings');
    } else {
      setView('products');
    }
  }, [numErrors, numProducts, numWarnings]);

  return [view, setView];
};

export default useProductViewControls;
