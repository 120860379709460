import { Table } from '@tanstack/react-table';
import { ProductItem } from '../productSearch.types';
import { isRowSelectable } from '../productSearch.helpers';
import Checkbox from '@mui/material/Checkbox';

interface ProductsTableSelectAllProps {
  table: Table<ProductItem>;
}

const ProductsTableSelectAll = ({ table }: ProductsTableSelectAllProps) => {
  const selectableRows = table
    .getFilteredRowModel()
    .rows.filter(isRowSelectable);

  const numSelectable = selectableRows.length;
  const numSelected = table.getSelectedRowModel().rows.length;

  const handleChange = (_: unknown, checked: boolean) => {
    if (checked) {
      table.setRowSelection(
        selectableRows.reduce(
          (arr, row) => ({
            ...arr,
            [+row.id]: true,
          }),
          {}
        )
      );
    } else {
      table.setRowSelection({});
    }
  };

  return (
    <Checkbox
      checked={numSelected === numSelectable}
      disabled={numSelectable === 0}
      indeterminate={numSelected > 0 && numSelected < numSelectable}
      onChange={handleChange}
    />
  );
};

export default ProductsTableSelectAll;
