import { Button, ButtonGroup, Skeleton } from '@mui/material';
import { useProductSearchCtx } from '../ProductSearch.context';
import { ProductPageView } from '../productSearch.types';

interface ProductViewControlsProps {
  active: ProductPageView;
  isLoading?: boolean;
  onChange: (view: ProductPageView) => void;
}

const ProductViewControls = ({
  active,
  isLoading,
  onChange,
}: ProductViewControlsProps) => {
  const { state } = useProductSearchCtx();
  const { numErrors, numProducts, numWarnings } = state;

  const handleChange = (view: ProductPageView) => () => {
    onChange(view);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={40} width={340} />
      ) : (
        <ButtonGroup color='info'>
          <Button
            disabled={numProducts === 0}
            onClick={handleChange('products')}
            variant={active === 'products' ? 'contained' : 'outlined'}
          >
            Valid products
          </Button>
          <Button
            disabled={numWarnings === 0}
            onClick={handleChange('warnings')}
            variant={active === 'warnings' ? 'contained' : 'outlined'}
          >
            Warnings
          </Button>
          <Button
            disabled={numErrors === 0}
            onClick={handleChange('errors')}
            variant={active === 'errors' ? 'contained' : 'outlined'}
          >
            Errors
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default ProductViewControls;
