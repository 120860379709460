import { Icon } from '@brandbank/portal-components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { userFacingMappingType } from '../connect-max.constants';
import { getGridTemplateColumns } from '../connect-max.helpers';
import { ConnectMaxMappingItem } from '../connect-max.types';

const Row = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  columnGap: '1rem',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  padding: '0.5rem 0',
}));

const ActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
});

type ConnectMaxListItemProps = {
  item: ConnectMaxMappingItem;
  handleEditRow: () => void;
  handleDeleteRow: () => void;
  dragDisabled: boolean;
};

const ConnectMaxListItem = ({
  item,
  handleEditRow,
  handleDeleteRow,
  dragDisabled,
}: ConnectMaxListItemProps) => (
  <Row
    sx={{
      gridTemplateColumns: getGridTemplateColumns(dragDisabled),
    }}
  >
    <Typography>{item.position}</Typography>
    <Typography>{userFacingMappingType[item.mappingType]}</Typography>
    <Typography>{item.mapping}</Typography>
    <ActionsContainer>
      <Tooltip title='Edit Item' enterDelay={750} enterNextDelay={750}>
        <IconButton sx={{ width: 'fit-content' }} onClick={handleEditRow}>
          <Icon iconName='edit' />
        </IconButton>
      </Tooltip>
      <Tooltip title='Remove Item' enterDelay={750} enterNextDelay={750}>
        <IconButton sx={{ width: 'fit-content' }} onClick={handleDeleteRow}>
          <Icon iconName='trash' />
        </IconButton>
      </Tooltip>
    </ActionsContainer>
    {dragDisabled ? <div /> : <DragIndicatorIcon sx={{ marginLeft: 'auto' }} />}
  </Row>
);

export default ConnectMaxListItem;
