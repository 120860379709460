import { ProductItem } from '../productSearch.types';
import useProductsTable, { TableOptionsMeta } from '../hooks/useProductsTable';
import { alpha } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  TableHeadCell,
  TablePagination,
  TableSearch,
} from '@brandbank/portal-components';
import { flexRender } from '@tanstack/react-table';
import ProductsTableRowTooltip from './ProductsTableRowTooltip';
import ProductsTableFilter from './ProductsTableFilter';

type ProductsTableProps = {
  items: ProductItem[];
  onResend: (items: ProductItem[], clearSelectedItems: () => void) => void;
};

const ProductsTable = (props: ProductsTableProps) => {
  const { items, onResend } = props;

  const table = useProductsTable(items);

  const numProductsSelected = Object.keys(table.getState().rowSelection).length;

  const handleClickClearSelected = () => {
    table.setRowSelection({});
  };

  const handleClickResend = () => {
    const selectedProducts = table
      .getSelectedRowModel()
      .rows.map(({ original }) => original as ProductItem);

    onResend(selectedProducts, handleClickClearSelected);
  };

  return (
    <TableContainer component={Paper}>
      <Stack alignItems='center' direction='row' spacing={1} sx={{ p: 1 }}>
        <TableSearch
          placeholder='Search by GTIN, PVID, or Description'
          sx={{
            mr: 'auto',
            '.MuiFormControl-root': {
              maxWidth: 400,
              width: '100%',
            },
          }}
          table={table}
        />

        <ProductsTableFilter
          column={table.getColumn('subscriberCode')}
          label='Subcode'
          multiple
          placeholder='Search subcodes...'
          sx={{ width: 400 }}
        />

        <ProductsTableFilter
          column={table.getColumn('productVersionStatusDescription')}
          label='Status'
          sx={{ width: 240 }}
        />

        <ProductsTableFilter
          column={table.getColumn('isQueued')}
          label='Queued?'
          sx={{ width: 180 }}
        />

        <ProductsTableFilter
          column={table.getColumn('isRanged')}
          label='Ranged?'
          sx={{ width: 180 }}
        />
      </Stack>

      {numProductsSelected > 0 && (
        <Toolbar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }}
        >
          <Typography sx={{ mr: 'auto' }}>
            {numProductsSelected} selected
          </Typography>

          <Button onClick={handleClickClearSelected} sx={{ mr: 1 }}>
            Clear selected
          </Button>

          <Button onClick={handleClickResend} variant='contained'>
            Resend
          </Button>
        </Toolbar>
      )}

      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeadCell key={header.id} header={header} />
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {table.getRowModel().rows.map((row) => {
            const { enableRowSelection, canResend } = table.options
              .meta as TableOptionsMeta;

            const item = row.original as ProductItem;

            const isRowSelectable = enableRowSelection(row);

            const handleRowClick = () => {
              if (isRowSelectable && canResend) {
                row.toggleSelected();
              }
            };

            return (
              <ProductsTableRowTooltip
                key={row.id}
                productStatus={item.productStatusDescription}
                show={!isRowSelectable}
              >
                <TableRow
                  onClick={handleRowClick}
                  hover={isRowSelectable && canResend}
                  sx={{
                    backgroundColor: (theme) => {
                      if (!isRowSelectable) return theme.palette.grey[200];
                      if (row.getIsSelected())
                        return alpha(theme.palette.primary.light, 0.2);
                      return 'initial';
                    },
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </ProductsTableRowTooltip>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination table={table} />
    </TableContainer>
  );
};

export default ProductsTable;
