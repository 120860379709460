import { Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Autocomplete,
  Button,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { HTMLAttributes, SyntheticEvent, useState } from 'react';

type TargetMarket = { code: string; id: number; description: string };

export type TargetMarketSelectModalProps = {
  options: { code: string; id: number; description: string }[];
  initialSelected?: TargetMarket[];
};

export default NiceModal.create(
  ({ options, initialSelected = [] }: TargetMarketSelectModalProps) => {
    const { hide, remove, resolve, visible } = useModal();

    const [selected, setSelected] = useState<TargetMarket[]>(initialSelected);

    const handleChange = (_: SyntheticEvent, values: TargetMarket[]): void => {
      setSelected(values);
    };

    const handleAdd = (): void => {
      const codes: { code: string }[] = selected.map((x) => ({ code: x.code }));

      resolve(codes);
      hide();
    };

    const renderOption = (
      props: HTMLAttributes<HTMLLIElement>,
      option: TargetMarket
    ) => (
      <ListItem {...props}>
        {option.code} | {option.description}
      </ListItem>
    );

    const getOptionLabel = (option: TargetMarket) =>
      `${option.code} | ${option.description}`;

    const isOptionEqualToValue = (option: TargetMarket, value: TargetMarket) =>
      option.id === value.id;

    return (
      <Modal
        action={
          <>
            <Button onClick={hide} variant='outlined'>
              Cancel
            </Button>
            <Button
              disabled={selected.length === 0}
              onClick={handleAdd}
              variant='contained'
            >
              Confirm
            </Button>
          </>
        }
        muiTransitionProps={{ onExited: remove }}
        onClose={hide}
        open={visible}
        title='Select Target Markets'
        width='30%'
      >
        <Autocomplete
          disableCloseOnSelect
          getOptionLabel={getOptionLabel}
          id='multi-select-input'
          loading={!options.length}
          loadingText='Loading…'
          multiple
          onChange={handleChange}
          options={options}
          renderInput={(params) => (
            <TextField {...params} label='Target Markets' />
          )}
          renderOption={renderOption}
          value={selected}
          isOptionEqualToValue={isOptionEqualToValue}
        />
        <Typography
          textAlign='right'
          fontSize='12px'
          sx={{ paddingTop: '1rem' }}
        >
          At least 1 Target Market must be selected*
        </Typography>
      </Modal>
    );
  }
);
