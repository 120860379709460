import { Icon } from '@brandbank/portal-components';
import { show } from '@ebay/nice-modal-react';
import { Button, Stack, Typography } from '@mui/material';
import ActionModal from 'features/ui/ActionModal';

type ConnectMaxDeleteButtonProps = {
  handleDelete: () => void;
};

const ConnectMaxDeleteButton = ({
  handleDelete,
}: ConnectMaxDeleteButtonProps) => {
  const showConfirmDeleteModal = () => {
    show(ActionModal, {
      title: 'Confirm Delete',
      content: (
        <Stack spacing={3}>
          <Typography>
            Are you sure you want to delete this Connect Max layout?
          </Typography>
          <Typography>
            The Connect Max layout for this company will no longer be available
            for suppliers to edit in SXP for products.
          </Typography>
        </Stack>
      ),
      onConfirm: handleDelete,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      width: '36rem',
      type: 'error',
    });
  };

  return (
    <Button
      variant='contained'
      color='error'
      sx={{ height: 'fit-content' }}
      endIcon={<Icon iconName='trash' />}
      onClick={showConfirmDeleteModal}
    >
      Delete
    </Button>
  );
};

export default ConnectMaxDeleteButton;
