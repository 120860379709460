import { Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Stack } from '@mui/material';

export type ActionModalProps = {
  onConfirm: () => void;
  title: string;
  content: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  width?: string;
  actionsDisabled?: boolean;
  type?: 'default' | 'error';
};

export default NiceModal.create(
  ({
    onConfirm,
    title,
    content,
    confirmButtonText,
    cancelButtonText,
    width,
    actionsDisabled = false,
    type = 'default',
  }: ActionModalProps) => {
    const modal = useModal();

    return (
      <Modal
        action={
          <>
            <Button
              onClick={modal.hide}
              variant='outlined'
              disabled={actionsDisabled}
              color={type === 'error' ? 'error' : 'primary'}
            >
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button
              onClick={() => {
                onConfirm && onConfirm();
                modal.hide();
              }}
              variant='contained'
              color={type === 'error' ? 'error' : 'primary'}
              disabled={actionsDisabled}
            >
              {confirmButtonText || 'Confirm'}
            </Button>
          </>
        }
        muiTransitionProps={{ onExited: modal.remove }}
        onClose={modal.hide}
        open={modal.visible}
        title={title}
        width={width || '40%'}
      >
        <Stack spacing={1}>{content}</Stack>
      </Modal>
    );
  }
);
