import { RoutingTabs } from 'features/ui';
import { useMatch, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import useUser from 'hooks/useUser';

const AdminLayout = () => {
  const routeMatch = useMatch(`/admin/:tab`);
  const defaultTab = 'audit-logs';
  const currentTab = routeMatch?.params.tab || defaultTab;

  const { hasRole } = useUser();

  return (
    <>
      <RoutingTabs
        tabs={[
          {
            label: 'Audit logs',
            to: '/admin/audit-logs',
          },
          {
            label: 'Feed reset approval routes',
            show: hasRole('Feed Reset Approval Route Create and Update'),
            to: '/admin/feed-reset-approval-routes',
          },
        ]}
        value={currentTab}
      />

      <Box sx={{ p: 3 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default AdminLayout;
