import React from 'react';
import { Icon, Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal
      action={
        <Button onClick={modal.hide} variant='contained'>
          OK
        </Button>
      }
      muiTransitionProps={{ onExited: modal.remove }}
      onClose={modal.hide}
      open={modal.visible}
      title='Get in touch'
      width='40%'
    >
      <Stack spacing={2}>
        <Typography variant='h6'>Bugs &amp; issues</Typography>
        <Typography>
          If you are experiencing any bugs or issues with any aspect of your
          feed(s), please contact our Client Integration Support Team below
        </Typography>
        <Button
          component={Link}
          href='https://brandbank.atlassian.net/servicedesk/customer/portal/1'
          startIcon={<Icon iconName='open' />}
          sx={{ alignSelf: 'start' }}
          target='_blank'
          variant='outlined'
        >
          Integration Support
        </Button>

        <Typography variant='h6'>
          Configuration, Updates &amp; Upgrades
        </Typography>
        <Typography>
          If you need to change your feed configuration, update any aspects or
          would like to talk to us about upgrading to include functionality such
          as rich content, health &amp; wellness or sustainability, please
          contact our Pre-Sales Team below
        </Typography>

        <Button
          component={Link}
          href='mailto:presales@smb.nielseniq.com'
          startIcon={<Icon iconName='email' />}
          sx={{ alignSelf: 'start' }}
          target='_blank'
          variant='outlined'
        >
          Contact pre-sales team
        </Button>
      </Stack>
    </Modal>
  );
});
