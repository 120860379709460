import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import NiceModal from '@ebay/nice-modal-react';
import ProductsTableInfoModal from './ProductsTableInfoModal';

interface ProductsTableRowTooltipProps extends Omit<TooltipProps, 'title'> {
  productStatus: string;
  show: boolean;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,

    '&::before': {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(2)}px`,
    fontSize: 15,
  },
}));

const ProductsTableRowTooltip = (props: ProductsTableRowTooltipProps) => {
  const { productStatus, show, ...rest } = props;

  const renderTooltip = () => (
    <Stack spacing={2} sx={{ p: 1 }}>
      <Typography>This product cannot be selected for resend.</Typography>
      <Button
        onClick={() =>
          NiceModal.show(ProductsTableInfoModal, {
            productStatus,
          })
        }
        size='small'
        sx={{ alignSelf: 'end' }}
        variant='contained'
      >
        More info
      </Button>
    </Stack>
  );

  return <StyledTooltip arrow title={show ? renderTooltip() : ''} {...rest} />;
};

export default ProductsTableRowTooltip;
