import { getUniqueStrings } from '@brandbank/portal-components';
import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useProductSearchCtx } from '../ProductSearch.context';

interface ProductSearchResultSummaryProps {
  isLoading?: boolean;
}

interface SummaryCountTileProps {
  label: string;
  color: string;
  count: number | ReactNode;
}

const SummaryCountTile = ({ color, count, label }: SummaryCountTileProps) => (
  <Paper
    data-testid={label.toLowerCase().replace(/ /gm, '-')}
    sx={{ minWidth: 240, p: 2 }}
  >
    <Typography>{label}</Typography>
    {typeof count === 'number' ? (
      <Typography variant='h4'>{count}</Typography>
    ) : (
      <>{count}</>
    )}
    <Box
      className='accent'
      sx={{
        bgcolor: color,
        borderRadius: 1,
        height: 6,
        mt: 2,
        width: '100%',
      }}
    />
  </Paper>
);

const ProductSearchResultSummary = ({
  isLoading,
}: ProductSearchResultSummaryProps) => {
  const { state } = useProductSearchCtx();
  const { numErrors, numProducts, numWarnings, products } = state;

  const numGtins = getUniqueStrings(products.map((item) => item.gtin)).length;

  const numPvids = getUniqueStrings(
    products.map(({ productVersionId }) => String(productVersionId))
  ).length;

  const renderProductsSummary = () => {
    if (numGtins === numPvids) return numProducts;
    return (
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Typography variant='h4'>
          {numGtins} <Typography component='span'>Gtins</Typography>
        </Typography>
        <Typography variant='h4'>
          {numPvids} <Typography component='span'>PVIDs</Typography>
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack direction='row' spacing={1}>
      {isLoading ? (
        Array.from(Array(3).keys()).map((i) => (
          <Skeleton
            height={115}
            key={i}
            width={240}
            sx={{ borderRadius: 1, maxWidth: 240 }}
            variant='rectangular'
          />
        ))
      ) : (
        <>
          <SummaryCountTile
            color='success.main'
            count={renderProductsSummary()}
            label='Valid products'
          />

          <SummaryCountTile
            color='warning.main'
            count={numWarnings}
            label='Warnings'
          />

          <SummaryCountTile
            color='error.main'
            count={numErrors}
            label='Errors'
          />
        </>
      )}
    </Stack>
  );
};

export default ProductSearchResultSummary;
