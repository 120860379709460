import { Reducer } from 'react';
import {
  ConnectMaxAssetItem,
  ConnectMaxAssetType,
  ConnectMaxMappingItem,
} from './connect-max.types';

type AddedPayload = {
  type: 'added';
  assetType: ConnectMaxAssetType;
  item: Omit<ConnectMaxMappingItem, 'position'>;
};

type EditedPayload = {
  type: 'edited';
  assetType: ConnectMaxAssetType;
  index: number;
  item: ConnectMaxMappingItem;
};

type DeletedPayload = {
  type: 'deleted';
  assetType: ConnectMaxAssetType;
  index: number;
};

type MovedPayload = {
  type: 'moved';
  newAssets: ConnectMaxAssetItem[];
};

type SetAssetsPayload = {
  type: 'set_assets';
  newAssets: ConnectMaxAssetItem[];
};

export type ConnectMaxAction =
  | AddedPayload
  | EditedPayload
  | DeletedPayload
  | MovedPayload
  | SetAssetsPayload;

const connectMaxReducer: Reducer<ConnectMaxAssetItem[], ConnectMaxAction> = (
  assets,
  action
) => {
  const getAssetIndex = (assetType: ConnectMaxAssetType) =>
    assets.findIndex((x) => x.assetType === assetType);

  switch (action.type) {
    case 'added': {
      const { assetType, item } = action;

      const assetIndex = getAssetIndex(assetType);

      // If this asset type doesn't exist, create that asset type with the new item //
      if (assetIndex === -1) {
        return [
          ...assets,
          {
            assetType,
            positions: [{ position: 1, ...item }],
          },
        ];
      }

      const lastShotTypeIndex = assets[assetIndex].positions.findLastIndex(
        (x) => x.mappingType === 'shotType'
      );

      // Ensure new shotTypes are placed before the additional assets //
      const position =
        item.mappingType === 'additionalAsset'
          ? assets[assetIndex].positions.length + 1
          : lastShotTypeIndex !== -1
          ? lastShotTypeIndex + 1
          : 0;

      const copy = [...assets];

      copy[assetIndex].positions.splice(position, 0, {
        ...item,
        position,
      });

      // Update each position to match its index + 1 (the order of the item) //
      copy[assetIndex].positions.forEach(
        (pos, idx, arr) => (arr[idx] = { ...pos, position: idx + 1 })
      );

      return copy;
    }
    case 'edited': {
      const { assetType, item, index } = action;
      const copy = [...assets];

      const assetIndex = getAssetIndex(assetType);

      copy[assetIndex].positions[index] = item;

      const lastShotTypeIndex = copy[assetIndex].positions.findLastIndex(
        (x) => x.mappingType === 'shotType'
      );

      const firstAdditionAssetIndex = copy[assetIndex].positions.findIndex(
        (x) => x.mappingType === 'additionalAsset'
      );

      // Maintain correct ordering of mapping types when they are changed //
      if (item.mappingType === 'shotType' && index > firstAdditionAssetIndex) {
        const [removed] = copy[assetIndex].positions.splice(index, 1);
        copy[assetIndex].positions.splice(firstAdditionAssetIndex, 0, removed);

        copy[assetIndex].positions.forEach(
          (pos, idx, arr) => (arr[idx] = { ...pos, position: idx + 1 })
        );
      }

      // Maintain correct ordering of mapping types when they are changed //
      if (item.mappingType === 'additionalAsset' && index < lastShotTypeIndex) {
        const [removed] = copy[assetIndex].positions.splice(index, 1);
        copy[assetIndex].positions.splice(lastShotTypeIndex, 0, removed);

        copy[assetIndex].positions.forEach(
          (pos, idx, arr) => (arr[idx] = { ...pos, position: idx + 1 })
        );
      }

      return copy;
    }
    case 'deleted': {
      const { index, assetType } = action;
      const copy = [...assets];

      const assetIndex = getAssetIndex(assetType);

      copy[assetIndex].positions.splice(index, 1);

      if (copy[assetIndex].positions.length < 1) {
        copy.splice(assetIndex, 1);
      }

      // Update each position to match its index + 1 (the order of the item) //
      if (copy[assetIndex]) {
        copy[assetIndex].positions.forEach(
          (pos, idx, arr) => (arr[idx] = { ...pos, position: idx + 1 })
        );
      }

      return copy;
    }
    case 'moved': {
      // Update each position to match its index + 1 (the order of the item) //
      return action.newAssets.map(({ assetType, positions }) => ({
        assetType,
        positions: positions.map((pos, index) => ({
          ...pos,
          position: index + 1,
        })),
      }));
    }
    case 'set_assets': {
      return action.newAssets;
    }
    default: {
      throw Error('Unknown action');
    }
  }
};

export default connectMaxReducer;
