import { SimpleTable } from '@brandbank/portal-components';

type CookieTableProps = {
  items: { cookie: string; duration: string; description: string }[];
};

const CookieTable = ({ items }: CookieTableProps): JSX.Element => (
  <SimpleTable
    columns={[
      { id: 'cookie', label: 'Cookie' },
      { align: 'center', id: 'duration', label: 'Duration' },
      { id: 'description', label: 'Description' },
    ]}
    items={items}
    sx={{ borderRadius: 1, mt: 2 }}
    tableProps={{
      stickyHeader: true,
      size: 'small',
    }}
  />
);

export default CookieTable;
