import { Icon } from '@brandbank/portal-components';
import { CardContent, CircularProgress, Typography } from '@mui/material';

type CardContentStatusProps = {
  type: 'error' | 'loading';
  errorMessage?: string;
};

const CardContentStatus = ({ type, errorMessage }: CardContentStatusProps) => (
  <CardContent
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
    }}
  >
    {type === 'error' && (
      <Icon
        iconName='error'
        color='error'
        sx={{ height: '4rem', width: '4rem' }}
      />
    )}

    {type === 'loading' && (
      <CircularProgress sx={{ height: '4rem', width: '4rem' }} />
    )}

    <Typography variant='h5' fontWeight={600} sx={{ color: 'grey.800' }}>
      {type === 'loading' ? 'Loading...' : 'Something went wrong'}
    </Typography>

    {type === 'error' && errorMessage !== '' && (
      <Typography>
        {errorMessage || "We've encountered an error, please try again later."}
      </Typography>
    )}
  </CardContent>
);

export default CardContentStatus;
