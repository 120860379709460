import { useProductSearchCtx } from '../ProductSearch.context';
import { Alert } from '@mui/material';
import { SimpleTable } from '@brandbank/portal-components';

type TableItem = {
  gtin: string;
  message: string;
};

const ProductWarningsView = () => {
  const { state } = useProductSearchCtx();
  const { productsNotFound, productsNotFoundInSystem } = state;

  const tableItems: TableItem[] = [];

  productsNotFound.forEach((item) =>
    tableItems.push({
      gtin: item.gtin,
      message: `GTIN ${item.gtin} is not in scope for this feed`,
    })
  );

  productsNotFoundInSystem.forEach((item) =>
    tableItems.push({
      gtin: item.gtin,
      message: `GTIN ${item.gtin} has not been captured by NielsenIQ Brandbank`,
    })
  );

  return (
    <>
      <Alert severity='info' sx={{ m: 1 }}>
        Any products not found when loading GTINS into the products table will
        be displayed here.
        <br />
        <br />
        These GTINs either:
        <ul>
          <li>have not been captured by NielsenIQ Brandbank</li>
          <li>
            are not in scope for this feed (such as GTINs associated to the
            wrong target market or subcode)
          </li>
        </ul>
      </Alert>

      <SimpleTable
        columns={[
          { id: 'gtin', label: 'GTIN' },
          { id: 'message', label: 'Message' },
        ]}
        items={tableItems}
        sx={{ borderRadius: 1, bgcolor: 'background.paper' }}
      />
    </>
  );
};

export default ProductWarningsView;
