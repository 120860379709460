import { booleanToString, formatDate } from '@brandbank/portal-components';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { CardRow } from 'features/ui';
import { useFeedDetailsCtx } from '../FeedDetails.context';

const FeedsOverviewCoverageInfo = () => {
  const { detail } = useFeedDetailsCtx();

  const asDate = (str: string | null) => (str ? formatDate(str, true) : null);

  return (
    <Card>
      <CardHeader
        title='Coverage Information'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        <Stack spacing={1}>
          <CardRow
            label='Coverage Status:'
            value={detail.coverageImportStatus}
          />
          <CardRow
            label='Allowed to import coverage?:'
            value={booleanToString(detail.coverageImport)}
          />
          <CardRow
            label='Coverage used to filter products?:'
            value={booleanToString(detail.usesRangeList)}
          />
          <CardRow
            label='API Coverage Imported:'
            value={asDate(detail.gtinsImported)}
          />
          <CardRow
            label='API Coverage Cached:'
            value={asDate(detail.xmlCoverageCached)}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FeedsOverviewCoverageInfo;
