import {
  AppSidebarHeader,
  AppSidebarLink,
  Icon,
} from '@brandbank/portal-components';
import AdminIcon from '@mui/icons-material/AccountCircleRounded';
import DashboardIcon from '@mui/icons-material/DashboardRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { paths } from 'features/routing';
import useUser from 'hooks/useUser';
import { SyntheticEvent } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const Link = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  '&.active .MuiListItem-root': {
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    '.MuiListItemIcon-root svg': {
      color: theme.palette.primary.main,
    },
    '.MuiListItemText-root span': {
      color: theme.palette.primary.main,
    },
  },
  '& svg, .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiTypography-root': {
    mt: '1px',
  },
  '&:hover svg': {
    color: theme.palette.primary.main,
  },
  '&:hover .MuiTypography-root': {
    color: theme.palette.primary.main,
  },
}));

const SidebarNavigation = (): JSX.Element => {
  const { hasRole, logout } = useUser();

  const handleLogout = (): void => {
    localStorage.removeItem('userPermission');
    logout();
  };

  const canViewExtractManagement =
    hasRole('View Extracts') || hasRole('Manage Extracts');

  const handleExtractManagementNavigation = (event: SyntheticEvent) => {
    const path = window.location.pathname;
    if (path.startsWith(paths.extractList) && path !== paths.extractList) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  };

  return (
    <>
      <AppSidebarHeader
        title='Client Integration Hub'
        sx={{
          '& .header-title': {
            fontSize: '21px',
          },
        }}
      />
      <Stack sx={{ height: '80vh', mt: 3 }}>
        <Link to={paths.dashboard}>
          <AppSidebarLink icon={<DashboardIcon />} label='Dashboard' />
        </Link>
        <Link to={paths.feeds}>
          <AppSidebarLink icon={<Icon iconName='list' />} label='Feeds' />
        </Link>
        {canViewExtractManagement && (
          <Link
            to={paths.extractList}
            onClick={handleExtractManagementNavigation}
          >
            <AppSidebarLink icon={<Icon iconName='list' />} label='Extracts' />
          </Link>
        )}

        {/* TODO - Uncomment once retailers list is complete */}
        {/* <Link to={paths.companies}>
          <AppSidebarLink
            icon={<Icon iconName='building' />}
            label='Companies'
          />
        </Link> */}

        {hasRole('Administration Viewer') && (
          <Link to={paths.admin}>
            <AppSidebarLink icon={<AdminIcon />} label='Admin' />
          </Link>
        )}
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            borderRadius: 1,
            mt: 'auto',
            mb: 2,
            p: 2,
          }}
        >
          <Typography fontWeight={600} sx={{ color: 'text.primary', mb: 1 }}>
            API Documentation
          </Typography>
          <Typography
            paragraph
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            View our documentation as well as XSD schemas with example XML
          </Typography>
          <Button
            component={Link}
            fullWidth
            to='/documentation'
            variant='contained'
          >
            Documentation
          </Button>
        </Box>

        <AppSidebarLink
          icon={<LogoutIcon />}
          label='Logout'
          onClick={handleLogout}
          sx={{
            cursor: 'pointer',
            '&:hover svg': {
              color: 'error.main',
            },
          }}
        />
      </Stack>
    </>
  );
};

export default SidebarNavigation;
