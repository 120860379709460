import { Formik, Form, Field, FormikHelpers } from 'formik';
import { FormValues } from '../productSearch.types';
import * as yup from 'yup';
import { TextField } from 'formik-mui';

type ProductSearchFormProps = {
  id: string;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
};

const schema = yup.object().shape({
  gtins: yup.string().trim().required('Please enter some GTINs'),
});

const ProductSearchForm = ({ id, onSubmit }: ProductSearchFormProps) => (
  <Formik
    initialValues={{ gtins: '' }}
    onSubmit={onSubmit}
    validationSchema={schema}
  >
    <Form id={id}>
      <Field
        component={TextField}
        name='gtins'
        type='textarea'
        label='Search by GTINs'
        multiline
        maxRows={10}
        minRows={10}
        sx={{ width: '100%' }}
      />
    </Form>
  </Formik>
);

export default ProductSearchForm;
