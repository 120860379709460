import { ProductItem } from '../productSearch.types';
import { TableFilter, TableFilterProps } from '@brandbank/portal-components';

const ProductsTableFilter = <Multiple extends boolean | undefined>(
  props: TableFilterProps<ProductItem, Multiple>
) => {
  const { column, ...rest } = props;

  const hasMultipleOptions = column
    ? Array.from(column.getFacetedUniqueValues().keys()).length > 1
    : false;

  return <>{hasMultipleOptions && <TableFilter column={column} {...rest} />}</>;
};

export default ProductsTableFilter;
