import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import FilterSearch, { FilterSearchParams } from './FilterSearch';

// TODO - TAKE AS PROPS
const options = [
  {
    label: 'Company Name',
    value: 'companyName',
  },
  {
    label: 'Company ID',
    value: 'salesforceId',
  },
  {
    label: 'Placeholder',
    value: 'placeholder',
  },
  {
    label: 'Placeholder',
    value: 'placeholder',
  },
] as const;

// TODO - TAke as props
const filters: FilterSearchParams[] = [
  {
    name: 'companyType',
    checked: false,
    key: 'retailer',
    label: 'Retailer',
  },
  {
    name: 'companyType',
    checked: false,
    key: 'supplier',
    label: 'Supplier',
  },
];

const AdvancedSearch = () => {
  const [searchBy, setSearchBy] = useState('companyName');
  const [filterBy, setFilterBy] = useState<FilterSearchParams[]>(filters);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
        p: '0.5rem',
      }}
    >
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Typography>Search By:</Typography>
        <Select
          name='searchBy'
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
          size='small'
          sx={{ bgcolor: 'common.white' }}
          variant='outlined'
        >
          {options.map(({ label, value }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          variant='outlined'
          size='small'
          sx={{ bgcolor: 'common.white' }}
          placeholder={searchBy}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Box>
      {filters && (
        <FilterSearch filterBy={filterBy} setFilterBy={setFilterBy} />
      )}
      <Button variant='contained'>Search</Button>
    </Box>
  );
};

export default AdvancedSearch;
