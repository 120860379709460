import {
  Badge,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';

export type FilterSearchParams = {
  name: string;
  key: string | number;
  label: string;
  checked: boolean;
  isDisabled?: boolean;
};

interface FilterSearchProps {
  filterBy: FilterSearchParams[];
  setFilterBy: (value: React.SetStateAction<FilterSearchParams[]>) => void;
}

const FilterSearch = ({ filterBy, setFilterBy }: FilterSearchProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleChange = (checked: boolean, index: number) => {
    setFilterBy((prev) => {
      const newArr = [...prev];
      newArr[index].checked = checked;

      return newArr;
    });
  };

  return (
    <>
      <Tooltip title='Advance search'>
        <IconButton
          onClick={(e) => {
            setOpen(true);
            handleClick(e);
          }}
        >
          <Badge badgeContent={filterBy.filter((x) => x.checked).length}>
            <FilterAltTwoToneIcon
              fontSize='medium'
              color='action'
              sx={{ color: 'primary.main', cursor: 'pointer' }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {filterBy.map((filter, index) => (
          <MenuItem
            key={filter.key}
            value={filter.name}
            sx={{ padding: '0 8px' }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(_e, checked) => handleChange(checked, index)}
                    checked={filter.checked}
                    disabled={filter.isDisabled}
                  />
                }
                label={filter.label}
              />
            </FormGroup>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterSearch;
