import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from '@mui/material';
import { ReactNode } from 'react';
import { Icon } from '@brandbank/portal-components';

interface ExpandableProps extends Omit<AccordionProps, 'title'> {
  expandIcon?: ReactNode;
  summary: string | ReactNode;
}

const Expandable = ({
  children,
  expandIcon,
  summary,
  ...rest
}: ExpandableProps) => (
  <Accordion {...rest}>
    <AccordionSummary
      expandIcon={expandIcon || <Icon iconName='arrowChevronDown' />}
    >
      {summary}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export default Expandable;
