import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import FileUploader, { FileObject } from './FileUploader';
import Stack from '@mui/material/Stack';

type UserFeedbackFormProps = {
  formType: 'feedback' | 'bug';
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
};

export type FormValues = {
  description: string;
  files: FileObject[];
  steps: string;
  subject: string;
};

const formSchema = Yup.object().shape({
  files: Yup.mixed(),
  subject: Yup.string().required('Please provide a subject'),
  description: Yup.string().required('Please provide a description'),
  steps: Yup.string(),
});

export const initialFormValues = {
  description: '',
  files: [],
  steps: '',
  subject: '',
};

const UserFeedbackForm = (props: UserFeedbackFormProps): JSX.Element => {
  const { formType, initialValues, onSubmit } = props;

  return (
    <Formik
      initialValues={initialValues || initialFormValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form id='feedback-form'>
          <Stack spacing={2}>
            <Field
              component={TextField}
              fullWidth
              name='subject'
              label='Subject'
              required
            />

            <Field
              component={TextField}
              fullWidth
              label='Description'
              name='description'
              multiline
              minRows={10}
              required
            />

            {formType === 'bug' && (
              <Field
                component={TextField}
                fullWidth
                label='Steps to reproduce'
                name='steps'
                multiline
                minRows={10}
              />
            )}

            <FileUploader
              disabled={isSubmitting}
              id='feedback-attachments'
              imagesOnly
              maximum={3}
              multiple
              name='files'
              onChange={(files) => setFieldValue('files', files)}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default UserFeedbackForm;
