import React from 'react';
import InfoIcon from '@mui/icons-material/InfoRounded';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

export type NoItemsProps = {
  actions?: React.ReactNode;
  title?: string;
  message?: string;
  svg?: React.ReactNode;
  sx?: SxProps;
};

const NoItems = (props: NoItemsProps): JSX.Element => {
  const { actions, title, message, svg, sx } = props;

  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        mb: 4,
        mt: 2,
        ...sx,
      }}
    >
      {svg || <InfoIcon color='warning' sx={{ height: 50, width: 50 }} />}
      <Typography variant='h5'>{title || 'No items found'}</Typography>
      {message && <Typography>{message}</Typography>}
      {actions}
    </Stack>
  );
};

export default NoItems;
