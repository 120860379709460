import { Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Select } from 'formik-mui';
import { ChangeEvent, useRef } from 'react';
import * as yup from 'yup';
import { MappingValueField } from '.';
import {
  assetTypeMap,
  connectMaxAssetTypes,
  userFacingMappingType,
} from '../connect-max.constants';
import { getMappingTypeOptions } from '../connect-max.helpers';
import {
  ConnectMaxAssetItem,
  ConnectMaxAssetType,
  ConnectMaxMappingType,
} from '../connect-max.types';
import { mappingTypeValidation, mappingValidation } from '../schemas';

interface FormValues {
  assetType?: ConnectMaxAssetType | '' | undefined;
  mappingType: string | undefined;
  mapping: string | undefined;
}

type AddConnectMaxAssetModalProps = {
  assetType?: ConnectMaxAssetType;
  assets: ConnectMaxAssetItem[];
};

const getDefaultMappingTypeValue = (
  assetType?: ConnectMaxAssetType
): ConnectMaxMappingType | undefined => {
  switch (assetType) {
    case 'imageFile':
      return 'shotType';
    case 'videoFile':
    case 'embedded_Vimeo':
    case 'embedded_YouTube':
      return 'additionalAsset';
    default:
      return undefined;
  }
};

export default NiceModal.create(
  ({ assetType, assets }: AddConnectMaxAssetModalProps) => {
    const { hide, remove, visible, resolve } = useModal();

    const INITIAL_VALUES: FormValues = {
      assetType: assetType || '',
      mappingType: getDefaultMappingTypeValue(assetType) || '',
      mapping: '',
    };

    const formRef = useRef<HTMLFormElement | null>(null);

    const formId = 'add-connect-max-asset-form';

    const addConnectMaxAssetModalSchema = yup.object({
      assetType: yup
        .string()
        .typeError('Asset Type is required.')
        .required('Asset Type is required.'),
      mappingType: mappingTypeValidation.test(
        'valid-mapping-type-test',
        `Shot Type can only be set for Image Files`,
        (value, context) => {
          if (!value) return true;

          if (
            context.parent.assetType &&
            context.parent.assetType === 'imageFile'
          )
            return true; // imageFile can be any mapping type
          if (value === 'shotType') return false; // shotType only valid for imageFile

          return true;
        }
      ),
      mapping: mappingValidation,
    });

    const onSubmit = (
      values: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      formikHelpers.setSubmitting(false);

      if (assets) {
        const existingMappings =
          assets
            .flatMap((asset) => asset.positions)
            ?.map((pos) => pos.mapping) || [];

        if (values.mapping && existingMappings.includes(values.mapping)) {
          formikHelpers.setFieldError(
            'mapping',
            'This mapping value already exists for this Connect Max configuration'
          );
          return;
        }
      }

      resolve(values);
      hide();
      return;
    };

    const onCancel = () => {
      resolve(null);
      hide();
    };

    return (
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={addConnectMaxAssetModalSchema}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <Modal
            action={
              <>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type='submit' variant='contained' form={formId}>
                  Submit
                </Button>
              </>
            }
            muiTransitionProps={{ onExited: remove }}
            open={visible}
            onClose={hide}
            title={`Add New ${assetType ? assetTypeMap[assetType] : 'Asset'}`}
            width='30%'
          >
            <Form id={formId} onSubmit={handleSubmit} ref={formRef}>
              <Stack spacing={2}>
                {!assetType && (
                  <Field
                    component={Select}
                    fullWidth
                    label='Asset Type'
                    name='assetType'
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      if (e.target.value !== 'imageFile') {
                        setFieldValue('mappingType', 'additionalAsset');
                      }
                      handleChange(e);
                    }}
                  >
                    {connectMaxAssetTypes.map((asset, idx) => (
                      <MenuItem key={`asset-menu-option-${idx}`} value={asset}>
                        {assetTypeMap[asset]}
                      </MenuItem>
                    ))}
                  </Field>
                )}

                <Field
                  component={Select}
                  fullWidth
                  label='Mapping Type'
                  name='mappingType'
                  onChange={handleChange}
                >
                  {getMappingTypeOptions(values.assetType).map(
                    (mappingType, idx) => (
                      <MenuItem
                        key={`mapping-type-option-${idx}`}
                        value={mappingType}
                      >
                        {userFacingMappingType[mappingType]}
                      </MenuItem>
                    )
                  )}
                </Field>

                <MappingValueField
                  handleChange={handleChange}
                  showInfoIcon={values.mappingType === 'additionalAsset'}
                />
              </Stack>
            </Form>
          </Modal>
        )}
      </Formik>
    );
  }
);
