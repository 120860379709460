import { Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { CONNECT_MAX_INFO_POINTS } from '../connect-max.constants';

const InfoItem = ({ text, sx }: { text: string; sx?: SxProps }) => (
  <ListItem disableGutters disablePadding sx={sx}>
    <ListItemIcon
      sx={{
        paddingRight: '0.5rem',
        minWidth: '0',
      }}
    >
      <CircleIcon
        sx={{
          height: '0.5rem',
          width: '0.5rem',
        }}
      />
    </ListItemIcon>
    <ListItemText secondary={text} />
  </ListItem>
);

export default NiceModal.create(
  ({ hideCheckbox = false }: { hideCheckbox: boolean }) => {
    const { hide, remove, visible } = useModal();

    const [checked, setChecked] = useState(false);

    const onCancel = () => {
      hide();
    };

    const handleChange = (
      _event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => {
      setChecked(checked);
      localStorage.setItem(
        'ShouldShowConnectMaxInfoModal',
        checked ? 'false' : 'true'
      );
    };

    return (
      <Modal
        action={
          <>
            {!hideCheckbox && (
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Don't show again"
              />
            )}
            <Button variant='contained' onClick={onCancel}>
              Close
            </Button>
          </>
        }
        muiTransitionProps={{ onExited: remove }}
        open={visible}
        onClose={hide}
        title='Connect Max Information'
        width='40%'
        muiCardHeaderProps={{
          sx: { '.MuiCardHeader-title': { fontSize: '1.5rem !important' } },
        }}
      >
        <List disablePadding>
          <Stack spacing={1}>
            {CONNECT_MAX_INFO_POINTS.map((text, index) => (
              <InfoItem text={text} key={`key-point-${index}`} />
            ))}
          </Stack>
        </List>
      </Modal>
    );
  }
);
