import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { StatusUI, StatusEnum, Modal } from '@brandbank/portal-components';
import { create, useModal } from '@ebay/nice-modal-react';
import { Alert } from '@mui/material';

export default create(() => {
  const { hide, remove, visible } = useModal();

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );

  const windowError = params.error_description
    ? decodeURIComponent(params.error_description)
    : '';

  return (
    <Modal
      action={
        <Button onClick={hide} variant='outlined' color='error'>
          Close
        </Button>
      }
      muiTransitionProps={{ onExited: remove }}
      onClose={hide}
      open={visible}
      title=''
      width='45%'
    >
      <StatusUI
        status={StatusEnum.ERROR}
        title='Login Error'
        sx={{
          alignItems: 'center',
          '& svg': {
            height: 50,
            width: 50,
          },
          '& h3': {
            fontSize: '1.5rem',
          },
          '& h5': {
            display: 'none',
          },
        }}
      />

      <Stack spacing={1} sx={{ mt: 2, textAlign: 'center' }}>
        <Alert severity='error' sx={{ '.MuiAlert-icon': { my: 'auto' } }}>
          {windowError || 'An unexpected error prevented login'}
        </Alert>
      </Stack>
    </Modal>
  );
});
