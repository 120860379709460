import { Auth0Provider } from '@auth0/auth0-react';
import {
  ThemeProvider as ComponentThemeProvider,
  theme,
} from '@brandbank/portal-components';
import { Provider as ModalProvider } from '@ebay/nice-modal-react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CookieConsentProvider } from '@use-cookie-consent/react';
import environmentVariables from 'config/environmentVariables';
import queryClient from 'config/queryClient';
import { combineProviders } from 'react-combine-providers';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from './SnackbarProvider';

const { auth } = environmentVariables;

// Providers for app

const providers = combineProviders();

providers.push(QueryClientProvider, { client: queryClient });

providers.push(Auth0Provider, {
  audience: 'integration-portal-api',
  clientId: auth.clientID || '',
  domain: auth.domain || '',
  redirectUri: window.location.origin,
  scope: 'write:feeds read:userClaims manage:purpose manage:extract',
});
providers.push(BrowserRouter);
providers.push(ThemeProvider, { theme });
providers.push(CookieConsentProvider, {
  useCookieConsentHooksOptions: { defaultConsent: { necessary: false } },
});
providers.push(ComponentThemeProvider);
providers.push(ModalProvider);
providers.push(SnackbarProvider);

const MasterProvider = providers.master();

// Providers for testing

const testProviders = combineProviders();

testProviders.push(QueryClientProvider, {
  client: new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
    logger: {
      log: console.log,
      warn: console.warn,
      error: () => null,
    },
  }),
});

testProviders.push(BrowserRouter);
testProviders.push(ThemeProvider, { theme });
testProviders.push(SnackbarProvider);
testProviders.push(ModalProvider);

export const TestingProviders = testProviders.master();

export default MasterProvider;
