import { useMutation } from '@tanstack/react-query';
import axios from 'config/axios';
import { FileAttachment } from '../userFeedback.types';

type Params = {
  description: string;
  images?: FileAttachment[];
  subject: string;
  type: 'bug' | 'feedback';
};

async function postFeedback(params: Params) {
  const { pathname } = window.location;
  const { description, images = [], subject, type } = params;
  const endpoint = type === 'bug' ? 'ReportBug' : 'ReportFeedback';

  await axios.post(endpoint, {
    Description: description,
    CurrentPage: pathname === '/' ? 'Dashboard' : pathname,
    Images: images || [],
    Subject: subject,
  });
}

const usePostFeedback = () => useMutation(postFeedback);

export default usePostFeedback;
