import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type CookieBannerProps = {
  onSettingsClick: () => void;
  onAcceptAllClick: () => void;
  onAcceptEssentialClick: () => void;
};

const CookieBanner = (props: CookieBannerProps): JSX.Element => {
  const { onAcceptAllClick, onAcceptEssentialClick, onSettingsClick } = props;

  return (
    <Paper
      data-testid='cookie-banner'
      sx={{
        borderRadius: 0,
        bottom: 0,
        p: 2,
        position: 'fixed',
        width: '100%',
      }}
    >
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography>
            We use cookies on our website to give you the most relevant
            experience by remembering your preferences and repeat visits. By
            clicking “Accept”, you consent to the use of ALL the cookies.
          </Typography>
        </Box>

        <Stack
          alignItems='center'
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
          justifyContent='end'
          spacing={{
            xs: 1,
            md: 2,
          }}
          sx={{
            flex: 1,
            flexGrow: {
              xl: 1,
              lg: 2,
            },
            mt: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Link href='/cookie-policy.pdf' target='_blank'>
            Cookie policy
          </Link>

          <Link href='https://www.brandbank.com/privacynotice/' target='_blank'>
            Privacy policy
          </Link>

          <Link href='/terms-and-conditions.pdf' target='_blank'>
            Terms &amp; conditions
          </Link>

          <Link onClick={onSettingsClick} sx={{ cursor: 'pointer' }}>
            Cookie settings
          </Link>
          <Button
            onClick={onAcceptEssentialClick}
            size='small'
            variant='outlined'
          >
            Accept essential
          </Button>
          <Button onClick={onAcceptAllClick} size='small' variant='contained'>
            Accept
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default CookieBanner;
