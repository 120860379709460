import * as React from 'react';
import { StatusUI, StatusEnum } from '@brandbank/portal-components';

const withSuspense = (element: JSX.Element): JSX.Element => (
  <React.Suspense
    fallback={<StatusUI splashPage status={StatusEnum.LOADING} />}
  >
    {element}
  </React.Suspense>
);

export default withSuspense;
