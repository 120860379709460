import { useProductSearchCtx } from '../ProductSearch.context';
import { ProductItem } from '../productSearch.types';
import ProductsTable from './ProductsTable';
import {
  ProductResendError,
  ProductResendModal,
} from 'features/product-resend';
import { show } from '@ebay/nice-modal-react';
import { useFeedDetailsCtx } from 'features/feeds';
const ProductsView = () => {
  const { detail } = useFeedDetailsCtx();
  const { dispatch, state } = useProductSearchCtx();
  const { products } = state;
  const handleResend = async (items: ProductItem[], clearItems: () => void) => {
    const errors: ProductResendError[] = await show(ProductResendModal, {
      itemsToResend: items,
      purposeId: String(detail.id),
    });
    if (errors.length > 0) {
      dispatch({ payload: errors, type: 'ADD_ERRORS' });
      clearItems();
    }
  };
  return <ProductsTable items={products} onResend={handleResend} />;
};
export default ProductsView;
