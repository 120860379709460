import { FeedDetailScope } from './feeds.types';

export const getSubcodesFromScopes = (
  scopes: FeedDetailScope[] | null,
  purposeSubscriberTypeId: number
): string[] => {
  if (!scopes || scopes.length === 0) return [];
  return scopes
    .filter((item) => item.purposeSubscriberTypeId === purposeSubscriberTypeId)
    .map(({ subscriberCode }) => subscriberCode);
};
