import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'config/axios';
import { useSnackbar } from 'notistack';
import { DeleteConnectMaxPayload } from '../connect-max.types';

type Response = {
  message?: string;
};

async function deleteConnectMaxConfig({
  retailerId,
  retailerName,
}: DeleteConnectMaxPayload) {
  const response = await axios.delete<Response>(
    `DeleteRetailerConfiguration?retailerId=${retailerId}&retailerName=${retailerName}`
  );

  if (!response.data) {
    return Promise.reject(`Unable to delete Connect Max layout`);
  }

  return response;
}

const useDeleteConnectMaxConfig = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const mutation = useMutation(deleteConnectMaxConfig, {
    onError: (err) => {
      console.error(err);
      enqueueSnackbar('Unable to delete Connect Max layout', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully deleted Connect Max layout', {
        variant: 'success',
      });
      queryClient.invalidateQueries(['connectMaxConfig']);
    },
  });

  return mutation;
};

export default useDeleteConnectMaxConfig;
