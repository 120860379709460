import { FormikHelpers } from 'formik';
import getProducts from '../api/getProducts';
import { useProductSearchCtx } from '../ProductSearch.context';
import { FormValues } from '../productSearch.types';

const useProductSearch = (purposeId: number) => {
  const { dispatch } = useProductSearchCtx();

  const isGtinRgx = (gtin: string) => /^-?\d+$/.test(gtin);
  const isLength = (gtin: string) => gtin.length >= 8 && gtin.length < 15;

  const processProducts = (res: Awaited<ReturnType<typeof getProducts>>) => {
    if (res.status === 'FAIL') {
      dispatch({ payload: res.errors, type: 'SEARCH_FAILED' });
    }

    if (res.status === 'SUCCESS') {
      const { productMetadata } = res.data;

      dispatch({
        payload: {
          products: productMetadata,
          ...res.data,
        },
        type: 'SEARCH_SUCCESSFUL',
      });
    }
  };

  const search = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const { setFieldError, setSubmitting } = helpers;

    let errorMessage = '';

    const gtins: string[] = values.gtins.trim().split(/\W/gm);

    if (gtins.length > 5000) {
      errorMessage = 'No more than 5000 GTINs can be entered at once';
    } else if (!gtins.every((gtin) => isGtinRgx(gtin) && isLength(gtin))) {
      errorMessage = 'A GTIN must be a numeric value between 8 and 14 digits';
    }

    if (errorMessage) {
      setFieldError('gtins', errorMessage);
      setSubmitting(false);
      return;
    }

    dispatch({ type: 'IS_SEARCHING' });

    getProducts(gtins, purposeId)
      .then(processProducts)
      .catch(() => {
        dispatch({ payload: [], type: 'SEARCH_FAILED' });
      });
  };

  return search;
};

export default useProductSearch;
