import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { CardRow } from 'features/ui';
import { useFeedDetailsCtx } from '../FeedDetails.context';

const FeedsOverviewPurposeInfo = () => {
  const { detail } = useFeedDetailsCtx();

  return (
    <Card>
      <CardHeader
        title='Purpose Information'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      <CardContent>
        <Stack spacing={1}>
          <CardRow label='Purpose Name:' value={detail.purposeName} />
          <CardRow label='Purpose ID:' value={detail.id} />
          <CardRow label='Type:' value={detail.purposeType} />
          <CardRow label='Subtype:' value={detail.purposeSubType} />
          <CardRow label='' value={null} />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FeedsOverviewPurposeInfo;
