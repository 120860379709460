import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/MenuRounded';
import MenuOpenIcon from '@mui/icons-material/MenuOpenRounded';
import Toolbar from '@mui/material/Toolbar';
import { UserMenu } from 'features/ui';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import SidebarNavigation from './SidebarNavigation';
import { AppSidebarMobile, useModal } from '@brandbank/portal-components';
import Breadcrumbs from './Breadcrumbs';

const TopNavigation = (): JSX.Element => {
  const mobileControls = useModal();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  return (
    <>
      <AppBar
        position='sticky'
        sx={{
          bgcolor: trigger ? 'common.white' : 'transparent',
          boxShadow: trigger ? 2 : 0,
          px: {
            xs: 2,
            sm: 0,
          },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            borderBottom: trigger ? 1 : 0,
            borderColor: (theme) => theme.palette.grey[300],
            p: 0,
          }}
        >
          <IconButton
            color='primary'
            edge='start'
            onClick={mobileControls.toggle}
            sx={{
              display: { md: 'inline-flex', lg: 'none' },
              '& svg': {
                height: 32,
                width: 32,
              },
            }}
          >
            {mobileControls.isOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>

          {!mobileControls.isOpen && <Breadcrumbs />}

          <UserMenu />
        </Toolbar>
      </AppBar>
      <AppSidebarMobile
        isOpen={mobileControls.isOpen}
        onClose={mobileControls.close}
        sx={{
          zIndex: 'drawer',
        }}
      >
        <Box sx={{ height: 80 }} />
        <SidebarNavigation />
      </AppSidebarMobile>
    </>
  );
};

export default TopNavigation;
