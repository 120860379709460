import React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon, capitalise, useModal } from '@brandbank/portal-components';
import { SxProps } from '@mui/material';

type BreadcrumbsProps = {
  sx?: SxProps;
};

type DeviceElementProps = {
  items: MappedRoute[];
};

type MappedRoute = {
  label: string;
  route: string;
};

const BreadcrumbsMobile = (props: DeviceElementProps): JSX.Element => {
  const { items } = props;

  const mobileMenuRef = React.useRef(null);
  const mobileMenuControls = useModal();

  return (
    <>
      <Button
        disableElevation
        disableRipple
        endIcon={
          <Icon
            iconName={
              mobileMenuControls.isOpen ? 'arrowChevronUp' : 'arrowChevronDown'
            }
          />
        }
        onClick={mobileMenuControls.open}
        ref={mobileMenuRef}
        variant='contained'
      >
        <Typography
          fontWeight={600}
          noWrap
          sx={{ maxWidth: 200 }}
          variant='button'
        >
          {items[items.length - 1].label}
        </Typography>
      </Button>

      <Menu
        anchorEl={mobileMenuRef.current}
        open={mobileMenuControls.isOpen}
        onClose={mobileMenuControls.close}
        sx={{
          '& .MuiPaper-root': {
            border: 1,
            borderColor: (theme) => theme.palette.grey[200],
            width: 200,
          },
        }}
      >
        {items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              <MenuItem key={item.route} disabled>
                {item.label}
              </MenuItem>
            );
          }

          return (
            <MenuItem component={RouterLink} key={item.route} to={item.route}>
              <Typography noWrap>{item.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const BreadcrumbsDesktop = (props: DeviceElementProps): JSX.Element => {
  const { items } = props;

  return (
    <MuiBreadcrumbs
      separator={<Icon iconName='arrowChevronRight' fontSize='small' />}
    >
      {items.map((item, index) => {
        if (index === items.length - 1) {
          return (
            <Typography
              sx={{ color: 'text.primary' }}
              fontWeight={500}
              key={item.route}
              variant='h6'
            >
              {item.label}
            </Typography>
          );
        }

        return (
          <Link
            component={RouterLink}
            underline='hover'
            to={item.route}
            key={item.route}
            sx={{
              color: 'text.primary',
            }}
            fontWeight={600}
            variant='h6'
          >
            {item.label}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
  const { sx } = props;
  const location = useLocation();
  const paths = location.pathname.split('/');
  const hasNoPaths = paths.every((path) => path === '');

  const getRouteFromPath = (path: string, index: number): string => {
    const previousPaths = paths.slice(0, index);
    const linkPath = `${previousPaths.join('/')}/${path}`;
    const route = index === 0 ? '/' : linkPath;

    return route;
  };

  const mappedRoutes: MappedRoute[] = paths.map((path, index) => {
    const route = getRouteFromPath(path, index);

    const label =
      index === 0
        ? 'Dashboard'
        : path.replace(/[-]/gm, ' ').split(' ').map(capitalise).join(' ');

    return { label, route };
  });

  return (
    <Box sx={sx}>
      {hasNoPaths ? (
        <Typography color='text.primary' variant='h6'>
          Dashboard
        </Typography>
      ) : (
        <>
          <Box
            className='breadcrumbs--mobile'
            sx={{
              display: {
                xs: 'block',
                sm: 'none',
              },
            }}
          >
            <BreadcrumbsMobile items={mappedRoutes} />
          </Box>

          <Box
            className='breadcrumbs--desktop'
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
              },
            }}
          >
            <BreadcrumbsDesktop items={mappedRoutes} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Breadcrumbs;
