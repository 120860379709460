import { Row } from '@tanstack/react-table';
import { ProductItem } from './productSearch.types';

export const isRowSelectable = (row: Row<ProductItem>) => {
  const item = row.original as ProductItem;

  return (
    item.productVersionStatusDescription === 'Current' &&
    item.productStatusDescription === 'Live'
  );
};
