import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Modal } from '@brandbank/portal-components';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

type ProductsTableInfoModalProps = {
  productStatus?: string;
};

export default NiceModal.create((props: ProductsTableInfoModalProps) => {
  const { productStatus } = props;
  const modal = useModal();

  const isHistoric = productStatus === 'Historic';

  return (
    <Modal
      action={
        <Button onClick={modal.hide} variant='contained'>
          OK
        </Button>
      }
      muiTransitionProps={{ onExited: modal.remove }}
      onClose={modal.hide}
      open={modal.visible}
      title={isHistoric ? 'De-listed products' : 'Non-current products'}
      width='40%'
    >
      <Stack spacing={2}>
        {isHistoric ? (
          <Typography>
            This product has been de-listed against this subcode; as a result
            you cannot mark it for resend.
          </Typography>
        ) : (
          <Typography>
            This PVID is not the current PVID for this GTIN on this subcode.
            Only the current PVID for this GTIN on this subcode can be resent.
          </Typography>
        )}
      </Stack>
    </Modal>
  );
});
