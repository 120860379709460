import { ReactNode } from 'react';
import { StatusUI, StatusEnum } from '@brandbank/portal-components';

interface LoadingWrapperProps {
  children: ReactNode;
  isLoading?: boolean;
}

const LoadingWrapper = ({ children, isLoading }: LoadingWrapperProps) => (
  <>
    {isLoading ? <StatusUI splashPage status={StatusEnum.LOADING} /> : children}
  </>
);

export default LoadingWrapper;
