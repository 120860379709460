import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useCompanyDetails } from 'features/companies/api';
import { CardContentStatus, CardRow } from 'features/ui';

type CompaniesOverviewParentCompanyProps = {
  parentCompanyId: string;
};

const CompaniesOverviewParentCompany = ({
  parentCompanyId,
}: CompaniesOverviewParentCompanyProps) => {
  const { data, status } = useCompanyDetails(parentCompanyId);

  return (
    <Card>
      <CardHeader
        title='Parent Company'
        titleTypographyProps={{
          variant: 'h6',
        }}
      />

      {(status === 'error' || status === 'loading') && (
        <CardContentStatus
          type={status}
          errorMessage='An error occurred while retrieving parent company data'
        />
      )}

      {status === 'success' && (
        <CardContent>
          {data ? (
            <Stack spacing={1}>
              <CardRow label='Name:' value={data.name} />
              <CardRow label='Salesforce ID:' value={data.id} />
              <CardRow
                label='Company Type:'
                value={data.companyTypeDescription}
              />
              <CardRow label='Company ID:' value={data.companyId} />
            </Stack>
          ) : (
            <Typography>No info found</Typography>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default CompaniesOverviewParentCompany;
