import axios from 'config/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

async function deleteFavouriteFeed(purposeId: string) {
  const response = await axios.delete<boolean>('DeleteFavouriteFeed', {
    data: { id: purposeId },
  });

  if (!response.data) {
    return Promise.reject(
      `Unable to remove feed with purposeId ${purposeId} from favourites`
    );
  }

  return true;
}

const useDeleteFavouriteFeed = () => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const mutation = useMutation(deleteFavouriteFeed, {
    onError: () => {
      enqueueSnackbar('Unable to remove favourite', { variant: 'error' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['favouriteFeeds']);
    },
  });

  return mutation;
};

export default useDeleteFavouriteFeed;
